export const defaultState = {
  isSyncInProgress: false,
  fullscreen: false,
  count: 0,
  blocked: true,
  unlocked: false,
  loadingPercent: 0,
  submittedNotes: [],
  syncProgress: 0,
  savingProcess: false,
  terminal: {},
  isOnline: navigator.onLine,
  shift: {},
  activeTableIndex: 0,
  ordersToSave: 0,
  employee: null,
  orderChannel: [],
  syncQueue: [],
  tablesListMode: false,
  callOrderListOnTableInitall : true
};
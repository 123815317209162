<template>
  <!-- Modal Notes -->
  <b-modal
    :id="'modal-note-order-item'"
    ref="modalNotes"
    hide-footer
    hide-header
    modal-class="success-popup"
    @show="resetModal"
    @hidden="resetModal"
    no-fade
  >
    <div class="text-center modal-complete">
      <svg class="icon icon-icon-popup-note">
        <use xlink:href="#icon-icon-popup-note" />
      </svg>

      <h2>
        {{ $t("orderscreen.note-this-item") }}
      </h2>

      <form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <b-form-group
          label-for="notes-input"
        >
          <b-textarea
            id="notes-input"
            v-model="notes"
            :placeholder="$t('orderscreen.note-this-placeholder')"
            class="popup-control mb-4"
            rows="3"
            max-rows="6"
          />
        </b-form-group>
      </form>

      <div class="d-flex justify-content-center align-items-center">
        <b-button
          variant="outline-warning"
          @click="$bvModal.hide('modal-note-order-item')"
        >
          {{ $t("orderscreen.cancel") }}
        </b-button>
        <b-button
          variant="warning"
          class="ml-2"
          @click="handleOk"
        >
          {{ $t("orderscreen.done") }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: "ModalItemNotes",
  data () {
    return {
      notes: ""
    };
  },
  methods: {
    handleOk (bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    resetModal () {
      this.index = this.$store.getters["itemNote/index"] || null;
      this.notes = this.$store.getters["itemNote/notes"] || "";
    },
    handleSubmit () {
      this.$emit("saveNote", { notes: this.notes });
      this.$nextTick(() => {
        this.$refs.modalNotes.hide();
      });
    }
  }
};
</script>

<template>
  <div>
    <div class="orders-head">
      <div 
        :class="{ 'bg-primary': isKioskMode }" 
        class="orders-top"
      >
        <div class="d-flex align-items-center">
          <div 
            v-if="currentOrder.id || currentOrder.local_id" 
            class="orders-top-num"
          >
            <span>
              {{ $t("amountandpaymentmethod.order-no") }}
            </span>
            <strong># {{ currentOrder.id || currentOrder.local_id }}</strong>
          </div>

          <div 
            v-if="isKioskMode && orderPanelOrderDiningOption" 
            class="orders-top-num ml-auto"
          >
            <span>Order Type</span>
            <strong> {{ orderPanelOrderDiningOption.name }}</strong>
          </div>

          <div class="orders-top-btn ml-auto d-sm-flex align-items-center">
            <b-button 
              v-if="orderPanelOrderDiningOption" 
              :class="{ 'd-none': isKioskMode }"
              :disabled="$route.params.id === 'new'" 
              variant="dark"
              @click="isSavingProcess ? null : showDiningOptionsModal()"
            >
              {{
                orderPanelOrderDiningOption
                  ? orderPanelOrderDiningOption.name
                  : defaultDiningOption ? defaultDiningOption.name : ""
              }}
            </b-button>
            <b-button 
              v-if="!isKioskMode" 
              :disabled="$route.params.id === 'new'" 
              variant="secondary"
              @click="isSavingProcess ? null : cancelOrder()"
            >
              {{ $t("orderscreen.cancel-order") }}
            </b-button>
            <b-button 
              v-if="isKioskMode" 
              variant="secondary" 
              @click="isSavingProcess ? null : cancelOrder()"
            >
              {{ $t("orderscreen.cancel-order") }}
            </b-button>
          </div>
        </div>
      </div>

      <div 
        v-if="isKioskMode" 
        class="orders-list-th"
      >
        <div class="d-flex align-items-center h-100 justify-content-between">
          <div class="th">
            QTY
          </div>
          <div class="th">
            PRODUCT NAME
          </div>
          <div class="th ml-auto">
            PRICE
          </div>
        </div>
      </div>
      <div 
        v-if="!isKioskMode" 
        class="orders-add"
      >
        <div>
          <b-button 
            variant="link" 
            @click="showSelectCustomerModal"
          >
            <div v-if="!currentOrder.customer">
              <svg class="icon icon-icon-add">
                <use xlink:href="#icon-icon-add" />
              </svg>
              <span>
                {{ $t("orderscreen.add-customer") }}
              </span>
            </div>
            <div v-else>
              {{ currentOrder.customer.first_name }} {{ currentOrder.customer.last_name }}
            </div>
          </b-button>
          <b-button 
            v-if="currentOrder.customer" 
            variant="link"
            @click="showViewCustomerModal(currentOrder.customer,2)"
          >
            View
          </b-button>
        </div>
        <b-button 
          v-if="allowCashDiscount && allowCashDiscount.value === 'true'"
          :variant="currentOrder && currentOrder.cash_discount ? 'primary' : 'outline-light'"
          @click="toggleCashDiscount"
        >
          {{ $t("orderscreen.cash-discount") }}
          {{ currentOrder && currentOrder.cash_discount ? $t("orderscreen.on") : $t("orderscreen.off") }}
        </b-button>
      </div>
    </div>

    <div 
      id="orders-sidebar-body" 
      class="orders-sidebar-body"
    >
      <div 
        :class="{ 'orders-list-kiosk': isKioskMode }" 
        class="orders-list" 
        role="tablist"
      >
        <div 
          v-if="!itemsAtCurrentOrder.length && isKioskMode" 
          class="orders-empty-kiosk"
        >
          <h5>
            Your card is empty
          </h5>
          <p>
            Please, add items to get started.
          </p>

          <img 
            src="../../assets/images/powered.svg" 
            class="kiosk-powered"
          >
        </div>
        <div v-if="!isKioskMode">
          <b-card 
            v-for="(item, index) in orderItems" 
            :key="item.id"
            :class="{ 'sent_to_kitchen': item.sent_to_kitchen }" 
            no-body 
            class="list-complete-item"
          >
            <b-card-header 
              header-tag="header" 
              class="orders-item-head p-0" 
              role="tab"
            >
              <b-button 
                v-b-toggle="'accordion-' + index" 
                block 
                href="#"
              >
                <div class="d-flex">
                  <div class="btn-num">
                    {{ item.quantity }}
                  </div>

                  <div class="btn-body">
                    <div class="d-flex align-items-center justify-content-between top text-nowrap">
                      <div class="text-left">
                        {{ item.name }} <span v-if="item.product === -2"> (card number: {{ getGiftCardNumber(item.local_id) }})</span>
                        <svg 
                          v-if="item.notes && item.notes.length" 
                          class="icon icon-icon-note"
                        >
                          <use xlink:href="#icon-icon-note" />
                        </svg>
                      </div>
                      <div class="text-right">
                        <span
                          v-if="(item.tax > 0 || item.price === 0) && item.tax_status === 0 && !isKioskMode"
                          class="short-desc"
                        >{{ $t("listvieworderdelegate.taxed") }}</span>
                        {{ currency }} {{ getItemGrandTotal(item).toFixed(2) }}
                      </div>
                    </div>
                  </div>
                </div>
              </b-button>
            </b-card-header>

            <b-collapse 
              :id="'accordion-' + index" 
              :visible="showCollapse(index)"
              accordion="my-accordion" 
              role="tabpanel"
              class="sub-accordion"
            >
              <b-card-body>
                <div 
                  v-if="item.discount > 0 || item.wholeDiscount > 0 || item.wholeDiscount < 0"
                  class="d-flex align-items-center justify-content-between sub-discount text-nowrap"
                >
                  <div class="text-left">
                    {{ currency }} {{ $t("orderscreen.discount") }}
                  </div>
                  <div class="text-right">
                    {{ currency }} {{ roundOffDiscounts(item) }}
                  </div>
                </div>
                <div 
                  v-for="modifierGroup in getModifiersByItemPosition(item.id)" 
                  :key="modifierGroup.id"
                  class="mod"
                >
                  <div class="d-flex align-items-center justify-content-between sub text-nowrap">
                    <div class="text-left mod-name">
                      {{ modifierGroup.name }}
                    </div>
                    <div class="text-right" />
                  </div>
                  <div 
                    v-for="modifier of modifierGroup.modifiers" 
                    :key="modifier.id"
                    class="d-flex align-items-center justify-content-between sub text-nowrap"
                  >
                    <div class="text-left">
                      {{ modifier.name }}
                    </div>
                    <div class="text-right">
                      {{ currency }} {{ (modifier.price).toFixed(2) }}
                    </div>
                  </div>
                </div>
                <table class="table">
                  <tr>
                    <td>
                      <b-button 
                        :disabled="item.product === -2"
                        variant="link" 
                        @click="changeQuantity(item.id)"
                      >
                        <span>
                          {{ $t("listvieworderdelegate.qty") }}
                        </span>
                        <strong class="text-primary">
                          {{ item.quantity }}
                        </strong>
                      </b-button>
                    </td>
                    <td>
                      <b-button 
                        :ref="`showPriceAdjust`" 
                        variant="link"
                        @click="showPriceAdjustModal(item)"
                      >
                        <span>
                          {{ $t("listvieworderdelegate.price") }}
                        </span>
                        <strong class="text-primary">
                          {{ currency }} {{ getItemPriceDisplay(item) }}
                        </strong>
                      </b-button>
                    </td>
                    <td>
                      <b-button 
                        :disabled="item.product === -2"
                        variant="link" 
                        @click="changeDiscount(item.id)"
                      >
                        <span>
                          {{ $t("listvieworderdelegate.discount") }}
                        </span>
                        <strong>
                          {{ currency }} {{ item.discount.toFixed(2) }}
                        </strong>
                      </b-button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b-button 
                        variant="link" 
                        @click="showNotesModal(index)"
                      >
                        {{
                          !(item.notes && item.notes.length) ? $t("listvieworderdelegate.add-notes") :
                          $t("listvieworderdelegate.edit-note")
                        }}
                      </b-button>
                    </td>
                    <td>
                      <b-button
                        v-if="(products.find(product => product.id === item.product)).modifier_groups.length > 0"
                        variant="link" 
                        class="text-danger" 
                        @click="modifiersOpen(item.id)"
                      >
                        {{ $t("listvieworderdelegate.modifiers") }}
                      </b-button>
                    </td>
                    <td>
                      <b-button 
                        variant="link" 
                        class="btn-remove"
                        @click="removeItemFromOrder(item.id)"
                      >
                        <svg class="icon icon-icon-remove">
                          <use xlink:href="#icon-icon-remove" />
                        </svg>
                      </b-button>
                    </td>
                  </tr>
                </table>
                <div 
                  v-if="item.notes && item.notes.length" 
                  class="card-notes"
                >
                  {{ item.notes }}
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>

        <div v-if="isKioskMode">
          <b-card 
            v-for="(item) in itemsAtCurrentOrder" 
            :key="item.id" 
            no-body 
            class="list-complete-item test"
            @click="changeQuantity(item.id)"
          >
            <div class="card-header p-0 test">
              <div class="d-flex align-items-center justify-content-between">
                <b-button 
                  class="card-qnt" 
                  variant="link"
                >
                  <span class="bg-primary text-white">
                    {{ item.quantity }}
                  </span>
                </b-button>

                <h6 class="card-title mb-0">
                  {{ item.name }}
                </h6>

                <div class="card-price ml-auto">
                  <span 
                    v-if="item.tax > 0 && item.tax_status === 0 && !isKioskMode" 
                    class="short-desc"
                  >{{
                    $t("listvieworderdelegate.taxed") }}</span>
                  <span>
                    {{ currency }} {{ getItemPriceDisplay(item) }}
                  </span>
                </div>

                <b-button 
                  variant="link" 
                  class="btn-remove ml-1"
                  @click.stop.prevent="removeItemFromOrder(item.id)"
                >
                  <svg class="icon icon-icon-remove">
                    <use xlink:href="#icon-icon-remove" />
                  </svg>
                </b-button>
              </div>

              <div 
                v-for="modifierGroup in getModifiersByItemPosition(item.id)" 
                :key="modifierGroup.id"
                class="mod"
              >
                <div class="d-flex align-items-center justify-content-between sub text-nowrap">
                  <div class="text-left mod-name">
                    {{ modifierGroup.name }}
                  </div>
                  <div class="text-right" />
                </div>
                <div 
                  v-for="modifier of modifierGroup.modifiers" 
                  :key="modifier.id"
                  class="d-flex align-items-center justify-content-between sub text-nowrap"
                >
                  <div class="text-left">
                    {{ modifier.name }}
                  </div>
                  <div class="text-right">
                    {{ currency }} {{ (modifier.price).toFixed(2) }}
                  </div>
                </div>
              </div>
            </div>
          </b-card>
        </div>
      </div>
    </div>

    <div class="orders-bottom">
      <div v-if="!isDisplayPaymentDetails">
        <div class="orders-bottom-item">
          <span class="text-left">
            {{ $t("ordertotal.total") }}
          </span>
          <span class="text-right">
            {{ currency }} {{ currentOrder.grand_total ? currentOrder.grand_total.toFixed(2) : "0.00" }}
            <b-button 
              variant="link" 
              class="w-100" 
              @click="displayPaymentDetails"
            >
              {{ $t("ordertotal.view-details") }}
            </b-button>
          </span>
        </div>
      </div>
      <div v-else>
        <div class="orders-bottom-item">
          <b-button 
            variant="link" 
            class="w-100 text-center" 
            @click="displayPaymentDetails"
          >
            {{ $t("ordertotal.close-details") }}
          </b-button>
        </div>
        <div class="orders-bottom-item">
          <span class="text-left">
            {{ $t("ordertotal.discount") }}
          </span>
          <span class="text-left">
            {{ currency }} {{ currentOrder ? currentOrder.discount_total.toFixed(2) : "0.00" }}
          </span>
        </div>

        <div class="orders-bottom-item">
          <span class="text-left">
            {{ $t("ordertotal.sub-total") }}
          </span>
          <span class="text-right">
            {{ currency }} {{ currentOrder ? currentOrder.subtotal.toFixed(2) : "0.00" }}
          </span>
        </div>

        <div class="orders-bottom-item">
          <span class="text-primary text-left">
            {{ $t("ordertotal.tax") }}
          </span>
          <span class="text-right">
            {{ currency }} {{ currentOrder ? toFixedNoRounding(currentOrder.tax_total, 2) : "0.00" }}
          </span>
        </div>
        <div class="orders-bottom-item">
          <span class="text-left">
            {{ $t("ordertotal.total") }}
          </span>
          <span class="text-right">
            {{ currency }} {{ currentOrder.grand_total ? currentOrder.grand_total.toFixed(2) : "0.00" }}
          </span>
        </div>
        <div 
          v-for="(payment, index) in paymentsCashEntries" 
          :key="index" 
          class="orders-bottom-item"
        >
          <span class="text-left">
            {{ $t("ordertotal.paid") }} - {{ payment.payment_type }}
          </span>
          <span class="text-right">
            {{ currency }} {{ payment.amount.toFixed(2) }}
            <span :style="{ color: 'red' }">
              {{ `(${payment.change_amount.toFixed(2)})` }}
            </span>
          </span>
        </div>
      </div>
      <div class="orders-bottom-options">
        <div 
          v-if="isPaymentTypesAvailable && showCheckoutButton"
          :class="{ 'disabled':!isPaymentTypesAvailable }" 
          class="pay"
          :style="{
            'background-color': activeRefund ? 'red' : ''}"
          @click="goToCheckout()"
        >
          <div class="d-flex align-items-center justify-content-between">
            <span 
              v-if="activeRefund" 
              class="text-left"
            >
              {{ $t("orderscreenv1.refund") }}
            </span>
            <!-- <span 
            v-if="activeRefund && isGiftCardRefund" 
            class="text-left"
          >
          Reimburse
          </span> -->
            <span 
              v-else 
              class="text-left"
            >
              {{ isKioskMode ? "Checkout" : $t("orderscreen.pay") }}
            </span>
                        
            <span class="text-right">
              {{ currency }} {{ currentOrderSum ? currentOrderSum.toFixed(2) : "0.00" }}
            </span>
          </div>
        </div>
      </div>

      <!-- Print panel For desktop -->
      <div 
        v-if="!isKioskMode" 
        class="print d-none d-md-block"
      >
        <b-row class="h-100 no-gutters">
          <b-col 
            cols="3" 
            class="h-100"
          >
            <b-button 
              :class="{ 'btn-disabled': itemsAtCurrentOrder.length === 0 }"
              :title="{ 'No active items': itemsAtCurrentOrder.length === 0 }" 
              variant="link" 
              class="w-100"
              @click="printCheck()"
            >
              <svg class="icon icon-icon-print-1">
                <use xlink:href="#icon-icon-print-1" />
              </svg>
              <span>
                {{ $t("amountandpaymentmethod.print-check") }}
              </span>
            </b-button>
          </b-col>
          <b-col 
            cols="3" 
            class="h-100"
          >
            <div 
              v-if="isKitchenPrintProcessing" 
              class="text-center spiner-overbutton"
            >
              <b-spinner 
                variant="primary" 
                label="Text Centered"
              />
            </div>
            <b-button 
              :class="{ 'btn-disabled': !kitchenUpdatesAvailable || isKitchenPrintProcessing || currentOrderSum < 0.01}"
              variant="link" 
              class="w-100" 
              @click="printKitchenCheck()"
            >
              <svg class="icon icon-icon-print-2">
                <use xlink:href="#icon-icon-print-2" />
              </svg>

              <span>
                {{ $t("amountandpaymentmethod.kitchen-print") }}
              </span>
            </b-button>
          </b-col>
          <b-col 
            cols="3" 
            class="h-100 d-none d-md-block"
          >
            <b-button 
              variant="link" 
              class="w-100" 
              @click="openDrawer()"
            >
              <svg class="icon icon-icon-print-3">
                <use xlink:href="#icon-icon-print-3" />
              </svg>

              <span>
                {{ $t("amountandpaymentmethod.open-drawer") }}
              </span>
            </b-button>
          </b-col>
          <b-col 
            cols="3" 
            class="h-100 d-none d-md-block"
          >
            <b-button 
              :class="{ 'btn-disabled': isSavingProcess }" 
              variant="link" 
              class="w-100"
              @click="showMoreOptionModal()"
            >
              <svg class="icon icon-icon-more-options">
                <use xlink:href="#icon-icon-more-options" />
              </svg>

              <span>
                {{ $t("amountandpaymentmethod.more-options") }}
              </span>
            </b-button>
          </b-col>
        </b-row>
        <MoreOptionsModal 
          v-if="employee" 
          :current-order="currentOrder" 
          :terminal-mode="terminalMode"
          :currency="currency" 
          :current-employee="employee" 
          :all-discounts="allDiscounts" 
          @hold-order="holdOrder"
          @save-discount-to-order="saveDiscountToOrder"
        />
      </div>
    </div>

    <CustomerModal
      :view-customer="viewCustomer"
      :is-selected="true"
      :index="2"
      @close-modal="clearViewCustomer"
    />
    <LeaveCurrentOrderModal 
      @hold-order="holdOrder" 
      @cancel-order="cancelOrder()" 
    />
  </div>
</template>

<script>
import orderMixin from "../../mixins/order/orderMixin";
import printMixin from "../../mixins/printMixin";
import syncMixin from "../../mixins/syncMixin";
import orderModifierMixin from "../../mixins/order/modifier";
import viewCustomerModalMixin from "../../mixins/modals/viewCustomerModalMixin";
import MoreOptionsModal from "../../modals/Order/MoreOptions/MoreOptionsModal";
import CustomerModal from "@/modals/CustomerModal"; 
import LeaveCurrentOrderModal from "../../modals/Order/LeaveCurrentOrderModal.vue";
// import { getItemGrandTotal} from "@/helpers/calc";
import { mapGetters, mapActions } from 'vuex'
import { toFixedNoRounding } from "@/helpers/utils";

export default {
    name: 'OrderPanel',
    components: {
      MoreOptionsModal,
      CustomerModal,
      LeaveCurrentOrderModal
    },
    props: {
        currentOrder: {
        type: Object,
        default: () => ({})
        },
        orderPanelOrderDiningOption: {
          type: Object,
            default: () => ({})
        },
        defaultDiningOption: {
          type: Object,
            default: () => ({})
        },
        paymentsCashEntries: {
          type: Array,
            default: () => ([])
        },
        currentOrderSum: {
          type: Number,
            default:0
        },
        orderPanelTable: {
          type: Object,
            default: () => ({})
        },
        isDisplayPaymentDetails: {
            type: Boolean,
            default:false
        },
        isLoad: {
            type: Boolean,
            default: false
        },
        isKitchenPrintProcessing: {
            type: Boolean,
            default: false
        },
        allDiscounts: {
            type: Array,
            default: () => ([])
        },
        discount: {
            type: Object,
            default: () => ({
                val: "",
                changed: false,
                type: 1
            })
        },
        orderItems: {
            type: Array,
            default:()=>([])
        },
        // giftCards: {
        //   type: Array,
        //   default: ()=>([])
        // }
    },
    mixins: [orderMixin, printMixin, orderModifierMixin, viewCustomerModalMixin, syncMixin],

    computed: {
        ...mapGetters({
            isOnline: "connection",
            terminalMode: "terminalMode",
            isShiftOpen: "isShiftOpen",
            products: "config/products",
            categories: "config/categories",
            employee: "employee",
            store: "config/store",
            isSavingProcess: "isSavingProcess",
            isKioskMode: "config/isKioskMode",
            updatingOrder: "orders/getUpdatingOrder",
            currency: "config/currency",
            refundItems: 'orders/refundItems',
            refundOrderId: 'orders/refundOrderId',
            activeGiftCard: 'giftVoucher/activeGiftCard',
            selectedGiftCards:"giftVoucher/selectedGiftCards",
        }),
      showCheckoutButton() { 
        if ((this.currentOrder.items.length >= 0.00 && this.currentOrder.items.find(item => item.void_status === 0)))
          return true
          return false
      },
      activeRefund() { 
        if (this.currentOrderSum < 0.00 && this.currentOrder.items.find(item => item.quantity < 0 && item.void_status === 0))
          return true
      return false
      }
    },
  methods: {
    ...mapActions({
      setGiftVoucherVisiblity:'giftVoucher/setGiftVoucherVisiblity'
    }),
        toFixedNoRounding,
        
        showDiningOptionsModal() {
            this.$emit('showDiningOptionsModal')

        },
        async showSelectCustomerModal() {
            this.$emit('showSelectCustomerModal')

        },
        showPriceAdjustModal(item) {
            this.$emit('showPriceAdjustModal',item)

        },
        showNotesModal(index) {
            this.$emit('showNotesModal',index)

        },
        displayPaymentDetails() {
            this.$emit('displayPaymentDetails')

        },
        async goToCheckout() {
            this.$emit('goToCheckout')

        },
        async holdOrder(payload) {
            this.$emit('holdOrder',payload)
  
        },
        async saveDiscountToOrder(value, discountType) {
            const payload = {
                value,
                discountType
            }
            this.$emit('saveDiscountToOrder',payload)

        },
        changeDiscount(itemId, name=null) {
          if(!name)
            this.$emit("changeDiscount",itemId)

        },
        modifiersOpen(itemId) { 
            this.$emit("modifiersOpen", itemId)
        },
        showCollapse(index) {
            return this.itemsAtCurrentOrder.length > 0 && this.itemsAtCurrentOrder.length - 1 === index
        },
        scrollToBottom() {
          /**
           *  Scroll to the bottom of the container orders-side-body
           * 
           */
            var scrollableDiv = document.getElementById('orders-sidebar-body');
            if(scrollableDiv)
              scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
        },
        async showMoreOptionModal(){
          // this.checkInternetConnectivity().then((res)=>{this.setGiftVoucherVisiblity(res)}).catch(err=>{{this.setGiftVoucherVisiblity(err)}})
          this.$bvModal.show('more-options-modal')
        },
        getGiftCardNumber(local_id){
          console.log(this.selectedGiftCards)
          return this.selectedGiftCards.find(card=>card.order_item_local_id === local_id)?.card_number
        }
    },
  watch: {
    orderItems() { 
      // scroll to the bottom of the list as the item adds on after a awaiting of 10 milisec
      setTimeout(this.scrollToBottom, 10);
    }
  }
}
</script>
<style lang="scss" scoped>
.f-small{
  font-size:small;
}
</style>
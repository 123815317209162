<template>
  <b-modal 
    no-close-on-backdrop
    hide-footer
    hide-header
    no-fade
    size="huge"
    id="surcharge-modal"
    modal-class="surcharge-popup"
  >
    <div class="text-center modal-complete">
      <svg class="icon icon-icon-popup-cansel">
        <use xlink:href="#icon-icon-popup-cansel" />
      </svg>

      <h2>
        {{ modal.title }}
      </h2>

      <span class="popup-meta mb-5">
        This payment method has a {{ surchargeValue }}% fee on top of your order total
      </span>

      <div class="surcharge-details">
        <div class="container d-flex flex-column justify-content-center">
          <div class="d-flex w-100">
            <span class="w-100 text-left">Order:</span>
            <span class="w-100 text-right">$ {{ (orderGrandTotal).toFixed(2) }}</span>
          </div>
          <div class="d-flex w-100">
            <span class="w-100 text-left">Surcharge:</span>
            <span class="w-100 text-right">$ {{ (surchargedValue).toFixed(2) }}</span>
          </div>
          <div class="d-flex w-100">
            <span class="w-100 text-left"><strong>Total Charge:</strong></span>
            <span class="w-100 text-right"><strong>$ {{ (orderGrandTotal+surchargedValue).toFixed(2) }}</strong></span>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-between align-items-center">
        <b-button
          variant="outline-primary"
          @click="cancelSurcharge"
        >
          {{ modal.cancelActionText }}
        </b-button>
        <b-button
          variant="primary"
          @click="addSurcharge"
        >
          <!-- TODO: need to add translation -->
          {{ modal.confirmActionText }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import orderMixin from '../mixins/order/orderMixin';
export default{
    props:{
        surchargeValue:{
            type:Number,
            default:0
        },
        orderGrandTotal:{
            type:Number,
            default:0
        }
    },
    mixins:[orderMixin],
    data : ()=>({
        open:true,
        modal:{
            title:'Surcharge Notice',
            cancelActionText:"Cancel",
            confirmActionText:"Continue"
        },
        grandTotal : 0,
        surcharge: 0,
    }),
    computed:{
      surchargedValue(){
        return this.surchargeCharged(this.surchargeValue,this.orderGrandTotal)
      }
    },
    methods:{
        addSurcharge(){
          this.$emit('surcharge')
        },
        cancelSurcharge(){
          this.$emit('cancelSurcharge')
        }
    }
}
</script>
<style scoped lang="scss">
.modal-complete{
    &.popup-meta{
        margin-bottom: 25px;
        width:20px;
        align-self: center;
    }
}


</style>

import store from "../../store/store"

function serializeCountryValue(country=null){
    if(country){
      return store.getters['customers/countries'].find(listedCountry=> listedCountry.text === country)?.value
    }
}


function deSerializeCountryValue(country=null){
    if(country){
        return store.getters['customers/countries'].find(listedCountry=> listedCountry.value === country)?.text
    }
}
  

function serializeCustomerAddressList(addressList){
    addressList = addDefaultAttribute(addressList)
    addressList = addressList.map(address=>{
    if(address.country)
        address.country = serializeCountryValue(address.country)
    return address
})
return addressList
}

function addDefaultAttribute(addressList){
    return addressList.map(address=>{address.default = false; return address})
}


function serializeCustomerDefaultAddress(address){
    address.default = true
    if(address.country)
        address.country = serializeCountryValue(address.country)
    return address

}


  export default
  {
    serializeCustomerAddressList,
    serializeCustomerDefaultAddress,
    deSerializeCountryValue
  }


<template>
  <pos-layout>
    <div class="h-100 orders">
      <!-- Print panel For mobile -->
      <!-- <div class="print d-md-none">
        <b-row class="d-flex h-100 no-gutters">
          <b-col class="h-100 w-100 d-md-none">
            <b-button
              :to="{ name: 'Tables', params: { id: currentOrder.table }}"
              variant="link"
              class="w-100 bg-pink"
            >
              <svg class="icon icon-icon-table">
                <use xlink:href="#icon-icon-table" />
              </svg>

              <span>
                {{ $t("amountandpaymentmethod.print-to-tables-mob") }}
              </span>
            </b-button>
          </b-col>

          <b-col class="h-100 w-100">
            <b-button 
              variant="link" 
              class="w-100"
            >
              <svg class="icon icon-icon-print-1">
                <use xlink:href="#icon-icon-print-1" />
              </svg>

              <span>
                {{ $t("amountandpaymentmethod.print-check") }}
              </span>
            </b-button>
          </b-col>
          <b-col 
            v-if="currentOrder.status === ORDER_STATUS.REFUNDED" 
            class="h-100 w-100"
          >
            <b-button 
              variant="link" 
              class="w-100"
            >
              <svg class="icon icon-icon-print-2">
                <use xlink:href="#icon-icon-print-2" />
              </svg>

              <span>
                {{ $t("amountandpaymentmethod.kitchen-print") }}
              </span>
            </b-button>
          </b-col>
          <b-col 
            v-if="currentOrder.status === ORDER_STATUS.REFUNDED" 
            class="h-100 w-100 d-none d-md-block"
          >
            <b-button 
              variant="link" 
              class="w-100"
            >
              <svg class="icon icon-icon-print-3">
                <use xlink:href="#icon-icon-print-3" />
              </svg>

              <span>
                {{ $t("amountandpaymentmethod.open-drawer") }}
              </span>
            </b-button>
          </b-col>
          <b-col 
            v-if="currentOrder.status !== ORDER_STATUS.CANCELLED" 
            class="h-100 w-100 d-none d-md-block"
          >
            <b-button 
              variant="link" 
              class="w-100"
            >
              <svg class="icon icon-icon-print-4">
                <use xlink:href="#icon-icon-print-4" />
              </svg>

              <span>
                {{
                  currentOrder.status === ORDER_STATUS.REFUNDED ? $t("amountandpaymentmethod.credit-card") : $t("amountandpaymentmethod.show-payments")
                }}
              </span>
            </b-button>
          </b-col>

          <b-col 
            v-if="!sidebarMob" 
            class="h-100 w-100 d-md-none"
          >
            <b-button
              variant="link"
              class="w-100 bg-primary"
              @click="sidebarMob = true"
            >
              <svg class="icon icon-icon-mob-view">
                <use xlink:href="#icon-icon-mob-view" />
              </svg>
              <span>
                {{ $t("amountandpaymentmethod.print-view-orders-mob") }}
              </span>
            </b-button>
          </b-col>

          <b-col 
            v-if="sidebarMob" 
            class="h-100 w-100 d-md-none"
          />
        </b-row>
      </div> -->

      <b-row class="h-100 no-gutters customer-display-container">
        <b-col
          col
          cols="12"
          sm="12"
          md="5"
          lg="5"
          xl="4"
          class="h-100 customer-display-container__left-container"
        >
          <div class="customer-display-container__left-container--sidebar">
            <div class="list">
              <div class="item">
                <p>Customer Display System</p>
              </div>
            </div>
          </div>
        </b-col>

        <b-col 
          col
          cols="12"
          sm="12"
          md="7"
          lg="7"
          xl="8"
          class="h-100 orders-col order-list-container customer-display-container__right-container"
        >
          <div class="customer-display-container__right-container--content">
            <div class="image">
              <img
                v-if="!this.customerDisplayDeviceConnected"
                src="../assets/images/svg/cd-icon.svg"
                alt=""
              >
              <img
                v-else
                src="../assets/images/svg/check.svg"
                alt=""
              >
            </div>
    
            <h2>{{ !this.customerDisplayDeviceConnected ? "The customer display system is not connected." : "Customer display system connected" }}</h2>
            <p v-if="!this.customerDisplayDeviceConnected">
              <span
                v-html="!this.connectionCode 
                  ? `<p>To connect, click 'connect' below and enter the following details into your Customer Display device:</p><p>Domain Name: <strong>${domainName}</strong></p><p>Terminal Number: <strong>${terminal.id}</strong></p>`  
                  : `<p>To reconnect, click 'reconnect' below and enter the following details into your Customer Display device:</p><p>Domain Name: <strong>${domainName}</strong></p><p>Terminal Number: <strong>${terminal.id}</strong></p>` "
              />
            </p>
            <p v-else>
              The transactions should now be shown on your customer display screen.
            </p>
            <button @click="getConnectionKey()">
              {{ this.connectionCode === '' ? "CONNECT" : "RECONNECT" }}
            </button>
          </div>
        </b-col>
      </b-row>
    </div>
  </pos-layout>
</template>
<script>
import PosLayout from "../layouts/PosLayout";
import {mapGetters} from 'vuex'
import customerDisplayMixin from "@/mixins/customerDisplayMixin";
export default {
    components: {
        PosLayout
    },
    mixins:[ customerDisplayMixin],
    data: () => ({
        code: '',
        bodyBgVariant: 'light',
        loader: {
            show: false,
            theme: 'dark'
        },
        customerDisplayDeviceConnected: false,
        domainName: ''

  }),
  computed: {
    ...mapGetters({
      connectionCode : "customerDisplay/connectionCode",
      terminal: 'config/terminal',
      baseUrl: "config/baseUrl",
    }),
  },
  methods: {
    getCodeSymbol(position) {
            if(this.connectionCode.length > 0)
              if (position >= this.connectionCode.length) {
                return "";
            } else {
                return this.connectionCode[position];
            }
        },

  },
  mounted() {
    this.domainName = this.baseUrl.split("//")[1].split(".")[0];
  }
}
</script>
<style lang="scss" scoped>
.customer-display-container {
  &__left-container {
    height: 100%;
    background-color: #fff;
    &--sidebar {
      height: 80vh;
      overflow-y: auto;
      background-color: #fff;
      .list {
        height: 100%;
        .item {
          padding: 20px;
          color: #007BFF;
          background-color: #fff;
          border-bottom: 1px solid #E5EAED;
          cursor: pointer;
          p {
            margin-bottom: 0;
            font-size: 18px;
            line-height: 24px;
            text-align: left;
          }
        }

      }
    }
    @media screen and (max-width: 768px) {
      height: fit-content !important;
      &--sidebar {
        height: fit-content;
      }
    }
  }
  &__right-container {
    padding: 100px 20px;
    background-color: #EEEEEE;
    display: flex;
    align-items: center;
    justify-content: center;
    &--content {
      max-width: 440px;
      margin: 0 auto;
      .image {
        width: 100px;
        height: 100px;
        border-radius: 100%;
        display: block;
        margin: 0 auto 24px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 100%;
          object-fit: none;
          background-color: #007BFF1A;
        }
      }
      h2 {
        font-size: 19px;
        font-weight: 600;
        margin-bottom: 16px;
      }
      p {
        font-size: 14px;
        max-width: 384px;
        margin: 0 auto;
        line-height: 20px;
        margin-bottom: 80px;
      }
      button {
        background-color: #007BFF;
        color: #fff;
        width: 240px;
        height: 60px;
        border: none;
        outline: none;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 40px;
        border-radius: 5px;
      }
      .pin-num {
        .pin-num-list {
          width: 100%;
          max-width: 100%;
          li {
            span {
              background-color: #fff;
              border: 2px solid #B6B6B6;
              height: 50px;
              width: 36px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight:bold;
              font-size:16px;
            }
          }
        }
      }
    }
  }
}

</style>
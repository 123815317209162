<template>
  <b-modal
    id="modal-auth-code-notify"
    hide-footer
    hide-header
    modal-class="success-popup success-popup_wrong"
    centered
  >
    <div class="text-center modal-complete">
      <svg class="icon icon-icon-popup-wrong">
        <use xlink:href="#icon-icon-popup-wrong" />
      </svg>

      <h2>
        {{ $t('verificationcodeinputpopup.verification-error-title') }}
      </h2>

      <div class="popup-meta">
        {{ $t('verificationcodeinputpopup.verification-error') }}
      </div>

      <div class="d-flex justify-content-center align-items-center">
        <b-button
          variant="outline-warning"
          class="w-100"
          @click="$bvModal.hide('modal-auth-code-notify')"
        >
          {{ $t('errorpopup.close') }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: "ModalAuthorizationNotify"
};
</script>

export const itemPriceAdjust = {
  namespaced: true,
  state: {
    item: null,
    index: null,
    price: null
  },
  mutations: {
    setItem: (state, payload) => {
      state.item = payload;
    },
    setIndex: (state, payload) => {
      state.index = payload;
    },
    setPrice: (state, payload) => {
      state.price = payload;
    }
  },
  getters: {
    item: (state) => {
      return state.item
    },
    index: (state) => {
      return state.index
    },
    price: (state) => {
      return state.price
    }
  }
};

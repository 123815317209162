import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "swiper/css/swiper.css";
import Vue from "vue";

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import i18n from "@/plugins/i18n";
import BootstrapVue from "bootstrap-vue";
import VueAwesomeSwiper from "vue-awesome-swiper";
import App from "./App";
import router from "./router";
import store from "./store/store";
import Vuex from "vuex";
import VuejsDialog from "vuejs-dialog";
import VueLogger from "vuejs-logger";
import { EMPLOYEE_ROLE } from "./helpers/employee/role";
import { TERMINAL_MODE } from "./helpers/terminal/mode";
import VueAxios from "vue-axios";
import axios from "axios";
import ApiService from "./core/services/api.service";
import indexDbService from "./helpers/dbHelper"


const isProduction = process.env.NODE_ENV === "production";

const options = {
  isEnabled: true,
  logLevel: isProduction ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true
};


Vue.use(VueLogger, options);

const RSVP = require("rsvp");

Vue.use(RSVP);
Vue.use(BootstrapVue);
Vue.use(VueAwesomeSwiper);
Vue.use(Vuex);
Vue.use(VuejsDialog);
Vue.use(VueAxios, axios);
Vue.use(require("vue-moment"));
Vue.config.productionTip = false;
Vue.config.devtools = true;

indexDbService.initialize()

const connection = localStorage.getItem("connection");
let dashboardUrl = connection ? JSON.parse(connection)["url"] : "";

console.log(process.env.NODE_ENV);

// START For local connection
// dashboardUrl = "http://localhost:8000";
// const authConfig = {
//     url: dashboardUrl,
//     token: 'cc3a76e0-997c-4e8b-a7b9-a9a9e37cbd6c'
// };
// store.dispatch("config/setAuthConfig", authConfig);
// END For local connection

const client = dashboardUrl ? dashboardUrl.split("//")[1].split(".biyo.co")[0] : "";
if (connection) {
  Sentry.setTag("dashboard_link", dashboardUrl);
  Sentry.setTag("client", client);
  Sentry.setTag("version", process.env.BIYO_VERSION);
}

// todo remove for dev, temp for testing
if (isProduction) {
  Sentry.init({
    Vue,
    dsn: "https://eb017330dfa34129b959bb7389008c32@o301527.ingest.sentry.io/5761961",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      })
    ],
    tracesSampleRate: 1.0,
    environment: client === "dev" ? "development" : "production"
  });
}

// reset/stop sync in progress
store.commit("endSync");
// ApiService.init();
let initialSyncDone = false;
router.beforeEach(async function (to, from, next) {
  const isUnlocked = store.getters.isUnlocked;
  const QUICK_SERVICE_ROUTES = [
    "Auth",
    "AuthQuick",
    "Synchronize",
    "Pin",
    "OrdersList",
    "Order",
    "OnlineOrder",
    "OrderCheckout",
    "Shift",
    "Tools",
    "Settings"
  ];
  const KIOSK_MODE_ROUTES = [
    "KioskHome",
    "KioskOrderType",
    "Order",
    "Tools",
    "Synchronize",
    "OrderCheckout"
  ];
  if (from.path === to.path) return true;
  if (to.name === "Auth") return next();
  else if (to.name === "AuthQuick") return next();
  const baseUrl = localStorage.getItem("baseUrl")
  ApiService.init();
  if (!baseUrl) return next("auth");
  if (!initialSyncDone && localStorage.getItem('connection') === null) {
    initialSyncDone = true;
    return next({ name: "Synchronize" });
  } else if (
    to.name === "Auth"
    || to.name === "AuthQuick"
    || to.name === "Synchronize"
    || to.name === "Reset"
    || to.name === "NewOrderRedirect"
  ) return next();
  let terminal = store.getters["config/terminal"];
  if (!terminal) {
    await store.dispatch("config/fetchTerminal");
    terminal = store.getters["config/terminal"];
  }
  if (!terminal) {
    return next("auth");
  }
  const terminalMode = TERMINAL_MODE[terminal.mode];
  const isKioskMode = terminalMode === "KIOSK_MODE";
  // skip pin for Kiosk Mode
  if (isKioskMode && !KIOSK_MODE_ROUTES.includes(to.name)) {
    return router.push({
      name: "KioskHome"
    }, () => {});
  }

  if (to.name !== "Pin" && !isUnlocked && terminalMode !== "KIOSK_MODE" && to.name !== "Tools") return next({ name: "Pin" });

  if (
    terminalMode === "QUICK_SERVICE"
    && !QUICK_SERVICE_ROUTES.includes(to.name)
  ) return router.push({
    name: "Order",
    params: {
      tableID: null,
      id: "new"
    }
  }, () => {});

  if ((to.name === "Order" || to.name === "OrderCheckout")) {
    // store.dispatch("orders/fetchOrdersFromDb")
    const order = to.params.id !== "new" ? store.getters["orders/getOrderByIdOrLocalId"]((to.params.id)) : null;
    const employee = store.getters.employee;
    const dbSettings = store.getters["config/terminal"]["db_settings"];
    if (!dbSettings) return next();
    const allowAllEmployeeOrders = dbSettings.find(setting => setting.name === "allow_all_employee_orders");
    if (
      (allowAllEmployeeOrders && allowAllEmployeeOrders.value === "false") && !isKioskMode
      && employee.role === EMPLOYEE_ROLE.Cashier
      && order && order.emp_open !== employee.id
    ) {
      return store.dispatch("errorModal/displayModalError", {
        modalId: "access-error",
        errorBody: {
          title: i18n.t("access-order-popup.title"),
          info: i18n.t("access-order-popup.text")
        }
      });
    } else if (order && order.locked_by && order.locked_by !== terminal.id) {
      const store_terminal = store.getters["config/storeTerminals"] || [];
      const locked_by_terminal = store_terminal.find(t => t.pk === order.locked_by);
      return store.dispatch("errorModal/displayModalError", {
        modalId: "access-error",
        errorBody: {
          title: i18n.t("locked-order-popup.title"),
          info: `${i18n.t("locked-order-popup.text")}: Terminal #${order.locked_by}${locked_by_terminal ? " - " + locked_by_terminal.name : ""}`
        }
      });

    }
  }
  // toggle calling of open and recent orders on table view
  if ((to.name === 'Tables' && (from.name !== 'Order' ||  from.name !== 'OrderCheckout')) && terminalMode !== "QUICK_SERVICE") { 
      store.dispatch('toggleCallOrderList',true)
  }
  return next();
});

const app = new Vue({
  i18n,
  router,
  store,
  data: {
    syncing: null
  },
  created: async function () {
    if (!("serviceWorker" in navigator) || !("SyncManager" in window)) {
      window.addEventListener("offline", () => {
        store.dispatch("setConnection", false);
      });
      window.addEventListener("online", () => {
        store.dispatch("setConnection", true);
      });
    }
  },
  mounted() {
    window.addEventListener("offline", () => {
      store.dispatch("setConnection", false);
    });

    window.addEventListener("online", () => {
      store.dispatch("setConnection", true);
    });
  },
  async beforeDestroy () {
    clearInterval(this.syncing);
    await navigator.serviceWorker.removeEventListener("message", this.updateLocalId);
  },
  render: h => h(App)
}).$mount("#app");

if (window.Cypress) {
  window.app = app;
}

document.addEventListener("touchmove", function (event) {
  if (event.scale !== 1) { event.preventDefault(); }
}, false);

<template>
  <div>new</div>
</template>

<script>
import router from '../router';

export default {
  name: 'NewOrderRedirect',

  data() {
    return {};
  },
  computed: {},
  async mounted() {
    await router.push({
      name: 'Order',
      params: {
        tableID: null,
        id: 'new'
      }
    })
  }
};
</script>

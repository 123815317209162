<template>
  <div 
    class="kiosk h-100 text-center" 
    @click="goToOrderType()"
  >
    <b-container 
      v-if="store" 
      fluid 
      class="h-100"
    >
      <b-row class="h-100 align-content-center">
        <img 
          :src="store.background" 
          class="kiosk-bg"
        >
        <img 
          :src="store.logo" 
          class="kiosk-logo"
        >
        <img 
          v-long-press="5000" 
          src="../assets/images/powered.svg" 
          class="kiosk-powered" 
          @long-press-start="goToTools"
        >

        <div class="col-12">
          <div class="kiosk-inner">
            <h1>Welcome</h1>
            <h3>
              Touch to begin your order
            </h3>
          </div>
        </div>

        <div 
          v-if="terminal" 
          class="terminal-version"
        >
          {{ terminal.name }}: {{ terminal.id }}
        </div>
      </b-row>
    </b-container>
    <scale-loader 
      v-if="!store" 
      :color="spinner.color" 
      :size="spinner.size"
    />
  </div>
</template>

<script>
import LongPress from "vue-directive-long-press";

export default {
  name: "KioskHome",
  components: { ScaleLoader: () => import("vue-spinner/src/ScaleLoader.vue") },
  directives: {
    "long-press": LongPress
  },
  data () {
    return {
      spinner: {
        size: "50px",
        color: "#007bff"
      }
    };
  },
  computed: {
    terminal () {
      return this.$store.getters["config/terminal"];
    },
    store () {
      return this.$store.getters["config/store"];
    }
  },
  methods: {
    goToOrderType () {
      this.$router.push({ name: "KioskOrderType" });
    },
    goToTools () {
      this.$router.push({ name: "Tools" });
    }
  }
};
</script>

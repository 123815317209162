export default function makeUrl(endpoint) {
  const baseUrl = localStorage.getItem('baseUrl')

  if (baseUrl) {
    return `${baseUrl}/api${endpoint}`;
  } else {
    console.warn("data.config.baseUrl is undefined");
    return `/api${endpoint}`;
  }
}

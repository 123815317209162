export async function sendToKds (obj, printer, orderGeneral, isUpdate = false) {
  console.log(process.env.NODE_ENV);
  console.log("orderGeneral", orderGeneral);
  console.log("obj", obj);
  // console.log("orderId", orderGeneral.id);
  //expected kds argument
  // kds = {
  //   token: 'string',
  //   location: 'string',
  //   device: 'string' || 'all' // optional
  // }
  console.log('5555', printer.kds_device || "all");
  const myHeaders = new Headers();
  myHeaders.append("x-integration-token", printer.token);
  myHeaders.append("x-location-id", printer.kds_location);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("x-device-ids", printer.kds_device || "all");

  const raw = {
    "id": orderGeneral.id.toString(),
    "name": `Order ${orderGeneral.id.toString()}`,
    "terminal": orderGeneral.related_terminal.toString(),
    "time": orderGeneral.updated_at,
    "mode": orderGeneral.dining_option,
    "items": []
  };
  console.log(raw);
  obj.items.forEach((item) => {
    console.log("each", item);
    item.modifiers;
    raw["items"].push({
      id: item.id,
      name: item.name,
      qty: item.quantity,
      mods: item.modifiers.map((m) => {
        return { name: m.name };
      })
    });

  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(raw),
    redirect: "follow"
  };
  if (isUpdate) {
    fetch("https://demo-integrations-api.ftservices.cloud/integrators/kds-orders", { ...requestOptions, ...{ method: "PUT" } })
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log("error", error));
  } else {

    fetch("https://demo-integrations-api.ftservices.cloud/integrators/kds-orders", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log("error", error));
  }
}

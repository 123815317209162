
const state = () => ({
    connectionCode:'',
    connectionState:'Not Connected',
    webSocketConnetion: null,
    resend: false
});

// getters
const getters = {
    connectionCode :  state => state.connectionCode,
    connectionState : state => state.connectionState,
    resend: state => state.resend
};

// mutations
const mutations = {
    SET_CONNECTION_CODE: (state, connectionCode) => state.connectionCode = connectionCode,
    SET_CONNECTION_STATE: (state,connectionState) => state.connectionState = connectionState,
    SET_RESEND_CONNECTION_KEY_STATUS: (state, status) => { state.resend = status }
};

// actions
const actions = {
    setConnectionCode: ({commit}, connectionCode)=> commit('SET_CONNECTION_CODE',connectionCode),
    setConnectionState: ({commit},connectionState) => commit('SET_CONNECTION_STATE', connectionState),
    sendMessage: ({state},message) => {
        if (state.webSocketConnetion)
          state.webSocketConnetion.send(message)

    },
    setResendConnection: ({commit}, status) => { commit('SET_RESEND_CONNECTION_KEY_STATUS', status); console.log("RESEINFIN", status) }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

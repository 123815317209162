<template>
  <b-modal
    :id="id"
    hide-footer
    modal-class="success-popup"
    hide-header
  >
    <div class="text-center modal-complete">
      <svg class="icon icon-icon-popup-wrong">
        <use xlink:href="#icon-icon-popup-wrong--circle" />
      </svg>
      <h2 style="margin: 0;">
        <slot name="title" />
      </h2>
      <div class="popup-meta">
        <slot name="info">
          {{ $t('modal-info.try-again') }}
        </slot>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <b-button
          variant="outline-primary"
          class="w-100 m-3"
          @click="$bvModal.hide(id)"
        >
          Close
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
<script>
  export default {
    props: {
      id: {
        default: 'info-modal',
        type: String
      }
    }
  }
</script>

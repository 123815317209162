<template>
  <pos-layout>
    <template #navbar>
      <span>&nbsp;</span>
    </template>


    <div class="tables-wrap h-100">
      <b-container
        fluid
        class="text-center h-100 h-mob-100 px-0"
      >
        <div class="d-md-none tables-head-mob">
          <b-row>
            <b-col
              v-for="(tablesection, indexMob) in sections"
              :key="tablesection.id"
              :class="{ 'active': indexMob === activeIndex }"
              cols="3"
              @click="toSlide(indexMob)"
            >
              <div class="tables-head-mob-inner">
                <h4>
                  {{ tablesection.section_name }}
                </h4>
                <div>
                  ( {{ occupiedTables(indexMob)["countOccupied"] }} / {{ occupiedTables(indexMob)["countVacant"] }} )
                </div>
              </div>
            </b-col>
          </b-row>
        </div>

        <swiper
          ref="swiperTop"
          :options="swiperOptionTop"
          class="tables-swiper h-100"
        >
          <swiper-slide
            v-for="(tablesection, index) in sections"
            :key="tablesection.id"
            :class="'class-'+ index"
          >
            <div
              class="tables h-100"
              @click="() => isEditing?null:refresh()"
            >
              <div class="tables-head d-none d-md-block">
                <div class="tables-head-inner">
                  <h4>
                    {{ tablesection.section_name }}
                  </h4>

                  <div class="tables-head-info d-flex align-items-center justify-content-center">
                    <div class="tables-head-occupied">
                      {{ $t("tablescreen.occupied-tables") }}:
                      <span>
                        {{ occupiedTables(index)["countOccupied"] }}
                      </span>
                    </div>
                    <div class="tables-head-vacant">
                      {{ $t("tablescreen.vacant-tables") }}:
                      <span>
                        {{ occupiedTables(index)["countVacant"] }}
                      </span>
                    </div>
                  </div>
                </div>
                <b-button
                  v-if="!isEditing"
                  :class="[tablesListMode ? 'btn_list' : 'btn_table']"
                  variant="dark"
                  class="d-none d-md-block"
                  @click="toggleView()"
                >
                  {{ $t("tablescreen.view") }}
                </b-button>
                <b-button
                  v-if="!tablesListMode"
                  variant="light"
                  class="d-none d-md-block"
                  style="margin-right: 10px;"
                  @click="editOrSavePosition"
                >
                  {{ editOrSaveStr }}
                </b-button>
              </div>

              <div class="tables-body">
                <div
                  ref="tableWrapper"
                  :class="[!tablesListMode ? 'tables-list_table' : 'tables-list_list']"
                  class="list-unstyled tables-list"
                  @mousemove="editDragDrop($event)"
                  @mouseup="finishDragDrop($event)"
                  @mouseleave="finishDragDrop($event)"
                  @touchmove="editDragDrop($event)"
                  @touchend="finishDragDrop($event)"
                >
                  <div
                    v-for="table in tablesection.tables"
                    :class="['type_' + table.type, 'table_' + table.id,
                             {
                               'available': table.tableOrders.length === 0, 'busy': table.tableOrders.length > 0,
                               'moving': isTableAvailableForMoving(table),
                               'jiggle': isEditing && currentEditTable.id === -1}
                    ]"
                    :style="'left:'+table.x_value+'px; '+'top:'+table.y_value+'px'"
                    :key="table.id"
                    class="box tables-item"
                    @mousedown="startDragDrop(table, $event)"
                    @touchstart="startDragDrop(table, $event)"
                  >
                    <a @click.stop="goToRoute(table)">
                      {{ table.table_name }}
                      <br>
                      <span
                        v-if="table.tableOrders.length === 0"
                      >
                        [Available]
                      </span>
                      <div
                        v-if="table.tableOrders.length > 0"
                        class="tables-item__order-info--wrapper"
                      >
                        <span>{{ getCustomersFromOrder(table.tableOrders) }}</span>
                        <span>
                          [{{
                            table.tableOrders.length === 1 ? "#" + (table.tableOrders[0].id || table.tableOrders[0].local_id) : table.tableOrders.length + " Orders"
                          }}]
                        </span>
                        <span>
                          {{ getTimePassed(new Date(table.tableOrders[0].open_date)) }}
                        </span>
                        <span v-if="table.table_screen_employee">{{ table.table_screen_employee }}</span>
                      </div>

                    </a>
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>

          <div
            slot="pagination"
            class="swiper-pagination d-none d-md-block"
          />
        </swiper>
        <scale-loader
          :loading="isLoad"
          :color="'#007bff'"
        />
      </b-container>
    </div>
  </pos-layout>
</template>
<script>
import router from "../router";
import PosLayout from "../layouts/PosLayout";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import store from "../store/store";
import { ORDER_STATUS } from "@/helpers/order/status";
import { mapActions, mapGetters } from "vuex"
import * as Sentry from "@sentry/vue";
// import { getOrder } from "@/helpers/dbHelper";

export default {
  name: "Tables",
  store,
  components: {
    PosLayout,
    ScaleLoader
  },
  data () {
    return {
      isView: true,
      syncing: null,
      isEditing: false,
      tableEdit: [],
      currentEditTable: {
        id: -1,
        x_value: -1,
        y_value: -1
      },
      isLoad: false,
      movingOrder: null,
      pusher: null,
    };
  },
  computed: {
    ...mapGetters({
      baseUrl: "config/baseUrl",
      store: "config/store",
      tables: "config/getAllTables",
      orders: "orders/getAllOrders",
      isSync: "isSyncInProgress",
      callOrderList: "callOrderListOnTableInitall",
      sections:"sectionsWithTableOrders"
    }),
    tablesListMode () {
      return this.$store.getters.tablesListMode;
    },

    // sections() {
    //   // console.log("FETCHING AGAIN")
    //   // const sections = this.$store.getters["sectionsWithTableOrders"];
    //   // console.log(sections)
    //   // this.fetchOrdersFromDb()
    //   return this.tableSections 
    // },
    swiperOptionTop: function () {
      const self = this;

      return {
        watchSlidesVisibility: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        allowTouchMove: !this.isEditing,
        initialSlide: self.activeIndex,
        on: {
          slideChange: function () {
            self.$store.dispatch("setTableIndex", { activeIndex: self.$refs.swiperTop.$swiper.activeIndex });
          }
        }
      };
    },
    editOrSaveStr: function () {
      return this.isEditing ? this.$t("tablescreen.save") : this.$t("tablescreen.edit");
    },
    isShiftOpen () {
      return this.$store.getters.isShiftOpen;
    },
    activeIndex () {
      return this.$store.getters.activeTableIndex;
    }
  },
  async created() {
    if (navigator.onLine) {
      this.$store.dispatch("orders/SetOfflineOrdersToTable")
      // await this.fetchGiftCards()
      await this.fetchGiftCardPayments()
    }
    else {
      this.$store.dispatch("orders/SetOfflineOrdersToTable")
    }
    if (this.tables.length === 0 )
      await this.fetchTablesFromDb()
    if (this.orders.length === 0)
      await this.fetchOrdersFromDb()
    this.tablesections = this.$store.getters["config/sections"];
    this.movingOrder = this.$route.params.moveorder ? this.$store.getters["orders/getOrderByIdOrLocalId"](this.$route.params.moveorder) : null;
    this.syncing = setInterval(function () {
      if (this.isEditing || !navigator.onLine) {
        this.$store.dispatch("orders/SetOfflineOrdersToTable")
        return true;
      }
    }.bind(this), 5000);
  },
  beforeDestroy() {
    clearInterval(this.syncing);
  },
  watch:{
    callOrderList:{
      async handler(val) {
        if (val) {
          await this.$store.dispatch("orders/fetchOpenOrders")
          await this.$store.dispatch("orders/fetchUpdatedTableOrders")
          // console.log("FETCHING AGAIN ggg")
          // this.$forceUpdate();
          // this.tableSections = this.$store.getters["sectionsWithTableOrders"]
          // this.reRender++
          // this.orders.forEach(order => { 
          //   if (order.id === 11991) { 
          //     console.log(order.id, order.status)
          //   }
          // })
          // console.log(this.tableSections)
          // // this.reRender++
          this.toggleCallOrderList(false)
        }
        // else { 
        //   this.$forceUpdate();
        //   this.reRender++
        //   console.log(this.reRender)
        // }
      },
       immediate:true
    }
   },
  methods: {
    ...mapActions({
      fetchTablesFromDb: 'config/fetchTablesFromDb',
      fetchOrdersFromDb: 'orders/fetchOrdersFromDb',
      toggleCallOrderList: 'toggleCallOrderList',
      // fetchGiftCards: "giftVoucher/fetchGiftCards",
      fetchGiftCardPayments: "giftVoucher/fetchGiftCardPayments"

     }),
    getCustomersFromOrder (orders) {
      const customers = [];
      orders.map(order => order.customer ? customers.push(`${order.customer.first_name} ${order.customer.last_name}`) : "");
      if (!customers.length) return "";
      return customers.length === 1 ? customers[0] : `${customers.length} ${this.$t("tablescreen.customers")}`;
    },
    refresh: async function () {
      console.log("refresh");
      this.isLoad = true;
      try {
        await store.dispatch("sync");
      } catch (e) {
        Sentry.captureException(e);
        console.error(e);
      }
      this.isLoad = false;
    },
    async goToRoute (table) {
      if (this.isEditing) return true;
      if (this.$route.params.moveorder) return this.moveOrderAndGo(table.id);

      const self = this;
      if (!self.isShiftOpen) {
        return await self.$router.push({ name: "Shift" });
      }
      table = await store.getters.getTableWithOrders(table.id);
      if (!table) {
        throw Error(`Could not find table with  table.id:${table.id}`);
      }
      console.log("TABLE ",table)
      const countOrders = table.tableOrders.length;
      switch (true) {
        case countOrders === 0:
          return self.$router.push({
            name: "Order",
            params: {
              tableID: table.id,
              id: "new"
            }
          }, () => {
          });
        case countOrders === 1:
          return await self.$router.push({
            name: "Order",
            params: {
              tableID: table.id,
              id: (table.tableOrders[0].id || table.tableOrders[0].local_id)
            }
          });
        case countOrders > 1:
          return self.$router.push({
            name: "TableOrders",
            params: {
              id: table.id
            }
          }, () => {
          });
      }
    },
    startDragDrop (table) {
      if (this.isEditing) {
        this.dragDrop = true;
        this.currentEditTable = table;
      }
    },
    editDragDrop (e) {
      if (this.isEditing && this.dragDrop) {
        if (e instanceof MouseEvent) {
          this.currentEditTable.x_value = e.clientX - 50;
          this.currentEditTable.y_value = e.clientY - 100;
        } else {
          this.currentEditTable.x_value = e.changedTouches[0].clientX - 50;
          this.currentEditTable.y_value = e.changedTouches[0].clientY - 100;
        }
      }
    },
    finishDragDrop: async function () {
      if (!this.isEditing || !this.dragDrop) return false;
      const index = this.tableEdit.findIndex(item => item.id === this.currentEditTable.id);
      index !== -1 ? this.tableEdit[index] = this.currentEditTable : this.tableEdit.push(this.currentEditTable);
      const tableIndex = this.tablesections[this.activeIndex].tables.findIndex(table => table.id === this.currentEditTable.id);
      this.tablesections[this.activeIndex].tables[tableIndex] = this.currentEditTable;
      this.$store.commit("config/setTable", this.currentEditTable);
      this.currentEditTable = {
        id: -1,
        x_value: -1,
        y_value: -1
      };
      this.dragDrop = false;
      this.$store.dispatch("config/uploadUpdatedTables");
    },
    editOrSavePosition () {
      if (!this.isEditing) {
        this.$refs.swiperTop.$swiper.detachEvents();
      } else {
        this.$refs.swiperTop.$swiper.attachEvents();
      }
      return this.isEditing = !this.isEditing;
    },
    toSlide (i) {
      this.$refs.swiperTop.$swiper.slideTo(i);
    },
    toggleView: function () {
      this.isView = !this.isView;
      this.$store.commit("toggleTableListMode");
    },
    // sortTables: function () {
    //   // const self = this;
    //   // this.tablesections.forEach(function (section, index) {
    //   //   self.tablesections[index].tables.sort((a, b) => (a.table_name > b.table_name) ? 1 : ((b.table_name > a.table_name) ? -1 : 0));
    //   // });
    // },
    // moving: function () {
    //   return this.$route.params.moveorder;
    // },
    // tableID: function () {
    //   return this.$route.params.tableID ? this.$route.params.tableID : null;
    // },
    occupiedTables: function (sectionIndex) {
      let countOccupied = 0;
      let countVacant = 0;
      for (const tableIndex in this.sections[sectionIndex]["tables"]) {
        if (this.sections[sectionIndex]["tables"][tableIndex]["tableOrders"].length > 0) {
          countOccupied++;
        } else {
          countVacant++;
        }
      }
      return { "countOccupied": countOccupied, "countVacant": countVacant };
    },
    getTimePassed (datetimeFrom) {
      const diff = new Date() - datetimeFrom.getTime();
      let msec = diff;
      const hh = Math.floor(msec / 1000 / 60 / 60);
      const days = parseInt((diff) / (1000 * 60 * 60 * 24), 10);
      msec -= hh * 1000 * 60 * 60;
      const mm = Math.floor(msec / 1000 / 60);
      msec -= mm * 1000 * 60;
      const ss = Math.floor(msec / 1000);
      msec -= ss * 1000;
      let timeToReturn = days > 0 ? days + " day(s) " : "";
      timeToReturn = timeToReturn + (hh.toString().length === 1 ? "0" + hh.toString() : hh);
      timeToReturn = timeToReturn + ":" + (mm.toString().length === 1 ? "0" + mm.toString() : mm.toString());
      return timeToReturn;
    },
		// eslint-disable-next-line no-unused-vars
    syncOrder: async function (idOfOrder, newTableId) {
      // const order = await getOrder(idOfOrder);
      // order.table = newTableId;
      // await this.saveAndStartSyncing(order);
    },
    isTableAvailableForMoving (table) {
      return this.$route.params.moveorder && (parseInt(this.$route.params.tableID) !== parseInt(table.id) || this.movingOrder.status === ORDER_STATUS.HOLD);
    },
    moveOrder (orderID, tableFromID, tableToID) {
      console.log("[dbHelper] moveOrder", tableToID);
      if (!orderID || !tableFromID || !tableToID || !this.movingOrder) {
        throw Error("moveOrder does not have all required parameters");
      }
      console.log("qwer", { ...this.movingOrder, ...{ table: tableToID } });
      this.$store.commit("orders/saveOrder", { ...this.movingOrder, ...{ table: tableToID, status: ORDER_STATUS.OPEN } });
      this.$store.dispatch('sync')
    },
    moveOrderAndGo: async function (tableID) {
      this.isLoad = true;
      this.moveOrder(this.$route.params.moveorder, this.$route.params.tableID, tableID);
      this.isLoad = false;
      await router.push({ name: "TableOrders", params: { id: tableID } }, () => {});
    },
  }
};
</script>

<style lang="scss">
.swiper-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  background: #D8D8D8;
  opacity: 1;
  outline: none;
  transition: all .2s ease-in-out;

  &-active {
    background: #1976D2;
  }
}

.jiggle {
  -webkit-animation: jiggle 0.2s infinite;
  -moz-animation-duration: 0.2s;
  -moz-animation-name: jiggle;
  -moz-animation-iteration-count: infinite;
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  transition: all 0.1s ease-out;
  touch-action: none;
}

@-moz-keyframes jiggle {
  0% {
    -moz-transform: rotate(-1deg);
  }
  50% {
    -moz-transform: rotate(1deg);
  }
}

@-webkit-keyframes jiggle {
  0% {
    -webkit-transform: rotate(-1deg);
  }
  50% {
    -webkit-transform: rotate(1deg);
  }
}
</style>


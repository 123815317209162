import { toFixed } from "@/helpers/utils";
import { ORDER_STATUS } from "@/helpers/order/status";
import router from "../../router";
import { EMPLOYEE_ROLE } from "@/helpers/employee/role";
import calculateTaxes from "./tax/calculateTaxes";
// import scaleMixin from "../scaleMixin";
import syncMixin from "../syncMixin";
import cdOrderMixin from "../cdOrderMixin";
import { mapGetters, mapActions } from "vuex";
import { calculateItemTaxTotal, getItemGrandTotal, getItemSubtotal, getModSum, getOrderGrantTotal, getOrderSubTotal, handlingRoundOffCalculations } from "../../helpers/calc";
import printMixin from "../printMixin";
import * as Sentry from "@sentry/vue";
import { PAYMENT_BEHAVIOR } from "../../helpers/order/payment/behavior";
// import { TRANSACTION_TYPE } from "@/helpers/order/transaction/types";

export default {
  data () {
    return {
      allowCashDiscount: null,
      table: null
    };
  },
  mixins: [calculateTaxes, syncMixin, cdOrderMixin, printMixin],
  computed: {
    ...mapGetters({
      terminal: "config/terminal",
      terminalPaymentTypes: "config/paymentTypes",
      refundOrderId: 'orders/refundOrderId',
      isKioskMode: "config/isKioskMode",
      wholeOrderDiscount : "orders/wholeOrderDiscount",
      showSelectedOrder: 'orders/showSelectedOrder',
      refundedGiftCards: 'giftVoucher/refundedGiftCards'
    }),
    orderDiningOption () {
      if (!this.terminal) return null;
      return this.terminal["dining_options"].find(option => this.currentOrder.dining_option_type === option.id);
    },
    itemsAtCurrentOrderLength () {
      return this.itemsAtCurrentOrder.length;
    },
    itemsAtCurrentOrder () {
      return this.currentOrder.items.filter(function (item) {
        return item.void_status !== 1;
      });
    },
    isPaymentTypesAvailable () {
      return this.paymentTypes.length !== 0 || this.isKioskMode;
    },
    paymentTypes() { 
      /**
       * this is computed property which filters out the payment types on the basis of cash discount property of db_settings
       * 
       */
      const cashDiscountSettingsEnabled = this.allowCashDiscount && this.allowCashDiscount.value === "true" && !this.isGiftCardRefund
      if (this.currentOrder && !this.isKioskMode) {
        // filter the paymentTypes (Cash,Credit,Int Credit, Int Debit.... etc.) on the basis of cash discount db_setting
        if (cashDiscountSettingsEnabled && this.currentOrder.cash_discount === true)
          return this.terminalPaymentTypes.filter(paymentType => paymentType.name === 'Cash')
        else if (cashDiscountSettingsEnabled && this.currentOrder.cash_discount === false)
          return this.terminalPaymentTypes.filter(paymentType => paymentType.name !== 'Cash')
        else if (this.isGiftCardRefund)
          return this.terminalPaymentTypes.filter(paymentType => paymentType.behavior === PAYMENT_BEHAVIOR.GIFT_CARD)
        else
          return this.terminalPaymentTypes
      }
        return this.terminalPaymentTypes
      
    },
    isGiftCardRefund(){
      return this.refundedGiftCards.length > 0 ?  true : false
    }
  },
  methods: {
    ...mapActions('orders',['setRefundOrderId','setEmptyCurrentOrder','setActiveOrderState','setWholeOrderDiscount','setSelectedOrder']),
    ...mapActions('giftVoucher',['removeSelectedGiftCard', 'updateSelectedGiftCards','setRefundGiftCardIds']),
    sync: async function (countSubtotal=true, callbackend=true) {
      /** 
       * calculates taxes, subtotal, grandtotal discounts and then syncs order to the background
       */
      console.log("[Order] sync");
      this.calculateAllItemTaxes();
      if(countSubtotal)
        this.countSubtotal();
      await this.$store.commit("orders/saveOrder", this.currentOrder);
      if (callbackend) {
        this.$store.dispatch("sync");
      } else {
        if (this.currentOrder.items.length === 1) {
          this.$store.dispatch("sync");
        }
      }
    },
    getItemPriceDisplay (item) {
      return toFixed(item.price, 2);
    },
    calculateItemCashDiscountChange (index, newItem = false) {
      /**
       * This function is used to add cahs discount when on or off
       */
      const item = this.currentOrder.items[index];
      this.currentOrder.items[index].price = this.currentOrder.cash_discount
        ? item.price / 1.04
        : (newItem ? item.price : item.price * 1.04);
      this.currentOrder.items[index].price_base = this.currentOrder.items[index].price 
      // this.currentOrder.items[index].price = this.currentOrder.cash_discount
      //   ? parseFloat(toFixed(item.price / 1.04, 2))
      //   : (newItem ? item.price : parseFloat(toFixed(item.price_base * 1.04, 2)));
      this.currentOrder.items[index].modifiers.forEach((mod, modIndex) => {
        this.currentOrder.items[index].modifiers[modIndex].price = this.currentOrder.cash_discount
          ? mod.price / 1.04
          : (newItem ? mod.price : mod.price * 1.04);
      });
      this.currentOrder.items[index].tax_items = this.calculateItemTaxItems(this.currentOrder.items[index]);
      this.currentOrder.items[index].tax = calculateItemTaxTotal(this.currentOrder.items[index]) || 0;
    },
    toggleCashDiscount () {
      if (!this.allowCashDiscount) return;
      this.currentOrder.cash_discount = !this.currentOrder.cash_discount;
      // remove gift card product from cash discounts as well
      for (const index of this.currentOrder.items.filter(item=>item.product!==-2).keys()) {
        this.calculateItemCashDiscountChange(index);
      }
      return this.sync();
    },
    getItemGrandTotal (item) {
      /**
       * This function is used to calculate the grand total of order's items.
       */

      //calculating modifiers sum
      let modifiersTotal = 0;
      if (item.modifiers) {
        item["modifiers"].forEach(function (mod) {
          if (!mod.void_status) modifiersTotal += mod.price_original;
        });
      }

      //caluclating item grand total 
       const itemGrandTotal = handlingRoundOffCalculations((wholeDiscountArray,price,discount,newQuantity,subTotal,modPrice)=>{
        return wholeDiscountArray.length > 0 ? newQuantity < 0 ? -1 * parseFloat(subTotal)  : parseFloat(subTotal) : parseFloat(toFixed((price-discount+modPrice)*newQuantity,2))
      },item,0,modifiersTotal)
      return parseFloat(itemGrandTotal)
      // return Math.max(0, parseFloat(toFixed(itemGrandTotal, 2)));
    },
    removeItemFromOrder: async function (itemId) {
      const self = this;
      const h = this.$createElement;
      const messageVNode = h("div", { class: ["modal-complete"] }, [
        h("div", {
          domProps: {
            innerHTML: `'<svg class="icon icon-icon-popup-remove">
                <use xlink:href="#icon-icon-popup-remove"></use>
                </svg>
                <h2>${this.$t("removeitempopup.remove-this-item")}</h2>
                <span class="popup-meta">
                    ${this.$t("removeitempopup.are-you-sure-you-want-to-remove-this-item")}
                </span>`
          }
        })
      ]);
      try {

        this.$bvModal.msgBoxConfirm([messageVNode], {
          id: "12345",
          title: [],
          centered: true,
          modalClass: "success-popup",
          hideHeader: true,
          footerClass: "d-flex justify-content-center align-items-center",
          cancelVariant: "outline-danger",
          okVariant: "danger",
          okTitle: "YES",
          "dialog-class": "qwerty2",
          cancelTitle: "NO",
          ststic: false,
          noFade: true
        }).then(async function (result) {
          if (result) {
            if (!self.isKioskMode && !self.employee) return false;
            if (!self.isKioskMode && self.employee.role && self.employee.role === EMPLOYEE_ROLE.Cashier && !self.isVerified) {
              self.lockedAction = "remove-item";
              self.lockedItemId = itemId;
              return self.$bvModal.show("enter-pin-modal");
            }
            await self.removeItemFromOrderProcessing(itemId);
          }
        }).catch(err => {
          console.info("[Order] something went wrong");
          console.error(err);
        });
      } catch (e) {
        Sentry.captureException(e);
        console.log(e);
      }
    },
    removeItemFromOrderProcessing (itemId) {
      /**
       * This function is used to remove item from the order
       */
      console.log("removeItemFromOrderProcessing");
      const self = this;
      //get the index of the item to be removed
      const position = self.currentOrder.items.findIndex(currentItem => currentItem.id == itemId);
      // mark the item void_status as true
      self.currentOrder.items[position].void_status = 1;
      self.currentOrder.items[position].voided_at = new Date().toISOString();
      self.removeSelectedGiftCard(self.currentOrder.items[position].local_id)

      // count all the essential paramters again
      self.countSubtotal();
      self.markUpdated(position, "item canceled");
      if (this.isKioskMode) {
        this.amountTenderedString = this.currentOrderGrandTotalSum * 100 + "";
      }
      self.$forceUpdate();
      // sync the order to the backend
      self.sync();
      if (this.currentOrder.discount_orders)
        this.countSubtotal(this.currentOrder.discount_orders_raw_value, this.currentOrder.discount_orders_raw_type);
    },
    countSubtotal: function (orderDiscountValue = null, discountType = null) {
      /**
       * This function is responsible for calculating all the essential calculations like order's tax total, subtotal, grand total, discount total
       */
     console.log(orderDiscountValue,discountType)
      this.currentOrder.subtotal = 0;
      this.currentOrder.discount_total = 0;
      //calculate whole order discount
      this.applyWholeOrderDiscount()

      //calculate item level grand total and order level discount total
      for (const itemIndex in this.currentOrder.items) {
        const item = this.currentOrder.items[itemIndex];
        if (!item.void_status) {
          this.currentOrder.items[itemIndex].grand_total = parseFloat((getItemGrandTotal(this.currentOrder.items[itemIndex])).toFixed(2));
          this.currentOrder.discount_total = parseFloat(toFixed((this.currentOrder.discount_total + this.roundOffDiscounts(item)),2));
          this.updateSelectedGiftCards({'order_item_local_id':this.currentOrder.items[itemIndex].local_id,"price":this.currentOrder.items[itemIndex].grand_total})
        }
      }

      //calcualte subtotal, grand total and tax total
      this.currentOrder.subtotal = parseFloat((getOrderSubTotal(this.currentOrder)).toFixed(2));
      this.currentOrder.tax_total = this.calculateOrderTax()
      console.log("CALCULATING TAx",this.calculateOrderTax())
      this.currentOrder.grand_total = parseFloat(toFixed(getOrderGrantTotal(this.currentOrder, this.products, this.isTaxInclusive), 2));
    },
    applyWholeOrderDiscount(){
      /**
       * This function is used to calculate the discount applied on the whole order
       */

      this.currentOrder.subtotal = 0;
      this.currentOrder.discount_total = 0
      // let itemsDiscountArray=[]

      // resetting the previously applied discounts for recalculation
      this.currentOrder.items = [...this.currentOrder.items.map((item)=>{
        item.discountPropotion = 0
        item.wholeDiscount = 0
        item.subtotal = getItemSubtotal(item)
        return item
      })]


      // calculate subtotal again
      this.currentOrder.subtotal = getOrderSubTotal(this.currentOrder)
      // do not include gift card in any kind of discounts
      let orderSubtotal = this.currentOrder.subtotal

      this.currentOrder.items.forEach(item=>{
        if(item.product === -2)
          orderSubtotal -= item.subtotal
      })
      // // defining variables for rounding of discounts
      // let maxPropotion = 0
      // let maxPropotionIndex = 0
      // store distributed discounts
      let distributedDiscounts = []
      this.currentOrder.items =  this.currentOrder.items.map((item,index)=>{
        let modifiersSum = 0;
        item.modifiers && item["modifiers"].forEach(function (mod) {
          if (!mod.void_status) modifiersSum += mod.price_original;
        });

        // modifiersSum *= item.quantity
        modifiersSum = modifiersSum * item.quantity

        if(!item.void_status && item.product !== -2){

        // check if the whole order discount is not is percentage
        if(this.currentOrder.discount_orders_raw_type === 2){
          const itemSubtotal = item.subtotal+modifiersSum
        // Determine each item's contribution to the total subtotal.
          
          const itemPropotion = this.roundToTwoDecimalPlaces(this.calulateItemPropotion(orderSubtotal,itemSubtotal))

        // if(maxPropotion < itemPropotion){
        //   maxPropotion = itemPropotion
        //   maxPropotionIndex = index
        // }
        // Allocate the discount proportionally.  
        const itemDiscount =  this.roundToTwoDecimalPlaces(this.calculatePerItemDiscount(itemPropotion,this.currentOrder.discount_orders))
        // push the discounts to the distributed discounts

        if((index+1) === this.currentOrder.items.length){ //if it is the last item
          // calculate sum of discounts of all the previous items
            const totalDiscounts = this.roundToTwoDecimalPlaces(distributedDiscounts.reduce((totalDiscount,discount)=>totalDiscount+discount,0 ))
        // if(distributedDiscounts.length > 0){
        if(totalDiscounts < this.currentOrder.discount_orders || totalDiscounts > this.currentOrder.discount_orders ){   
              item.discountPropotion = item.discountPropotion + (this.currentOrder.discount_orders - totalDiscounts)
              item.wholeDiscount = orderSubtotal >= 0 ? this.roundToTwoDecimalPlaces(item.discountPropotion/item.quantity,2) : -1 * this.roundToTwoDecimalPlaces(item.discountPropotion/item.quantity,2)
              distributedDiscounts.push( (this.currentOrder.discount_orders - totalDiscounts))
            }
        else{
        // if there is no rounding error
          item.discountPropotion = itemDiscount
          item.wholeDiscount = orderSubtotal >= 0 ? this.roundToTwoDecimalPlaces(item.discountPropotion/item.quantity,2) : -1 * this.roundToTwoDecimalPlaces(item.discountPropotion/item.quantity,2)
          distributedDiscounts.push(itemDiscount)
        }
        }
        else{// if it is not the last element
          item.discountPropotion = itemDiscount
          item.wholeDiscount = orderSubtotal >= 0 ? this.roundToTwoDecimalPlaces(item.discountPropotion/item.quantity,2) : -1 * this.roundToTwoDecimalPlaces(item.discountPropotion/item.quantity,2)
          distributedDiscounts.push(itemDiscount)
        }

        // Calculate taxes for each item based on discounted prices.
        item.tax_items = this.calculateItemTaxItems(item)
        item.tax = this.getItemAllTaxSum(item)

        console.log("THIs IS ITEM TAX", item.tax_items)
        console.log("THIs IS ITEM TAX", item.tax)

        }

      else if (this.currentOrder.discount_orders_raw_type === 1)
      {
          // Convert the discount percentage into the currency discount
          const totalDiscount = (orderSubtotal * (this.currentOrder.discount_orders / 100))

          // Calculate the propotions and discount to be distributed on items
          const itemPropotion = this.calulateItemPropotion(orderSubtotal,(item.subtotal+modifiersSum))
          const itemDiscount = this.calculatePerItemDiscount(itemPropotion,totalDiscount)
          // push the discounts to the distributed discounts

       
          if((index+1) === this.currentOrder.items.length){ //if it is the last item
            // calculate sum of discounts of all the previous items
              const totalDiscounts = this.roundToTwoDecimalPlaces(distributedDiscounts.reduce((totalDiscount,discount)=>totalDiscount+discount ,0))
  
          // if(distributedDiscounts.length > 0){
          if((totalDiscounts < this.currentOrder.discount_orders || totalDiscounts > this.currentOrder.discount_orders) && distributedDiscounts.lenght>0 ){   
                item.discountPropotion = item.discountPropotion + (this.currentOrder.discount_orders - totalDiscounts)
                item.wholeDiscount = orderSubtotal >= 0 ? this.roundToTwoDecimalPlaces(item.discountPropotion/item.quantity,2) : -1 * this.roundToTwoDecimalPlaces(item.discountPropotion/item.quantity,2)
                distributedDiscounts.push( (this.currentOrder.discount_orders - totalDiscounts))
              }
          else{
          // if there is no rounding error
            item.discountPropotion = itemDiscount
            item.wholeDiscount = orderSubtotal >= 0 ? this.roundToTwoDecimalPlaces(item.discountPropotion/item.quantity,2) : -1 * this.roundToTwoDecimalPlaces(item.discountPropotion/item.quantity,2)
            distributedDiscounts.push(itemDiscount)
          }
          }
          else{// if it is not the last element
            item.discountPropotion = itemDiscount
            item.wholeDiscount = orderSubtotal >= 0 ? this.roundToTwoDecimalPlaces(item.discountPropotion/item.quantity,2) : -1 * this.roundToTwoDecimalPlaces(item.discountPropotion/item.quantity,2)
            distributedDiscounts.push(itemDiscount)
          }
  
          // Calculate taxes for each item based on discounted prices.
          item.tax_items = this.calculateItemTaxItems(item)
          item.tax = this.getItemAllTaxSum(item)

      }

        
        }
        return item
      })

    },
    async cancelOrderProcessing () {
      /**
       * This function is used to cancel a order
       */
      const self = this;
      // sets the cancel status of the order 
      self.currentOrder.status = ORDER_STATUS.CANCELLED;
      // if(self.currentOrder.order_gift_card_payment)
      //   self.currentOrder.order_gift_card_payment.forEach(gift_card_payment=>{gift_card_payment.is_applied = false;gift_card_payment.transaction_type = TRANSACTION_TYPE.VOID} )
      self.currentOrder.related_terminal = self.terminal.id;
      // remove gift card payments on cancel order
      self.currentOrder.items.forEach(item=>{self.removeSelectedGiftCard(item.local_id)})
      // remove refunded gift cards on cancel order
      this.setRefundGiftCardIds([])
      self.currentOrder.close_date = new Date().toISOString();
      self.currentOrder.locked_by = null
      // save the order and sync to the backend
      await self.$store.commit("orders/saveOrder", self.currentOrder);
      this.$store.dispatch("sync");
      this.setRefundOrderId(null)
      // redirect if clicked on order number from gift card modal
      if(self.showSelectedOrder){
        this.$router.push({name:"OrdersList"})
        this.setActiveOrderState(false)
        this.setEmptyCurrentOrder(true)
        return
      }
      if (self.currentOrder.table) {
        self.table = this.$store.getters.getTableWithOrders(self.currentOrder.table);
      }
      if (self.currentOrder.table) {
        // if any previous orders completed in table service mode are returned in quick service mode
        if (this.terminalMode === 'QUICK_SERVICE') { 
          this.setEmptyCurrentOrder(true)
        }
        if (self.table.tableOrders && self.table.tableOrders.length === 0) {
          this.sendOrderToChannel({
            id: 0,
            localID: 0,
            subtotal: 0,
            items: [],
            table: null,
            tax_total: 0,
            discount_total: 0,
            status: 1,
            open_date: null,
            number: "0",
            discount_orders: 0,
            payments: [],
            receipt_kitchen_printed: false,
            description: "",
            dining_option_type: null,
            grand_total:0,
            surcharge_value:0
          })
          router.push({ name: "Tables" }, () => {});
        } else {
          router.push({ name: "TableOrders", params: { id: self.table.id } }, () => {
          });
        }
      } else if (self.isKioskMode) {
        router.replace({
          name: "KioskHome"
        }, () => {
        });
      } else {
        router.replace({
          name: "Order",
          params: {
            tableID: null,
            id: "new"
          }
        }, () => {
        });
        this.setEmptyCurrentOrder(true)
      }
    },
    emptyOrder () {
      this.currentOrder = {
        id: 0,
        localID: 0,
        subtotal: 0,
        items: [],
        table: null,
        tax_total: 0,
        discount_total: 0,
        status: 1,
        open_date: null,
        number: "0",
        discount_orders: 0,
        payments: [],
        receipt_kitchen_printed: false,
        description: "",
        dining_option_type: null,
        grand_total: 0,
        // order_gift_card_payment:[],
        isActive:false //isActive variable to determine if current order is open the pos window
      };
      // resetting refund id
      this.setRefundOrderId(null)

    },
    cancelOrder: async function () {
      console.log("cancelOrder");
      const self = this;
      this.cancelOrderModalPromise().then(async function (confirmed) {
        console.log("cancelOrder then confirmed", confirmed);
        if (confirmed) {
            if (self.currentOrder.receipt_kitchen_printed)
              // raising cancel ticket for kitchen printable items
              await self.printKitchenCheck("order cancelled")
          if (!self.employee && !self.isKioskMode) return false;
          if (self.employee && self.employee.role && self.employee.role === EMPLOYEE_ROLE.Cashier && !self.isVerified) {
            self.lockedAction = "remove-order";
            return self.$bvModal.show("enter-pin-modal");
          }
          self.cancelOrderProcessing();
        }
      }).catch(err => {
        console.log("catch cancelOrder", err);
      });
    },
    cancelOrderModalPromise () {
      console.log("cancelOrderModalPromise");
      const h = this.$createElement;
      const messageVNode = h("div", { class: ["modal-complete"] }, [
        h("div", {
          domProps: {
            innerHTML: "<svg class=\"icon icon-icon-popup-cansel\">" +
              "<use xlink:href=\"#icon-icon-popup-cansel\"></use>" +
              "</svg>" +
              "<h2>Cancel this order?</h2>" +
              "<span class=\"popup-meta\">" +
              "Are you sure you want to cancel this order?" +
              "</span>"
          }
        })
      ]);
      return this.$bvModal.msgBoxConfirm([messageVNode], {
        title: [],
        centered: true,
        modalClass: "success-popup",
        hideHeader: true,
        footerClass: "d-flex justify-content-center align-items-center",
        cancelVariant: "outline-primary",
        okVariant: "primary",
        okTitle: "YES",
        cancelTitle: "NO",
        noFade: true
      });
    },
    assignCustomerToOrder (customer) {
      /**
       * this function adds the selected customer to a order
       */
      const currentOrder = this.currentOrder;
      const customerObject = customer
      delete customerObject.default_address
      currentOrder.customer = customerObject
      // calculates for customer specific prices 
      currentOrder.items.forEach(item => this.checkSpecialPriceForItem(item, customer));
      this.calculateAllItemTaxes();
      // this.sync();
    },
    removeCustomerFromOrder () {
      /**
       * this function removes a customer from the order 
       */
      const currentOrder = this.currentOrder;
      currentOrder.customer = null;
      // reverts back any customer specific special price applied previously
      currentOrder.items.forEach(item => {
        item.price = item.default_price;
        item.price_base = item.default_price;
      });
      this.currentOrder = JSON.parse(JSON.stringify(currentOrder));
      //calculating item taxes and syncing the order to the backend
      this.calculateAllItemTaxes();
      this.sync();
    },
    checkSpecialPriceForItem (item, customer) {
      /**
       * this function is responsible for the updation of the items price in-accordance to the customer selected 
       */
      if (!item.special_prices_items || !item.special_prices_items.length) return;
      const specialPrice = item.special_prices_items.find(priceItem => priceItem.group === customer.group);
      if (!specialPrice) return item.price = item.default_price;
      item.price = specialPrice.price;
      item.price_base = specialPrice.price;
    },
    changeQuantity: function (itemId, name=null) {
      /** This function is used to show change quantity modal */
      if(!name){
        const index = this.currentOrder.items.findIndex(currentItem => currentItem.id === itemId);
        this.$store.commit('qtyModal/setIndex',  index)
        this.$store.commit('qtyModal/setInitial',  this.currentOrder.items[index].quantity + "")
        this.$bvModal.show("modal-quantity");
      }
    },
    saveNewQuantity: async function (objForSaving) {
      const {index, newQtyStr} = objForSaving;
      if (!this.isKioskMode && !this.employee) return false;
      if (newQtyStr === "") return false;
      if (newQtyStr === ".") return this.quantity.val = "";
      if (
        (newQtyStr === "0"
          || (newQtyStr !== this.currentOrder.items[index].quantity.toString()
            && this.currentOrder.items[index].sent_to_kitchen))
        && this.employee.role
        && this.employee.role === EMPLOYEE_ROLE.Cashier
        && !this.isVerified
      ) {
        this.lockedAction = "item-qty";
        return this.$bvModal.show("enter-pin-modal");
      }
      if (newQtyStr.includes("-")) {
        if(!this.refundOrderId)
        this.setRefundOrderId(this.currentOrder.local_id)
       
      }
      else { 
         this.setRefundOrderId(null)
      }
      let quantity = newQtyStr;
      const splittedQuantity = quantity.split(".");
      if (splittedQuantity[1] && splittedQuantity[1].length > 3) {
        quantity = parseFloat(quantity.toFixed(3));
      } else {
        quantity = parseFloat(quantity);
      }
      // add void items in case of reducing qty
      if (quantity < this.currentOrder.items[index].quantity && this.currentOrder.items[index].quantity - quantity > 0) {
        // clone
        const newItem = JSON.parse(JSON.stringify(this.currentOrder.items[index]));
        newItem.quantity = this.currentOrder.items[index].quantity - quantity;
        newItem.subtotal = newItem.quantity * newItem.price;
        newItem.grand_total = parseFloat((newItem.subtotal + ((await getModSum(newItem)) * newItem.quantity)).toFixed(2));
        newItem.void_status = 1;
        newItem.id = this.getItemIterator();
        newItem.local_id = newItem.id;
        this.currentOrder.items.push(newItem);
      } 
      this.currentOrder.items[index].quantity = quantity;
      this.currentOrder.items[index].subtotal = getItemSubtotal(this.currentOrder.items[index]);
      this.currentOrder.items[index].tax_items = this.calculateItemTaxItems(this.currentOrder.items[index]);
      this.currentOrder.items[index].tax = calculateItemTaxTotal(this.currentOrder.items[index]);
      
      this.$bvModal.hide("modal-quantity");
      this.countSubtotal();
      if (this.currentOrder.discount_orders_raw_value)
        await this.countSubtotal(this.currentOrder.discount_orders_raw_value, this.currentOrder.discount_orders_raw_type);

      if (this.currentOrder.receipt_kitchen_printed && this.currentOrder.items[index]["sent_to_kitchen"]) {
        this.markUpdated(index, "qty changed");
      }
      if (this.isKioskMode) {
        this.amountTenderedString = this.currentOrderGrandTotalSum * 100 + "";
      }
      await this.sync();
    },
    getItemIterator: function () {
      let i = Date.now();
      let iter, itemFound;
      do {
        iter = this.currentOrder.id + "_" + this.currentOrder.items.length + "_" + i;
        itemFound = this.currentOrder.items.find((item) => item.id === iter);
        i++;
      } while (itemFound);
      return iter;
    },

    calulateItemPropotion(orderSubtotal,itemSubtotal){
      if(orderSubtotal)
        return (itemSubtotal / orderSubtotal)
      else
        return itemSubtotal
    },

    calculatePerItemDiscount(itemContribution,discountApplied){
      if(discountApplied)
        return itemContribution * discountApplied 
      else
        return 0
    },

    calculatePerItemSubtotal(itemSubtotal,itemDiscount) {
      if(itemSubtotal)
        return this.roundCalculation(itemSubtotal -itemDiscount)
      else
        parseFloat((getOrderSubTotal(this.currentOrder)).toFixed(2));
      },

    calculatePerItemTax(itemSubtotal,taxRate) {
      return this.roundCalculation((itemSubtotal * taxRate))
  },

  roundToTwoDecimalPlaces(num) {
    return Math.round(num * 100) / 100;
  },

    roundOffDiscounts(item){
    
    /*
      if a whole order discount is applied:
      then if the quantity is negative i.e. the order is refunded  return the total of discount mulitplying with a -1 else return the total discount
      else if return discount into quantity
    */

    return handlingRoundOffCalculations((wholeDiscountArray)=>{
      return wholeDiscountArray.length > 0 ? Math.abs((parseFloat(toFixed((wholeDiscountArray.reduce((total,discount)=>total+discount)+(item.discount * item.quantity)),2))) ) : parseFloat((item.discount * item.quantity).toFixed(2))
    },item)

    // return wholeDiscountArray.length > 0 ? Math.abs((parseFloat(toFixed((wholeDiscountArray.reduce((total,discount)=>total+discount)+(item.discount * item.quantity)),2))) ) : parseFloat((item.discount * item.quantity).toFixed(2))
    },
    surchargeCharged(total, surcharge){
      this.grandTotal = parseFloat(total)
      this.surcharge = parseFloat(surcharge)
      return parseFloat((this.grandTotal * (this.surcharge/100)))
    },
    async checkInternetConnectivity() {
    // URL of a small, lightweight resource that is highly likely to be accessible
    const url = 'https://www.google.com/favicon.ico';

    return fetch(url, { method: 'HEAD', mode: 'no-cors' })
      .then(() => {
        // If the fetch is successful, we have internet connectivity
        return true;
      })
      .catch(() => {
        // If the fetch fails, we don't have internet connectivity
        return false;
      });
    },
    closeGiftCardNotAvailableModal(){
      this.$bvModal.hide('gift-card-not-available')
    }
  },
  async created() { 
    //fetching cash_discount db_setting from local storage
    const terminal = JSON.parse(localStorage.getItem('terminal'))
    const cashDiscountSetting = terminal.db_settings.filter(setting => setting.name === 'cash_discount')
    if (cashDiscountSetting.length)
      this.allowCashDiscount = cashDiscountSetting[0]
  }
};

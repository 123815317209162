// Components
import TableOrders from '@/views/TableOrders';

// Layouts
import PosLayout from '../layouts/PosLayout';

// Views
// const AppPin = () => import('@/views/AppPin');
const Synchronize = () => import('../views/Synchronize');
import Shift from '../views/Shift';
import Tables from '../views/Tables';
import Order from '../views/Order';
import OrderCheckout from '../views/OrderCheckout';
import OrdersList from '../views/OrdersList';
const Modals = () => import('../views/Modals');
import Auth from '../views/Auth';
import Tools from '../views/Tools';
import Reset from '../views/Reset';
import KioskHome from '../views/KioskHome';
import KioskOrderType from '../views/KioskOrderType';
import NewOrderRedirect from '../views/NewOrderRedirect';
import OnlineOrders from "../views/OnlineOrders";
import AppPin from "../views/AppPin";
import Settings from "../views/Settings"
const AuthQuick = () => import('../views/AuthQuick');

const routes = [
  {
    path: '/',
    name: 'PosLayout',
    title: 'PosLayout',
    component: PosLayout,
    redirect: {name: 'Tables'}
  },
  {
    path: '/synchronize',
    name: 'Synchronize',
    title: 'Synchronize',
    component: Synchronize
  },
  {
    path: '/reset',
    name: 'Reset',
    title: 'Reset',
    component: Reset
  },
  {
    path: '/shift',
    name: 'Shift',
    title: 'Shift',
    component: Shift
  },
  {
    path: '/tables/moveorder/:moveorder/from/:tableID',
    name: 'TablesMoveOrder',
    title: 'TablesMoveOrder',
    component: Tables,
    props: (route) => ({moving: route.query.moving})
    // props: { default: true, sidebar: false }
  },
  {
    path: '/tables',
    name: 'Tables',
    title: 'Tables',
    component: Tables,
    props: {sync: false}
  },
  {
    path: '/tables/:id',
    component: TableOrders,
    title: 'TableOrders',
    name: 'TableOrders'
  },
  {
    path: '/new-redirect',
    name: 'NewOrderRedirect',
    title: 'NewOrderRedirect',
    component: NewOrderRedirect
  },
  {
    path: '/orders/:id',
    name: 'Order',
    title: 'Order',
    component: Order
  },
  {
    path: '/orders/:id/checkout',
    name: 'OrderCheckout',
    title: 'OrderCheckout',
    component: OrderCheckout
  },
  {
    path: '/orders',
    name: 'OrdersList',
    title: 'OrdersList',
    component: OrdersList
  },
  {
    path: '/online-orders',
    name: 'OnlineOrder',
    title: 'OnlineOrder',
    component: OnlineOrders
  },
  {
    path: '/modals',
    name: 'Modals',
    title: 'Modals',
    component: Modals
  },
  {
    path: '/pin',
    name: 'Pin',
    title: 'Pin',
    component: AppPin
  },
  {
    path: '/kiosk',
    name: 'KioskHome',
    title: 'KioskHome',
    component: KioskHome
  },
  {
    path: '/kiosk/order-type',
    name: 'KioskOrderType',
    title: 'KioskOrderType',
    component: KioskOrderType
  },
  {
    path: '/auth',
    name: 'Auth',
    title: 'Auth',
    component: Auth
  },
  {
    path: '/quick-auth',
    name: 'AuthQuick',
    title: 'AuthQuick',
    component: AuthQuick
  },
  {
    path: '/tools',
    name: 'Tools',
    title: 'Tools',
    component: Tools
  },
  {
    path: '/settings',
    name: 'Settings',
    title: 'Settings',
    component: Settings,
    
  },
  {
    path: '*',
    redirect: {name: '/'}
  }
];

export default routes;

<template>
  <div>
    <b-modal
      id="more-options-modal"
      no-close-on-backdrop
      hide-footer
      size="md"
      body-class="modal-height"
      no-fade
    >
      <template
        slot="modal-header"
        slot-scope="{ close }"
      >
        <b-button
          variant="outline-light"
          @click="close()"
        >
          {{ $t('more-options-modal.cancel') }}
        </b-button>
        <h5>
          {{ $t('more-options-modal.more-options') }}
        </h5>
      </template>
      <div class="options">
        <div
          v-if="terminalMode !== 'QUICK_SERVICE'"
          class="option"
          @click="moveOrder"
        >
          <svg class="icon icon-icon-move">
            <use xlink:href="#icon-icon-move" />
          </svg>
          <span>
            {{ $t('more-options-modal.move').toUpperCase() }}
          </span>
        </div>
        <div
          v-if="terminalMode !== 'QUICK_SERVICE' && currentOrder.table"
          class="option"
          @click="splitOrder"
        >
          <svg class="icon icon-icon-split">
            <use xlink:href="#icon-icon-split" />
          </svg>
          <span>
            {{ $t('more-options-modal.split').toUpperCase() }}
          </span>
        </div>
        <div
          class="option"
          @click="showHoldOrderModal"
          v-if="currentOrder.status!==ORDER_STATUS.HOLD"
        >
          <svg class="icon icon-icon-hold">
            <use xlink:href="#icon-icon-hold" />
          </svg>
          <span>
            {{ $t('more-options-modal.hold').toUpperCase() }}
          </span>
        </div>
        <div
          class="option"
          @click="showOrderDiscountModal"
        >
          <svg class="icon icon-icon-discount">
            <use xlink:href="#icon-icon-discount" />
          </svg>
          <span>
            {{ $t('more-options-modal.discount').toUpperCase() }}
          </span>
        </div>
        <div
          class="option"
          @click="showGiftModal()"
        >
          <svg class="icon icon-icon-gift">
            <use xlink:href="#icon-icon-gift" />
          </svg>
          <span>
            GIFT CARD
          </span>
        </div>
      </div>
    </b-modal>
    <HoldOrder @hold-order="holdOrder" />
    <OrderDiscountModal
      :discount="orders_discount"
      :popup-price="popupPrice"
      :popup-price-sidebar="popupPriceSidebar"
      :body-bg-variant="bodyBgVariant"
      :all-discounts="allDiscounts"
      :currency="currency"
      :current-order="currentOrder"
      :current-employee="currentEmployee"
      @save-discount-to-order="discountOrder"
    />
    <GiftModal />
    <GiftVoucherUnAvailable />
  </div>
</template>

<script>
  import HoldOrder from "./HoldOrder";
  import OrderDiscountModal from "../../OrderDiscountModal";
  import GiftModal from "./GiftCardModal.vue"
  import GiftVoucherUnAvailable from "../../GiftVoucherUnAvailable"
  import { ORDER_STATUS } from "../../../helpers/order/status";
  import { mapGetters } from 'vuex'

  export default {
    name: "MoreOptionsModal",
    components: {
      HoldOrder,
      OrderDiscountModal,
      GiftModal,
      GiftVoucherUnAvailable
    },
    props: {
      currentOrder: {
        type: Object,
        required: true
      },
      terminalMode: {
        type: String,
        required: true
      },
      currency: {
        type: String,
        required: false,
        default: '$'
      },
      currentEmployee: {
        type: Object,
        required: true
      },
      allDiscounts: {
        type: Array,
        required: true
      }
    },
    data () {
      return {
        orders_discount: {
          val: 0,
          changed: false,
          type: 1
        },
        ORDER_STATUS: ORDER_STATUS,
        popupPrice: ['popup-price'],
        popupPriceSidebar: ['popup-price-sidebar'],
        bodyBgVariant: 'light',
        applicationIsOnline:true
      }
    },
    computed: {
      ...mapGetters({
        showGiftVoucherOption:'giftVoucher/showGiftVoucherOption'
      }),
      isSavingProcess() {
        return this.$store.getters.isSavingProcess;
      },
      // isOnline(){
      //   console.log(window.navigator.onLine)
      //   return window.navigator.onLine
      // }
    },
    methods: {
      moveOrder() {
        if (this.isSavingProcess) return;
        this.$router.push({
          name: 'TablesMoveOrder',
          params: { tableID: this.currentOrder.table, moveorder: this.currentOrder.id || this.currentOrder.local_id}
        });

      },
      splitOrder() {
        if (this.isSavingProcess) return;
        this.$router.push({ name: 'TableOrders', params: { id: this.currentOrder.table }})
      },
      showHoldOrderModal() {
        this.$bvModal.show('hold-order-modal')
      },
      holdOrder(payload) {
        this.$emit('hold-order', payload);
      },
      showOrderDiscountModal() {
        this.$bvModal.show('modal-order-discount');
      },
      discountOrder(payload) {
        const {value, discountType} = payload;
        this.$emit('save-discount-to-order', value, discountType);
        this.$bvModal.hide('more-options-modal');
      },
      async showGiftModal(){
        // console.log(this.checkInternetConnectivity())
        const hasConnectivity = await this.checkInternetConnectivity()
        if(hasConnectivity){
        this.$bvModal.hide('more-options-modal');
        this.$bvModal.show('gift-voucher-modal')
        }
        else{
          this.$bvModal.show('gift-card-not-available')
        }
      },
      async checkInternetConnectivity() {
        // URL of a small, lightweight resource that is highly likely to be accessible
        const url = 'https://www.google.com/favicon.ico';

        return fetch(url, { method: 'HEAD', mode: 'no-cors' })
          .then(() => {
            // If the fetch is successful, we have internet connectivity
            return true;
          })
          .catch(() => {
            // If the fetch fails, we don't have internet connectivity
            return false;
          });
      },
      closeGiftCardNotAvailableModal(){
        this.$bvModal.hide('gift-card-not-available')
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "@/assets/sass/_mixins.scss";
  .options {
    display: flex;
    flex-wrap: wrap;
    .option {
      display: flex;
      justify-content: center;
      width: 135px;
      margin: 0 0 1rem 1rem;
      padding: 20px 0;
      background-color: #ffffff;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      color: #2196F3;
      cursor: pointer;
      .icon {
        height: 1.5rem;
        width: 2rem;
      }
      @include breakpoint(sm) {
        width: 100%;
        margin: 10px 0;
      }
    }
  }

  .modal-header {
    background: #FAFAFA;
    align-items: center;
    position: relative;
    justify-content: center;
    height: 72px;

    .btn {
      min-width: 84px;
      position: absolute;
      left: 16px;
      top: 50%;
      margin-top: -20px;

      @media (max-width: 767.98px) {
        left: .25rem;
      }
    }

    h5 {
      margin: auto;
      text-align: center;
    }
  }
</style>

export const apiCall = async (url, method = "get", token = null, body = {}) => {

  const headers = {
    "Content-Type": "application/json",
    "Accept": "application/json"
  };
  if (token) headers.authorization = token;

  const fetchConfig = {
    method: method.toUpperCase(),
    headers: new Headers(headers),
    withCredentials: true
  };

  if (method.toLowerCase() !== "get" && Object.keys(body).length !== 0)
    fetchConfig.body = JSON.stringify(body);

  return await fetch(url, fetchConfig).then(async (response) => {
    switch (response.status) {
      case 200:
        return response.json();
      case 404:
        return false;
      case 400:
        throw Error(`Already had first sync`);
      default:
        throw Error(`Got ${response.status} at ${url}`);
    }
  });
};

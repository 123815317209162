export const PAYMENT_BEHAVIOR = {
  ALLOW_CHANGE: 1,
  DIRECT_CHARGE: 2,
  NABANCARD_INTEGRATION: 3,
  GIFT_CARD:4
};

export const PAYMENT_REVERSAL_TRANSACTION_TYPE = {
  'CCR1':'CCR9',
  'DB00' : 'DB01'
}


<template>
  <pos-layout>
    <template #navbar>
      <SearchForm />
    </template>

    <div class="h-100 orders">
      <!-- Print panel For mobile -->
      <div class="print d-md-none">
        <b-row class="d-flex h-100 no-gutters">
          <b-col class="h-100 w-100 d-md-none">
            <b-button
              :to="{ name: 'Tables', params: { id: currentOrder.table }}"
              variant="link"
              class="w-100 bg-pink"
            >
              <svg class="icon icon-icon-table">
                <use xlink:href="#icon-icon-table" />
              </svg>

              <span>
                {{ $t("amountandpaymentmethod.print-to-tables-mob") }}
              </span>
            </b-button>
          </b-col>

          <b-col class="h-100 w-100">
            <b-button
              variant="link"
              class="w-100"
            >
              <svg class="icon icon-icon-print-1">
                <use xlink:href="#icon-icon-print-1" />
              </svg>

              <span>
                {{ $t("amountandpaymentmethod.print-check") }}
              </span>
            </b-button>
          </b-col>
          <b-col
            v-if="currentOrder.status === ORDER_STATUS.REFUNDED"
            class="h-100 w-100"
          >
            <b-button
              variant="link"
              class="w-100"
            >
              <svg class="icon icon-icon-print-2">
                <use xlink:href="#icon-icon-print-2" />
              </svg>

              <span>
                {{ $t("amountandpaymentmethod.kitchen-print") }}
              </span>
            </b-button>
          </b-col>
          <b-col
            v-if="currentOrder.status === ORDER_STATUS.REFUNDED"
            class="h-100 w-100 d-none d-md-block"
          >
            <b-button
              variant="link"
              class="w-100"
            >
              <svg class="icon icon-icon-print-3">
                <use xlink:href="#icon-icon-print-3" />
              </svg>

              <span>
                {{ $t("amountandpaymentmethod.open-drawer") }}
              </span>
            </b-button>
          </b-col>
          <b-col
            v-if="currentOrder.status !== ORDER_STATUS.CANCELLED"
            class="h-100 w-100 d-none d-md-block"
          >
            <b-button
              variant="link"
              class="w-100"
            >
              <svg class="icon icon-icon-print-4">
                <use xlink:href="#icon-icon-print-4" />
              </svg>

              <span>
                {{
                  currentOrder.status === ORDER_STATUS.REFUNDED ? $t("amountandpaymentmethod.credit-card") : $t("amountandpaymentmethod.show-payments")
                }}
              </span>
            </b-button>
          </b-col>

          <b-col
            v-if="!sidebarMob"
            class="h-100 w-100 d-md-none"
          >
            <b-button
              variant="link"
              class="w-100 bg-primary"
              @click="sidebarMob = true"
            >
              <svg class="icon icon-icon-mob-view">
                <use xlink:href="#icon-icon-mob-view" />
              </svg>
              <span>
                {{ $t("amountandpaymentmethod.print-view-orders-mob") }}
              </span>
            </b-button>
          </b-col>

          <b-col
            v-if="sidebarMob"
            class="h-100 w-100 d-md-none"
          />
        </b-row>
      </div>

      <b-row class="h-100 no-gutters">
        <b-col
          :class="{hide: sidebarMob}"
          col
          cols="12"
          md="5"
          lg="4"
          class="h-100 orders-sidebar-mob"
        >
          <div class="orders-sidebar">
            <div class="orders-head">
              <div class="orders-top">
                <div class="d-flex align-items-center">
                  <div class="orders-top-num">
                    <span>
                      {{ $t("amountandpaymentmethod.order-no") }}
                    </span>
                    <strong>
                      # {{ (currentOrder.id || currentOrder.local_id) }} - {{ orderStatusTextView }}
                    </strong>
                  </div>
                  <div class="orders-top-btn ml-auto d-flex align-items-center">
                    <b-button
                      v-if="currentOrder.status === ORDER_STATUS.COMPLETED && currentOrder.grand_total !== 0.00"
                      variant="secondary"
                      :disabled="currentOrder.isFullyRefund || !currentOrder.id || orderHasOnlyGiftCardProduct"
                      @click="showRefundModal"
                    >
                      {{ currentOrder.isFullyRefund ? "Fully Refunded":$t("orderscreenv1.refund") }}
                    </b-button>
                    <b-button
                      v-if="currentOrder.status === ORDER_STATUS.OPEN || currentOrder.status === ORDER_STATUS.HOLD"
                      :to="{ name: 'Order', params: { id: currentOrder.id || currentOrder.local_id }}"
                      variant="secondary"
                    >
                      {{ $t("orderscreen.edit") }}
                    </b-button>
                  </div>
                </div>
              </div>
              <div class="orders-add">
                <span v-if="!currentOrder.customer">{{ $t("orderscreen.no-customer-selected") }}</span>
                <span v-else>
                  {{ currentOrder.customer.first_name }} {{ currentOrder.customer.last_name }}
                </span>
              </div>
            </div>

            <div class="orders-sidebar-body">
              <div
                class="orders-list"
                role="tablist"
              >
                <!-- <transition-group name="list-complete"> -->
                <b-card
                  v-for="(item, index) in itemsAtCurrentOrder"
                  :key="item.id"
                  no-body
                  class="list-complete-item"
                >
                  <header
                    class="card-header orders-item-head p-0"
                    role="tab"
                  >
                    <b-button
                      v-b-toggle="'accordion-'+index"
                      block
                      href="#"
                      class="btn-secondary btn-block"
                    >
                      <div class="d-flex">
                        <div class="btn-num">
                          {{ item.quantity }}
                        </div>

                        <div class="btn-body">
                          <div class="d-flex align-items-center justify-content-between top text-nowrap">
                            <div class="text-left d-flex">
                              {{ item.name }}
                              <svg
                                v-if="item.notes&&item.notes.length"
                                class="icon icon-icon-note"
                              >
                                <use xlink:href="#icon-icon-note" />
                              </svg>
                              <div
                                v-if="item.isPartialRefund"
                                class="icon-dot ml-2 my-auto"
                              />
                            </div>
                            <div class="text-right">
                              <span
                                v-if="item.hasOwnProperty('tax_rates') && item.tax_rates.length > 0"
                                class="short-desc"
                              >{{ $t("listvieworderdelegate.taxed") }}</span>
                              {{ currency }} {{ getItemGrandTotal(item).toFixed(2) }}
                            </div>
                          </div>
                          <div class="d-flex align-items-center justify-content-between sub text-nowrap">
                            <div class="text-left">
                              {{ calculateDiscount(item.cost, item.discount) }} %
                            </div>
                            <div class="text-right">
                              {{ currency }} {{ item.discount ? (item.discount * item.quantity).toFixed(2) : "0.00" }}
                            </div>
                          </div>
                          <!-- <div
                              v-if="currentOrder.isPartialRefund"
                              class="icon-dot ml-2"
                            /> -->
                        </div>
                      </div>
                    </b-button>
                  </header>

                  <b-collapse
                    :id="'accordion-'+index"
                    :visible="showCollapse(index)"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <table class="table">
                        <tr>
                          <td>
                            <span>
                              {{ $t("listvieworderdelegate.qty") }}
                            </span>
                            <strong class="text-primary">
                              {{ item.quantity }}
                            </strong>
                          </td>
                          <td>
                            <span>
                              {{ $t("listvieworderdelegate.price") }}
                            </span>
                            <strong class="text-primary">
                              {{ currency }}{{ item.price ? item.price.toFixed(2) : "0.00" }}
                            </strong>
                          </td>
                          <td>
                            <span>
                              {{ $t("listvieworderdelegate.discount") }}
                            </span>
                            <strong>
                              {{ currency }} {{ item.discount.toFixed(2) }}
                            </strong>
                          </td>
                        </tr>
                      </table>
                      <div
                        v-if="item.notes&&item.notes.length"
                        class="card-notes"
                      >
                        {{ item.notes }}
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <!-- </transition-group> -->
              </div>
            </div>

            <div class="orders-bottom">
              <div v-if="!displayPaymentDetailsList">
                <div class="orders-bottom-item">
                  <span class="text-left">
                    {{ $t("ordertotal.total") }}
                  </span>
                  <span
                    v-if="currentOrder.surcharge_value <=0 "
                    class="text-right"
                  >
                    {{ currency }} {{ currentOrder.grand_total ? currentOrder.grand_total.toFixed(2) : "0.00" }}
                    <b-button
                      variant="link"
                      class="w-100"
                      @click="isDisplayPaymentDetailsList"
                    >
                      {{ $t("ordertotal.view-details") }}
                    </b-button>
                  </span>
                  <span
                    v-else
                    class="text-right"
                  >
                    {{ currency }} {{ currentOrder.grand_total ? (currentOrder.grand_total+currentOrder.surcharge_value).toFixed(2) : "0.00" }}
                    <b-button
                      variant="link"
                      class="w-100"
                      @click="isDisplayPaymentDetailsList"
                    >
                      {{ $t("ordertotal.view-details") }}
                    </b-button>
                  </span>
                </div>
              </div>

              <div v-else>
                <div class="orders-bottom-item">
                  <b-button
                    variant="link"
                    class="w-100 text-center"
                    @click="isDisplayPaymentDetailsList"
                  >
                    {{ $t("ordertotal.close-details") }}
                  </b-button>
                </div>
                <div class="orders-bottom-item">
                  <span class="text-left">
                    {{ $t("ordertotal.discount") }}
                  </span>
                  <span class="text-right">
                    {{ currency }} {{ currentOrder.discount_total ? currentOrder.discount_total.toFixed(2) : "0" }}
                  </span>
                </div>
                <div class="orders-bottom-item">
                  <span class="text-left">
                    {{ $t("ordertotal.sub-total") }}
                  </span>
                  <span class="text-right">
                    {{ currency }} {{ currentOrder.subtotal.toFixed(2) }}
                  </span>
                </div>

                <div class="orders-bottom-item">
                  <span class="text-left">
                    {{ $t("ordertotal.tax") }}
                  </span>
                  <span class="text-right">
                    {{ currency }} {{ toFixedNoRounding(currentOrder.tax_total, 2) }}
                  </span>
                </div>

                <div
                  class="orders-bottom-item"
                  v-if="currentOrder.tips_total"
                >
                  <span class="text-left">
                    {{ $t("ordertotal.tip") }}
                  </span>
                  <span class="text-right">
                    {{ currency }} {{ currentOrder.tips_total.toFixed(2) }}
                  </span>
                </div>

                <div
                  v-if="currentOrder.surcharge_value >0 "
                  class="orders-bottom-item"
                >
                  <span class="text-left">
                    Surcharge
                  </span>
                  <span class="text-right">
                    {{ currency }} {{ currentOrder.surcharge_value.toFixed(2) }}
                  </span>
                </div>

                <div class="orders-bottom-item">
                  <span class="text-left">
                    {{ $t("ordertotal.total") }}
                  </span>
                  <span
                    v-if="currentOrder.surcharge_value <= 0"
                    class="text-right"
                  >
                    {{ currency }} {{ currentOrder.grand_total ? currentOrder.grand_total.toFixed(2) : "0.00" }}
                  </span>
                  <span
                    v-else
                    class="text-right"
                  >
                    {{ currency }} {{ currentOrder.grand_total ? (currentOrder.grand_total+currentOrder.surcharge_value).toFixed(2) : "0.00" }}
                  </span>
                </div>
              </div>

              <div
                v-if="!(currentOrder.status === ORDER_STATUS.HOLD || currentOrder.status === ORDER_STATUS.CANCELLED)"
              >
                <div
                  v-for="(item, index) in currentOrder.payments"
                  :key="index"
                  class="orders-bottom-item"
                >
                  <span class="text-left">
                    {{ item.amount > 0 ? $t("ordertotal.paid") : "Refunded" }} - {{ item.payment_type }}
                  </span>
                  <span class="text-right">
                    {{ currency }} {{ item.amount.toFixed(2) }}
                    <span :style="{color: 'red'}">
                      {{ `(${item.change_amount.toFixed(2)})` }}
                    </span>
                  </span>
                </div>
              </div>
              <div class="orders-bottom-options">
                <div class="order-sum">
                  <div class="d-flex align-items-center justify-content-between">
                    <span class="text-left">
                      {{ $t("ordertotal.balance") }}
                    </span>
                    <span class="text-right">
                      {{
                        currency
                      }} {{ currentOrder.balance_remaining ? currentOrder.balance_remaining.toFixed(2) : "0.00" }}
                    </span>
                  </div>
                </div>
              </div>

              <!-- Print panel For desktop -->
              <div class="print d-none d-md-block">
                <div class="d-flex h-100 no-gutters">
                  <div class="h-100 w-100">
                    <b-button
                      :disabled="!currentOrder.status"
                      variant="link"
                      class="w-100"
                      @click="printCheck()"
                    >
                      <svg class="icon icon-icon-print-1">
                        <use xlink:href="#icon-icon-print-1" />
                      </svg>

                      <span>
                        {{ $t("amountandpaymentmethod.print-check") }}
                      </span>
                    </b-button>
                  </div>
                  <!--                  todo: Kitchen print check< if we don't need that functionality in view order, remove that-->
                  <!--                  <div class="h-100 w-100" v-if="currentOrder.status === ORDER_STATUS.REFUNDED">-->
                  <!--                    <b-button-->
                  <!--                      variant="link"-->
                  <!--                      class="w-100"-->
                  <!--                      @click="printKitchenCheck()"-->
                  <!--                      :disabled="!currentOrder.status"-->
                  <!--                    >-->
                  <!--                      <svg class="icon icon-icon-print-2">-->
                  <!--                        <use xlink:href="#icon-icon-print-2"></use>-->
                  <!--                      </svg>-->

                  <!--                      <span>-->
                  <!--                        {{ $t('amountandpaymentmethod.kitchen-print') }}-->
                  <!--                      </span>-->
                  <!--                    </b-button>-->
                  <!--                  </div>-->
                  <!--todo: Open drawer check if we don't need that functionality in view order, remove that-->
                  <!--<div class="h-100 w-100" v-if="currentOrder.status === ORDER_STATUS.REFUNDED">-->
                  <!--                    <b-button-->
                  <!--                      variant="link"-->
                  <!--                      class="w-100"-->
                  <!--                      :disabled="!currentOrder.status"-->
                  <!--                    >-->
                  <!--                      <svg class="icon icon-icon-print-3">-->
                  <!--                        <use xlink:href="#icon-icon-print-3"></use>-->
                  <!--                      </svg>-->

                  <!--                      <span>-->
                  <!--                        {{ $t('amountandpaymentmethod.open-drawer') }}-->
                  <!--                      </span>-->
                  <!--                    </b-button>-->
                  <!--                  </div>-->
                  <!-- <div v-if="currentOrder.status !== ORDER_STATUS.CANCELLED" class="h-100 w-100">
                    <b-button
                      :disabled="!currentOrder.status"
                      variant="link"
                      class="w-100"
                    >
                      <svg class="icon icon-icon-print-4">
                        <use xlink:href="#icon-icon-print-4"/>
                      </svg>

                      <span>
                        {{ $t('amountandpaymentmethod.show-payments') }}
                      </span>
                    </b-button>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </b-col>

        <b-col
          col
          cols="12"
          md="7"
          lg="8"
          class="h-100 orders-col order-list-container"
        >
          <div
            :id="!isMobile && 'infinite-list'"
            class="products-wrap h-100 pt-0"
          >
            <div class="section h-100">
              <div class="section-top bg-light">
                <h2>
                  {{ $t("vieworderspopup.orders") }}
                </h2>

                <b-button
                  :to="{ name: 'Tables'}"
                  variant="outline-light"
                >
                  {{ $t("vieworderspopup.close") }}
                </b-button>
              </div>

              <div
                :id="isMobile && 'infinite-list'"
                class="section-body"
              >
                <div class="filter">
                  <div class="filter-panel flex-wrap flex-xl-nowrap justify-content-between">
                    <div
                      class="btn-group w-100"
                      role="group"
                    >
                      <b-button
                        :class="{'active': statusFilter==3}"
                        @click="statusFilter=3"
                      >
                        <span>{{ $t("vieworderspopup.completed") }}</span>
                      </b-button>
                      <b-button
                        :class="{'active': statusFilter==1}"
                        @click="statusFilter=1"
                      >
                        <span>{{ $t("vieworderspopup.open") }}</span>
                      </b-button>
                      <b-button
                        :class="{'active': statusFilter==2}"
                        @click="statusFilter=2"
                      >
                        <span>{{ $t("vieworderspopup.hold") }}</span>
                      </b-button>
                      <b-button
                        :class="{'active': statusFilter==4}"
                        @click="statusFilter=4"
                      >
                        <span>{{ $t("vieworderspopup.cancelled") }}</span>
                      </b-button>
                      <b-button
                        :class="{'active': statusFilter==5}"
                        @click="statusFilter=5"
                      >
                        <span>{{ $t("vieworderspopup.refunded") }}</span>
                      </b-button>
                      <b-button>
                        <span>{{ $t("vieworderspopup.account") }}</span>
                      </b-button>
                    </div>

                    <div class="d-flex ml-xl-auto align-items-center justify-content-between pt-2 pt-xl-0 w-100">
                      <div class="ml-auto position-relative filter-search d-flex">
                        <input
                          v-model="searchValue"
                          type="text"
                          class="form-control"
                          @keyup.enter="searchByNumber"
                          placeholder="Order #"
                        >
                        <b-button
                          class="active flex-grow-0 flex-shrink-0"
                          @click="searchByNumber"
                        >
                          <span>Search</span>
                        </b-button>
                      </div>
                    </div>
                  </div>

                  <div class="filter-body">
                    <table class="table orders-table">
                      <tr
                        v-for="(order, index) in filteredOrders"
                        :key="index"
                      >
                        <td class="text-left">
                          #{{ order.id }}
                        </td>
                        <td class="orders-table-info">
                          <h6 v-if="order.customer">
                            {{ order.customer.first_name }} {{ order.customer.last_name }}
                            <b-button
                              v-if="order.customer"
                              variant="link"
                              @click="showViewCustomerModal(order.customer)"
                            >
                              View
                            </b-button>
                          </h6>
                          <span
                            v-if="order.status === ORDER_STATUS.COMPLETED || order.status === ORDER_STATUS.CANCELLED"
                          >
                            {{ convertDate(order.close_date) }}
                          </span>
                          <span v-if="order.status === ORDER_STATUS.OPEN">
                            {{ convertDate(order.open_date) }}
                          </span>
                          <span v-if="order.status === ORDER_STATUS.HOLD">
                            <p>{{ convertDate(order.hold_date ? order.hold_date : order.open_date) }}</p>
                            <p class="hold-note">Hold Note: {{ order.description }}</p>
                          </span>
                        </td>
                        <td v-if="order.surcharge_value <=0 ">
                          {{ currency }}{{ order.grand_total ? order.grand_total.toFixed(2) : "0.00" }}
                        </td>
                        <td v-else>
                          {{ currency }}{{ order.grand_total ? (order.grand_total+order.surcharge_value).toFixed(2) : "0.00" }}
                        </td>
                        <td
                          v-if="true"
                          class="text-right"
                        >
                          <b-button
                            variant="light"
                            @click="viewOrder(order)"
                          >
                            {{ $t("delegatevieworders.view-order") }}
                          </b-button>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <scale-loader
            :loading="isLoad || isProcessing"
            :color="'#007bff'"
          />
        </b-col>
      </b-row>
    </div>
    <SimpleInfoModal :id="'info-modal'">
      <template v-slot:title>
        {{ $t("order.not-found") }}
      </template>
      <template v-slot:info>
        {{ $t("order.not-found") }}. {{ $t("modal-info.try-again") }}
      </template>
    </SimpleInfoModal>
    <ConfirmActionModal
      :modal="confirmActionModal"
      @confirm-action="confirmAction"
    />
    <SuccessActionModal :modal="successActionModal" />
    <CustomerModal
      :view-customer="viewCustomer"
      :is-selected="true"
      @close-modal="clearViewCustomer"
    />
    <ProcessingModal />
    <ModalError
      :id="'modal-error-checkout'"
      :error="error"
    />
  </pos-layout>
</template>

<script>
import { ORDER_STATUS } from "../helpers/order/status";

// include the default style
import "vuejs-dialog/dist/vuejs-dialog.min.css";
import orderMixin from "../mixins/order/orderMixin";
import shiftMixin from "../mixins/shiftMixin";
import viewCustomerModalMixin from "../mixins/modals/viewCustomerModalMixin";
import CustomerModal from "@/modals/CustomerModal";
import SimpleInfoModal from "../components/SimpleInfoModal";
import PosLayout from "../layouts/PosLayout";
import SearchForm from "../components/SearchForm";
import ConfirmActionModal from "../modals/ConfirmActionModal";
import SuccessActionModal from "../modals/SuccessActionModal";
import { TRANSACTION_TYPE } from "@/helpers/order/transaction/types";
import syncMixin from "../mixins/syncMixin";
import printMixin from "../mixins/printMixin";
import ProcessingModal from "../modals/ProcessingModal";
import ModalError from "../modals/ModalError";
import { PAYMENT_BEHAVIOR } from "@/helpers/order/payment/behavior";
import { makeNARefund, makeNAVoid } from "@/helpers/payments/NABancard";
import ScaleLoader from 'vue-spinner/src/ScaleLoader'
import { mapActions, mapGetters } from "vuex";
const sound = new Audio(require("../assets/sounds/error-sound.mp3"));
import * as Sentry from "@sentry/vue";
import { toFixedNoRounding } from "@/helpers/utils";

export default {
  name: "OrderList",
  components: {
    PosLayout,
    SearchForm,
    SimpleInfoModal,
    ScaleLoader,
    ConfirmActionModal,
    SuccessActionModal,
    CustomerModal,
    ProcessingModal,
    ModalError
  },
  mixins: [orderMixin, shiftMixin, viewCustomerModalMixin, syncMixin, printMixin],
  data () {
    return {
      sidebarMob: true,
      timer: 0,
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 0,
        freeMode: true,
        slideToClickedSlide: false,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        }
      },
      currentCategory: 2,
      storeInfo: null,
      itemIdCounter: 0,
      currentOrder: {
        id: 0,
        localID: 0,
        subtotal: 0,
        tax_total: 0,
        grand_total: 0,
        items: [],
        table: 0,
        status: null,
        // order_gift_card_payment:[]
      },
      modalShow: false,
      bodyBgVariant: "light",
      popupPrice: ["popup-price"],
      popupPriceSidebar: ["popup-price-sidebar"],
      statusFilter: 3,
      ORDER_STATUS: ORDER_STATUS,
      displayPaymentDetailsList: false,
      searchValue: "",
      perPage: 20,
      countItems: 20,
      infoModal: {
        text: "Not Found"
      },
      confirmActionModal: {
        title: "",
        text: "",
        confirmActionText: "refund"
      },
      actionToConfirm: "",
      successActionModal: {
        title: "Success",
        text: "Order has been refunded"
      },
      isProcessing: false,
      error: {
        title: "Unknown error...",
        info: "Please, contact support"
      },
      isScrolled:false // used to control the refreshing of the order display
    };
  },
  computed: {
    ...mapGetters({
      shouldDeductQuantity: 'orders/shouldDeductQuantity',
      orderList: 'orders/orderList',
      refundedOrdersList:"orders/refundedOrdersList",
      showSelectedOrder:"orders/showSelectedOrder"
    }),
    filteredOrders: function () {
      const self = this;

      if(!self.isScrolled)
        this.resetCurrentOrder() // if page is scrolled then it should not reset the current ordr until and unless the status filters are changed
      let offlineRefundedOrders = this.orders.filter((order) => {
        if (!order.id) {
          return order.status === ORDER_STATUS.REFUNDED
        }
      })
      let refundedOrders= [...self.refundedOrdersList]
      refundedOrders.push(...offlineRefundedOrders)

      let orders = this.orderList.filter(function (order) {
        return order.status === self.statusFilter;
      });
      let offlineOrders = this.orders.filter((order) => {
        if (!order.id) {
          return order.status === self.statusFilter
        }
      })
      if (self.statusFilter === ORDER_STATUS.COMPLETED) {
        // let repeatedItems = []
        refundedOrders.forEach(order => {

          if (order.reference_order !== null && order.status === ORDER_STATUS.REFUNDED) {
            const selectedOrder = orders.find(selectedOrder => {
              if (selectedOrder.id === order.reference_order) {
                return selectedOrder
              }

            })
          const selectedOrderIndex = orders.findIndex(selectedOrder => selectedOrder.id === order.reference_order)
            if (selectedOrder)

              if (order.items.length > 0) {

                order.items.forEach(item => {

                  let itemIndex = -1
                  selectedOrder.items.findIndex((selectedItem,index) => {

                    if (selectedItem.id === item.order_refund_item) {
                      itemIndex = index
                       return index
                    }
                  })

                  if (itemIndex > -1) {
                    // let orderQuantity = selectedOrder.items[itemIndex].quantity
                    if (item.void_status === 0 && (selectedOrder.items[itemIndex].void_status === 0 || selectedOrder.items[itemIndex].void_status === null  ) ) {
                      // all the values are already in negative thus add operation will be perform
                      if (!item.void_item ) {
                        item.void_item = true
                        selectedOrder.items[itemIndex].isPartialRefund = true
                        selectedOrder.total_sum += parseFloat((item.grand_total).toFixed(2))
                        orders[selectedOrderIndex] = selectedOrder
                      }
                    }

                  }

                })
              }
        }

        })
      this.setDeductRefundQuantity(false)
        orders.forEach((selectedOrder,selectedOrderIndex) => {
          if (selectedOrder.total_sum <= 0.01 && selectedOrder.status === ORDER_STATUS.COMPLETED) {
            selectedOrder.isFullyRefund = true
            orders[selectedOrderIndex] = selectedOrder

        }
      })
      // orders = [...duplicateOrders]
    }

      orders = orders.sort((a, b) => {
        return b.id - a.id;
      }).splice(0, this.countItems);

      if (orders.length > 0 && !this.isScrolled) {
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.currentOrder = this.showSelectedOrder ? this.$store.getters["orders/getOrderByIdOrLocalId"](this.showSelectedOrder) : orders[0];  // page is not scrolled then it will update the current order

      }
      if (offlineOrders.length > 0) {
        offlineOrders.forEach((offlineOrder) => {
          orders.unshift(offlineOrder)
        })
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.currentOrder = this.showSelectedOrder ? this.$store.getters["orders/getOrderByIdOrLocalId"](this.showSelectedOrder) : orders[0];
      }
      return orders
    },
    orderStatusTextView: function () {
      const currentOrder = this.currentOrder;
      if (!currentOrder.status)
        return "";

      switch (currentOrder.status) {
        case this.ORDER_STATUS.COMPLETED :
          return this.$t("vieworderspopup.completed");
        case this.ORDER_STATUS.OPEN :
          return this.$t("vieworderspopup.open");
        case this.ORDER_STATUS.CANCELLED :
          return this.$t("vieworderspopup.cancelled");
        case this.ORDER_STATUS.HOLD :
          return this.$t("vieworderspopup.hold");
        case this.ORDER_STATUS.REFUNDED :
          return this.$t("vieworderspopup.refunded");
        default:
          return "";
      }
    },
    orders: function () {
      return this.$store.getters["orders/getAllOrders"];
    },
    currentShift: function () {
      return this.$store.getters["shift"];
    },
    isLoad: function () {
      return this.$store.getters["orders/isLoad"];
    },
    isMobile: function () {
      return window.innerWidth <= 992;
    },
    store () {
      return this.$store.getters["config/store"];
    },
    terminal () {
      return this.$store.getters["config/terminal"];
    },
    currency () {
      return this.$store.getters["config/currency"];
    },
    orderHasOnlyGiftCardProduct(){
      return this.currentOrder.items.filter(item => item.void_status === 0).every(item=>item.product === -2)
    }
  },
  watch: {
    async statusFilter(status) {

       // nullify the selected order on change of status selection
       this.setSelectedOrder(null)
        // checking if the status is changed an page is not scrolled
        this.isScrolled = false
        const payload = {
          orderStatus: status,
          firstPage: 1,
          initial: true
        }
        if (status === ORDER_STATUS.COMPLETED) {
          await this.fetchOrderListByStatus(payload)
        }
        else {
          await this.fetchOrderListByStatus(payload)
        }
        this.countItems = this.perPage;
    },
    searchValue (value, prevValue) {
      const idRegex = "^[0-9_]*$";
      if (value.search(idRegex) === -1) this.searchValue = prevValue;
    }
  },
  async created() {
    // do not fetch data until and unless the state are empty 
    if(this.orderList.length === 0 || this.orders.length === 0)
      await this.fetchOrdersFromDb()   
    if ("serviceWorker" in navigator)
      navigator.serviceWorker.addEventListener("message", this.receiveMessage);
  },
  async mounted () {
    this.startTimer();

    const payload = {
              orderStatus: this.statusFilter,
              initial:true
    }

    await this.fetchOrderListByStatus(payload)
    const container = document.querySelector("#infinite-list");
    const self = this;
    if(container)
      container.addEventListener("scroll", async function () {
        if (self.isLoad) return false;
        const userScrollHeight = container.scrollTop + container.clientHeight;
        const threshold = userScrollHeight + container.scrollHeight * 0.2;
        if (threshold - container.scrollHeight > -2) {
          // setting the boolean so it does not updates the currentOrder
          self.isScrolled = true
          const payload = {
            orderStatus: self.statusFilter,
            initial: false
          }
          await self.fetchOrderListByStatus(payload)
          self.countItems = self.countItems + self.perPage;
        }
      });
  },
  beforeDestroy () {
    // if ("serviceWorker" in navigator)
    //   navigator.serviceWorker.removeEventListener("message", this.receiveMessage);

    this.$store.dispatch("resetPageCounterForAll");

    // nullify the selected order on change of status selection
    this.setSelectedOrder(null)
  },
  methods: {
    ...mapActions('orders',
    [
      'setRefundItems',
      'setRefundOrderId',
      'setDeductRefundQuantity',
      'setRefundOrderTableId',
      'fetchOrderListByStatus',
      'emptyOrderList', 
      'fetchOrderById',
      'setRefundedOrderTotalDiscount',
      'fetchOrdersFromDb',
      'setSelectedOrder'
  ]),
  toFixedNoRounding,
    confirmAction (value) {
      if (!value) return false;
      this[this.actionToConfirm]();
      this.$bvModal.hide("confirm-action--modal");
    },
    showRefundModal() {
      const self = this
      let orderToBeRefunded = { ...this.currentOrder }
      // checking if the refunded order was previously made through gift card
      if(orderToBeRefunded.order_gift_card_payment.length > 0){
        console.log("WITH IN CLAUSE", orderToBeRefunded.order_gift_card_payment.map(gift_card_payment=>gift_card_payment.gift_card_number) )
        this.setRefundGiftCardIds(orderToBeRefunded.order_gift_card_payment.map(gift_card_payment=>gift_card_payment.gift_card_number))
      }
      orderToBeRefunded.items.forEach(item=>item.duplicateQuantity = item.quantity)
      let refundedOrders = self.refundedOrdersList.filter(refundedOrders => refundedOrders.reference_order === orderToBeRefunded.id)
      let offlineRefundedOrders = this.orders.filter((order) => {
        if (!order.id) {
          return order.status === ORDER_STATUS.REFUNDED && order.reference_order === orderToBeRefunded.id
        }
      })
      refundedOrders.push(...offlineRefundedOrders)
      if (refundedOrders.length > 0) {
        refundedOrders.forEach(refundedOrder => {
          orderToBeRefunded.items.forEach((orderToBeRefundedItems,itemIndex) => {
          const item = refundedOrder.items.filter(refundedOrderItem => refundedOrderItem.order_refund_item === orderToBeRefundedItems.id)

            if (item.length > 0 && item[0].void_item) {
              orderToBeRefunded.items[itemIndex].duplicateQuantity += item[0].quantity
            }
          })
        })
      }
      // saving the attributes which calculates discount total for the refunded order of the reference order
      const discountTotalDetails = {
        value: this.currentOrder.discount_orders_raw_value,
        discountType: this.currentOrder.discount_orders_raw_type

      }
      // exclude gift card product with if -2
      this.setRefundItems(orderToBeRefunded.items.filter(item=>item.product !== -2) || [])
      // stroing in store
      this.setRefundedOrderTotalDiscount(discountTotalDetails)
      this.setRefundOrderId(this.currentOrder.id)
      this.setRefundOrderTableId(this.currentOrder.table)
      this.$router.push({ name: 'Order', params: { id: 'new',tableId: null, referenceId:this.currentOrder.id, refund:true } })
    },
    async refund () {
      this.$bvModal.show("processing-modal");
      let isError = false;
      const paymentObjects = [];
      try {
        // eslint-disable-next-line no-unused-vars
        for (const payment of this.currentOrder.payments) {
          const amount = 0 - (payment.amount - payment.change_amount);
          const current_payment_type = this.terminal.payment_types.find(py => py.name === payment.payment_type);
          let result;
          if (!current_payment_type) {
            this.error.title = "Payment type is not found";
            this.error.info = "Payment type is not available at current terminal";
            isError = true;
            break;
          }
          const behavior = current_payment_type.behavior;
          if (behavior === PAYMENT_BEHAVIOR.NABANCARD_INTEGRATION) {
            // NA payment
            // try void
            try {
              result = await makeNAVoid(current_payment_type.url, payment.processor_response);
              if (result && result.status === "error") {
                throw Error("Could not refund");
              }
            } catch (e) {
              console.error("Could not void");
              console.error(e);
              Sentry.captureException(e);
              isError = true;
            }
            // try refund
            if (!result || (result && result["status"] === "error")) {
              try {
                result = await makeNARefund(current_payment_type.url, payment.processor_response);
                if (result && result.status === "error") {
                  throw Error("Could not refund");
                }
              } catch (e) {
                console.error("Could not refund");
                console.error(e);
                Sentry.captureException(e);
                isError = true;
              }
            }
            if (!result || (result && result["status"] === "error")) {
              console.error("Can not refund");
              this.error.title("Can not refund");
              this.error.info("Can not refund, contact administrator");
              isError = true;
            }
            // NA payment end
          }
          if (!isError) {
            paymentObjects.push({
              order: payment.order,
              employee: payment.employee,
              amount: amount,
              amount_paid: amount,
              tips: payment.tips,
              change_amount: 0,
              payment_type: payment.payment_type,
              payment_date: new Date().toISOString(),
              related_terminal: this.terminal.id,
              transaction_type: TRANSACTION_TYPE.RETURN,
              processor_refund_response: result && result.response ? result.response : "",
              card_lastfour: null,
              local_id: `${this.terminal.id}_${this.currentOrder.payments.length + 1 + paymentObjects.length}`,
              shift: this.currentShift.id
            });
          }

        }
      } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        isError = true;
      }
      if (isError) {
        this.$bvModal.show("modal-error-checkout");
      } else {
        this.currentOrder.status = ORDER_STATUS.REFUNDED;
        this.currentOrder.payments = [...this.currentOrder.payments, ...paymentObjects];
        paymentObjects.map(async (paymentObject) => {
          await this.savePaymentToShift({ name: paymentObject.payment_type, amount: paymentObject.amount }, "refund");
        });
        this.$store.commit("orders/saveOrder", this.currentOrder);
        this.$bvModal.show("success-action--modal");
      }
      await this.$bvModal.hide("processing-modal");
      await this.$bvModal.hide("processing-modal");

    },
    async searchByNumber (searchValue=null) {
      
      const id = searchValue ? searchValue : this.searchValue;
      if (isNaN(id)) {
        if (!id.includes("_")) return this.searchError();
        return this.localSearch(id);
      }
      if (!navigator.onLine) return this.localSearch(id);
      const orders = await this.fetchOrderById(id)
      if (!orders.length) return this.searchError();
      this.currentOrder = orders[0];

      return this.searchValue = "";

    },
    localSearch (id) {
      const orders = this.orderList.filter(order => order.id == id);
      if (!orders.length) return this.searchError();

      this.currentOrder = orders[0];

      return this.searchValue = "";
    },
    searchError () {
      sound.play();
      this.infoModal.text = "Order not found";
      this.$bvModal.show("info-modal");

      return this.searchValue = "";
    },
    receiveMessage (event) {
      const self = this;
      self.updateLocalId(event.data);
    },
    startTimer () {
      const vm = this;
      const timer = setInterval(function () {
        vm.value += 2;
        if (vm.value >= 180) clearInterval(timer);
      }, 50);
    },
    updateLocalId: function (msgObj) {
      if (this.currentOrder.id === msgObj.localID) {
        this.currentOrder.id = msgObj.remoteID;
      }
    },
    viewOrder: function (viewOrder) {
      this.sidebarMob = false;
      this.isScrolled = true
      const currentOrder = this.orderList.find(order => order.id === viewOrder.id);
      if (currentOrder) {
        console.log("VIEWING ORDER",currentOrder)
        this.currentOrder = currentOrder
      }
      else {
        const currentOrder = this.orders.find(order => order.local_id === viewOrder.local_id);
        this.currentOrder = currentOrder
      }
    },
    convertDate: function (date) {
      return date ? this.$moment(date).format("D MMMM YYYY hh:mmA") : "";
    },
    calculateDiscount: function (itemCost, discount) {
      return discount ? (parseFloat((discount / itemCost * 100).toFixed(2))) : 0;
    },
    isDisplayPaymentDetailsList: function () {
      return this.displayPaymentDetailsList = !this.displayPaymentDetailsList;
    },
    resetCurrentOrder() {
      this.currentOrder = {
        id: 0,
        localID: 0,
        subtotal: 0,
        tax_total: 0,
        grand_total: 0,
        items: [],
        table: 0,
        status: null
      }
    },
    showCollapse(index) {
      return this.itemsAtCurrentOrder.length > 0 && this.itemsAtCurrentOrder.length - 1 === index
    }
  }
};
</script>

<style lang="scss">
.orders-bottom-options {
  border-bottom: 1px solid #e0e0e0;
}

.list-complete-item {
  transition: all .5s;
}

.list-complete-enter, .list-complete-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.list-complete-leave-active {
  position: absolute;
}

.popup-price {

  .modal-dialog {
    max-width: 440px;
  }

  .modal-body {
    padding: 30px 44px;

    @media (max-width: 767.98px) {
      padding: 25px 12px;
    }
  }

  .modal-header {
    background: #FAFAFA;
    align-items: center;
    position: relative;
    justify-content: center;
    height: 72px;

    .btn {
      min-width: 84px;
      position: absolute;
      left: 16px;
      top: 50%;
      margin-top: -20px;

      @media (max-width: 767.98px) {
        left: 12px;
      }
    }

    h5 {
      margin: 0px;
      text-align: center;
    }
  }

  .modal-footer {

    border: none;
    position: absolute;
    width: 130px;
    text-align: center;
    top: 0;
    bottom: 0;
    background: #EBEBEB;
    border-radius: 0px 10px 10px 0px;
    right: -130px;
    border-left: 3px solid #2A7BFF;
    padding: 0px;
    align-items: flex-start;
    overflow: hidden;
    height: 100%;

    .w-100 {
      height: 100%;
    }

    h5 {
      font-size: 16px;
      line-height: 19px;
      color: rgba(74, 74, 74, 0.717108);
      background: #FAFAFA;
      height: 71px;
      line-height: 71px;
      margin: 0px;
      width: 100%;
    }

    ul {
      overflow-y: auto;
      height: calc(100% - 71px);

      li {
        padding: 1px 0;
      }

      a {
        display: block;
        height: 72px;
        width: 100%;
        font-size: 15px;
        line-height: 21px;
        color: #1E1E1E;
        text-decoration: none;
        background: #fff;
        transition: all .2s ease-in-out;
        padding: 10px;

        &.active,
        &:hover {
          background: #2889FA;
          color: #fff;
        }

        span {
          display: block;
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }
      }


    }
  }

  &-sidebar {
    .modal-content {
      border-radius: 10px 0px 0px 10px;
    }

    .modal-header {
      border-radius: 10px 0px 0px 0rem;
    }

    .modal-body {
      border-radius: 0rem 0px 0px 10px;
    }
  }
}


.modifiers-sidebar-backdrop {
  background: #212121;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  cursor: pointer;
  opacity: .5;
  z-index: 10;

  transition: all .2s ease;
}

.modifiers-sidebar-backdrop[aria-pressed="false"] {
  opacity: 0;
  z-index: -1;
}


.slide-enter-active,
.slide-leave-active {
  left: 0;
  transition: transform 0.2s ease;
}

.slide-enter,
.slide-leave-to {
  left: 100%;
  transition: all .15s ease-in 0s;
}


@media (max-width: 767.98px) {
  .list-complete-item,
  .modifiers-sidebar-backdrop,
  .slide-enter-active,
  .slide-leave-active,
  .slide-enter,
  .slide-leave-to {
    transition: none !important;
  }
}


.modifiers-sidebar-panel {
  position: absolute;
  z-index: 100;
  top: 0;
  background: #eeeeee;
  left: 0;
  width: 100%;
  bottom: 0;
}

.order-sum {
  background-color: #fff;
  color: #2c3e50;
  height: 60px;
  font-size: 1.375rem;
  line-height: 1.875rem;
  padding: 15px 16px;
}

.icon-dot{
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50px;
}
.order-list-container {
  .v-spinner {
    height: 100% !important;
  }
}
.table .orders-table-info .hold-note{
    font-size:1.10rem;
}

</style>

const STORAGE_KEY = 'first-tab';

const tabKey = String(Date.now() + Math.random());

const activeTab = localStorage.getItem(STORAGE_KEY);
if (!activeTab) {
  localStorage.setItem(STORAGE_KEY, tabKey);
}

export function setCurrentTab(_callback) {
  if (localStorage.getItem(STORAGE_KEY) !== tabKey) {
    _callback();
  }
}

export function removeCurrentTab() {
  console.log('removeTabChangeListener');
  localStorage.removeItem(STORAGE_KEY);
  localStorage.setItem('removed', 'true');
}

<template>
  <div class="pin-page h-100 text-center">
    <b-container
      fluid
      class="h-100"
    >
      <b-row class="h-100 align-content-center">
        <b-col cols="12">
          <b-navbar
            variant="faded"
            type="light"
          >
            <b-navbar-brand href="#">
              <img
                class="logo"
                src="../assets/images/logo.svg"
                alt=""
              >
            </b-navbar-brand>
          </b-navbar>
          <PinBlockComponent
            :title="$t('pinscreen.enter-pin-to-login')"
            :pin-msg="$t('pinscreen.enter-pin-to-login')"
            :wrong-pin-msg="$t('pinscreen.wrong-pin')"
            :is-display-default-pin="isDisplayDefaultPin"
            :default-pin="DEFAULT_PIN"
            @get-employee="getEmployeeByPin"
          />
        </b-col>

        <div
          v-if="terminal"
          class="terminal-version"
        >
          {{ terminal.name }}: {{ terminal.id }}
        </div>
        <div class="sync-btn">
          <div class="sync-btn__wrapper">
            <button
              @click="goToSync"
              :class="{'disabled':isSyncInProgress || !connection}"
              :disabled="isSyncInProgress || !connection"
            >
              <svg class="icon icon-icon-popup-sync">
                <use xlink:href="#icon-icon-popup-sync" />
              </svg>
              <span>{{ $t("syncdatapopup.sync-data") }}</span>
            </button>
          </div>
        </div>
        <div class="pin-version">
          V {{ VERSION }}
        </div>
      </b-row>

      <b-modal
        id="modal-1"
        v-model="modalShow"
        :body-bg-variant="bodyBgVariant"
        no-close-on-backdrop
        hide-footer
        title="Please, enter terminal verification code:"
      >
        <div class="pin-num text-center">
          <ul class="pin-num-list">
            <li><span>{{ getCodeSymbol(0) }}</span></li>
            <li><span>{{ getCodeSymbol(1) }}</span></li>
            <li><span>{{ getCodeSymbol(2) }}</span></li>
            <li><span>{{ getCodeSymbol(3) }}</span></li>
            <li><span>{{ getCodeSymbol(4) }}</span></li>
            <li><span>{{ getCodeSymbol(5) }}</span></li>
          </ul>
        </div>

        <div class="pin-keyboard text-center">
          <ul class="pin-keyboard-list">
            <li>
              <span
                class="pin-key"
                @click="typeCode(1)"
              >1</span>
            </li>
            <li>
              <span
                class="pin-key"
                @click="typeCode(2)"
              >2</span>
            </li>
            <li>
              <span
                class="pin-key"
                @click="typeCode(3)"
              >3</span>
            </li>

            <li>
              <span
                class="pin-key"
                @click="typeCode(4)"
              >4</span>
            </li>
            <li>
              <span
                class="pin-key"
                @click="typeCode(5)"
              >5</span>
            </li>
            <li>
              <span
                class="pin-key"
                @click="typeCode(6)"
              >6</span>
            </li>

            <li>
              <span
                class="pin-key"
                @click="typeCode(7)"
              >7</span>
            </li>
            <li>
              <span
                class="pin-key"
                @click="typeCode(8)"
              >8</span>
            </li>
            <li>
              <span
                class="pin-key"
                @click="typeCode(9)"
              >9</span>
            </li>

            <li><span class="pin-key pin-key_call">C</span></li>

            <li>
              <span
                class="pin-key"
                @click="typePin(0)"
              >0</span>
            </li>
          </ul>
        </div>

        <b-button
          class="mt-4"
          variant="primary"
          block
        >
          {{ $t('verificationcodeinputpopup.accept') }}
        </b-button>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import store from '../store/store';
import i18n from '@/plugins/i18n';
import PinBlockComponent from './particle/PinBlockComponent';
import { mapGetters, mapActions } from "vuex";

const DEFAULT_PIN = 1234;
export default {
  name: 'AppPin',
  components: {
    PinBlockComponent
  },
  data() {
    return {
      pin: '',
      code: '',
      VERSION: '',
      modalShow: false,
      bodyBgVariant: 'light',
      employee: 'en', // default
      pinMsg: this.$i18n.t('pinscreen.enter-pin-to-login'),
      // terminal: null,
      isDisplayDefaultPin: false,
      DEFAULT_PIN
    };
  },
  computed: {
    ...mapGetters(['isSyncInProgress']),
    pinLength() {
      return this.pin.length;
    },
    isShiftOpen() {
      return this.$store.getters.isShiftOpen;
    },
    terminal() {
      return this.$store.getters['config/terminal'];
    },
    connection () {
      return this.$store.getters.connection;
    }
  },
  beforeMount: async function () {
    this.VERSION = process.env.VUE_APP_VERSION;
  },
  created: async function () {
    await this.fetchEmployees()
    const defaultEmployee = this.$store.getters['config/employeeById'](1);
    if (defaultEmployee && defaultEmployee.pin === DEFAULT_PIN)
      this.isDisplayDefaultPin = true;
  },
  async mounted() {
    this.pin = '';
  },
  methods: {
    ...mapActions({
      fetchEmployees: 'config/fetchEmployeesFromDb'
    }),
    goToSync() {
      this.$router.push({ name: 'Synchronize' });
    },
    async getEmployeeByPin(employee) {
      if (!employee) {
        console.warn('Could not find user with given pin...');
      } else {
        await this.$store.dispatch('setEmployee', employee)
        store.commit('unlock');
        this.clearPin();
        this.changeLocale(employee.language);
        await store.dispatch('setEmployee', { employee });
        if (!this.isShiftOpen) return await this.$router.push({ name: 'Shift' });
        this.terminal.mode === 1 ? await this.$router.push({ name: 'Tables' }) : await this.$router.push({
          name: 'Order',
          params: {
            tableID: null,
            id: 'new'
          }
        });
      }
    },
    onKey(event) {
      if (event.keyCode >= 48 && event.keyCode <= 57) {
        this.typePin(event.keyCode - 48);
      } else if (event.keyCode === 8) {
        this.clearPin();
      }
    },
    getCodeSymbol(position) {
      if (position >= this.code.length) {
        return '';
      } else {
        return this.code[position];
      }
    },
    clearPin() {
      this.pin = '';
    },
    clearCode() {
      this.code = '';
    },
    changeLocale(locale) {
      i18n.locale = locale;
    }
  }
};
</script>

<style lang="scss" scoped>
.pin-page {
  background: linear-gradient(180deg, #1976D2 0%, #2196F3 100%);
  color: #fff;
  min-height: 100vh;

  h6 {
    font-size: 15px;
    line-height: 18px;
  }

  .pin-block {
    padding-top: 15px;
  }

  @media (max-height: 376px) {
    height: auto !important;
  }
}

.navbar {
  padding: 30px 0 30px;
}

.navbar-brand {
  padding: 0;
  margin: 0 auto;
  height: 60px;

  img {
    max-height: 100%;
    height: 100%;
  }
}

.terminal-version {
  position: absolute;
  bottom: 20px;
  font-size: .875rem;
  line-height: 1rem;
  color: #fff;
  pointer-events: none;
}

.pin-version {
  right: 20px;
}

.terminal-version {
  left: 20px
}

.sync-btn {
  position: absolute;
  bottom: 20px;
  width: 100%;

  &__wrapper {
    position: relative;

    button {
      background: #FFFFFF;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      border: none;
      padding: 15px 45px 8px;

      span {
        display: block;
        padding: 5px 0;
      }

      .icon-icon-popup-sync {
        fill: #2196F3;
      }
    }
  }
}
.disabled{
  opacity:0.7;
  cursor:default;
}

</style>

<template>
  <div class="pin-block">
    <div class="pin-block__title">
      <h6>{{ title }}</h6>
      <h6 v-if="isDisplayDefaultPin">
        Default PIN is ({{ defaultPin }})
      </h6>
    </div>
    <div class="pin-dots">
      <ul class="pin-dots-list">
        <li
          v-for="i in 4"
          :key="i"
          :class="{filled: pinLength>=i}"
        />
      </ul>
    </div>

    <div
      class="pin-text"
      style="padding-bottom: 40px;"
    >
      <span>{{ message }}</span>
    </div>

    <div class="pin-keyboard">
      <ul class="pin-keyboard-list">
        <li>
          <span
            class=" pin-key"
            @click="typePin(1)"
          >1</span>
        </li>
        <li>
          <span
            class=" pin-key"
            @click="typePin(2)"
          >2</span>
        </li>
        <li>
          <span
            class=" pin-key"
            @click="typePin(3)"
          >3</span>
        </li>

        <li>
          <span
            class=" pin-key"
            @click="typePin(4)"
          >4</span>
        </li>
        <li>
          <span
            class=" pin-key"
            @click="typePin(5)"
          >5</span>
        </li>
        <li>
          <span
            class=" pin-key"
            @click="typePin(6)"
          >6</span>
        </li>

        <li>
          <span
            class=" pin-key"
            @click="typePin(7)"
          >7</span>
        </li>
        <li>
          <span
            class=" pin-key"
            @click="typePin(8)"
          >8</span>
        </li>
        <li>
          <span
            class=" pin-key"
            @click="typePin(9)"
          >9</span>
        </li>

        <li><span /></li>
        <li>
          <span
            class="pin-key"
            @click="typePin(0)"
          >0</span>
        </li>
        <li><span @click="clearPin">{{ $t('pinscreen.clear') }}</span></li>
      </ul>
    </div>
  </div>
</template>

<script>

  // import {getEmployeeByPin} from "../../helpers/dbHelper";

  export default {
    name: "PinBlockComponent",
    props: {
      'title': {
        type: String,
        required:false,
        default: ''
      },
      'clearFormTrigger': {
        type: Boolean,
        required:false,
        default: false
      },
      'pinMsg': {
        type: String,
        required:false,
        default: ''
      },
      'wrongPinMsg': {
        type: String,
        required: false,
        default: 'Wrong pin, try again'
      },
      'isDisplayDefaultPin': {
        type: Boolean,
        required: false,
        default: false
      },
      'defaultPin': {
        type: Number,
        required: false,
        default: 1234
      }
    },
    data () {
      return {
        pin: '',
        isValid: true,
        message: ''
      }
    },
    computed: {
      pinLength () {
        return this.pin.length;
      }
    },
    watch: {
      clearFormTrigger(value) {
        if (value) {
          this.clearPin();
        }
      },
      isValid(value) {
        if (!value) return this.setMessage(this.wrongPinMsg);

        return this.setMessage(this.pinMsg);

      }
    },
    created() {
      window.addEventListener('keypress', this.onKey);
      this.setMessage(this.pinMsg);
    },
    beforeDestroy() {
      window.removeEventListener('keypress', this.onKey);

    },
    methods: {
      setMessage(text) {
        this.message = text;
      },
      clearPin () {
        this.pin = '';
      },
      onKey (event) {
        if (event.keyCode >= 48 && event.keyCode <= 57) {
          this.typePin(event.keyCode - 48);
        } else if (event.keyCode === 8) {
          this.clearPin();
        }
      },
      async typePin (num) {
        if (this.pin.length < 4) {
          this.pin = this.pin.concat(num);
        }
        if (this.pin.length === 4) {
          const employee = await this.$store.dispatch('config/getEmployeeByHashedPin', this.pin);
          if (!employee) {
            this.clearPin();
            this.isValid = false;
          }
          return this.$emit('get-employee', employee);
        }
      }
    }
  }
</script>

<style scoped>
  .pin-dots-list {
    padding-top: 0 !important;
  }
</style>

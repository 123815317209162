<template>
  <!-- Modal Error -->
  <b-modal
    :id="id"
    hide-footer
    hide-header
    no-close-on-backdrop
    modal-class="success-popup success-popup_wrong"
    no-fade
  >
    <div class="text-center modal-complete">
      <svg class="icon icon-icon-popup-wrong">
        <use xlink:href="#icon-icon-popup-wrong" />
      </svg>

      <h2 style="white-space: normal;">
        {{ error.title }}
      </h2>

      <div class="popup-meta">
        {{ error.info }}
      </div>

      <div class="d-flex justify-content-center align-items-center">
        <b-button
          v-if="error.title === 'Payment Error'"
          variant="outline-primary"
          class="w-100 m-3"
          @click="retryPayment()"
        >
          <!-- TODO: need to add translation -->
          Retry
        </b-button>
        <b-button
          :class="`w-100 ${error.title === 'Payment Error' ? 'm-3' : ''}`"
          variant="outline-warning"
          @click="hideModal"
        >
          {{ $t('errorpopup.close') }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
  export default {
    name: 'ModalError',
    props: {
      id: {
        default: 'modal-error',
        type: String
      },
      error: {
        type: Object,
        required: true
      },
      cancelCallback: {
        type: Function,
        required: false,
        default: () => {}
      }
    },
    data () {
      return {}
    },
    methods: {
      // todo rewrite this method to a general one (name)
      retryPayment() {
        return this.$emit('retry-payment');
      },
      hideModal() {
        this.cancelCallback();
        this.$emit('hide-modal')
        this.$bvModal.hide(this.id);
      }
    }
  }
</script>

<template>
  <div class="kiosk h-100 text-center">
    <b-container
      v-if="store"
      fluid
      class="h-100"
    >
      <b-row class="h-100 align-content-center">
        <img
          :src="store.background"
          class="kiosk-bg"
        >
        <img
          :src="store.logo"
          class="kiosk-logo"
        >
        <img
          v-long-press="5000"
          src="../assets/images/powered.svg"
          class="kiosk-powered"
          @long-press-start="goToTools"
        >

        <b-col cols="12">
          <div class="kiosk-inner">
            <h3>
              Select order type
            </h3>


            <div class="d-flex flex-wrap justify-content-center align-items-center">
              <b-button
                v-for="dining_option in terminal['dining_options']"
                :key="dining_option.id"
                @click="goToOrder(dining_option.id)"
                variant="light"
                class="kiosk-btn"
              >
                {{ dining_option.name }}
              </b-button>
            </div>
          </div>
        </b-col>

        <div
          v-if="terminal"
          class="terminal-version"
        >
          {{ terminal.name }}: {{ terminal.id }}
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import LongPress from "vue-directive-long-press";

export default {
  name: "KioskOrderType",
  components: {},
  directives: {
    "long-press": LongPress
  },
  data () {
    return {};
  },
  computed: {
    terminal () {
      return this.$store.getters["config/terminal"];
    },
    store () {
      return this.$store.getters["config/store"];
    },
    isKioskMode () {
      return this.$store.getters['config/isKioskMode'];
    }
  },
  methods: {
    goToTools () {
      this.$router.push({ name: "Tools" });
    },
    async goToOrder (diningOption) {
      const newOrder = await this.$store.dispatch('orders/initNewOrder',
        {related_terminal: this.terminal.id})

        // add dining options in kiosk mode.
      if (!newOrder.dining_option_type) {
        newOrder.dining_option_type = diningOption;
        newOrder.dining_option = this.terminal["dining_options"].find(option =>  newOrder.dining_option_type === option.id)?.name;
      }
      this.$store.commit("orders/saveOrder", newOrder);
      await this.$router.push({ name: "Order", params: { id: newOrder.local_id, diningOption } });
    }
  }
};
</script>

import {ORDER_STATUS} from "@/helpers/order/status";
import * as Sentry from "@sentry/vue";


export const orderList = {
  state: () => ({
    list: [],
    lastStatus: ORDER_STATUS.CANCELLED,
    page: {
      0: 2,
      1: 2,
      2: 2,
      3: 2,
      4: 2,
      5: 2
    },
    isLoad: false
  }),
  mutations: {
    // eslint-disable-next-line no-unused-vars
    UPDATE_LIST: async (state) => {
      // state.list = await getOrdersFromDB();
      // state.isLoad = false;
    },
    // eslint-disable-next-line no-unused-vars
    ADD_DATA_TO_LIST: async (state, data) => {
      // await addToDb('orders', data);
      // state.list = await getOrdersFromDB();
      state.isLoad = false;
    },
    INCREMENT_PAGE: (state, statusIndex) => ++state.page[statusIndex],
    STOP_INCREMENT: (state, statusIndex) => state.page[statusIndex] = 0,
    RESET_PAGE_COUNTER_FOR_ALL_STATUSES: (state) => state.page = {
      0: 2,
      1: 2,
      2: 2,
      3: 2,
      4: 2,
      5: 2
    },
    RESET_PAGE_COUNTER_FOR_STATUS: (state, status) => state.page[status] = 2
  },
  actions: {
    getList: async ({dispatch, state}, payload) =>  {
      if (!state.page[payload.status]) return false;
      if (!navigator.onLine) return dispatch('updateList');
      state.isLoad = true;
      try {
        if (payload.firstPage) {
          // const data = await getOrders(payload.status, 1, false);
          // await addToDb('orders', data);
          dispatch('updateList');
          if (state.page[payload.status] === 1) await dispatch('incrementPage', {status})
        } else {
          // const data = await getOrders(payload.status, state.page[payload.status]);
          // dispatch('addDataToList', {data: data})
        }
        state.isLoad = false;
      } catch (e) {
        Sentry.captureException(e);
        state.isLoad = false
      }
    },
    // eslint-disable-next-line no-unused-vars
    getOrder: async ({dispatch}, payload) => {
      // const order = await getOrder(payload.id);
      // const results = order.results;
      // if (results.length) {
      //   await addToDb('orders', order);
      //   dispatch('updateList');
      // }

      // return results;
    },
    updateList: ({commit})                         => commit('UPDATE_LIST'),
    incrementPage: async ({commit}, payload)       => commit('INCREMENT_PAGE', payload.status),
    stopIncrement: ({commit}, payload)             => commit('STOP_INCREMENT', payload.status),
    addDataToList: ({commit}, payload)             => commit('ADD_DATA_TO_LIST', payload.data),
    resetPageCounterForAll: ({commit})             => commit('RESET_PAGE_COUNTER_FOR_ALL_STATUSES'),
    resetPageCounterForStatus: ({commit}, payload) => commit('RESET_PAGE_COUNTER_FOR_STATUS', payload.prevStatus)

  },
  getters: {
    list: state => state.list,
    completed: state => state.list.filter(order => order.status === ORDER_STATUS.COMPLETED),
    cancelled: state => state.list.filter(order => order.status === ORDER_STATUS.CANCELLED),
    isLoad: state => state.isLoad
  }
}

<template>
  <div>
    <b-container class="text-left pt-4">
      <h3>Reset</h3>
      <scale-loader
        :loading="isLoading"
        :color="'#007bff'"
      />
    </b-container>
  </div>
</template>

<script>
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import * as Sentry from "@sentry/vue";
export default {
	name: "Reset",
	components: {
		ScaleLoader
	},
	data () {
		return {
			isLoading: true,
			errors: []
		};
	},
	async created () {
		try {
			console.log(`Reset store to default`);
			this.$store.commit("reset");
		} catch (e) {
			Sentry.captureException(e);
			console.warn("Could not clear a local storage", e);
		}
		this.isLoading = false;
		await this.$router.push({ name: "Auth" });
	}
};
</script>

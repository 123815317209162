export function setDashboardSettings (auth) {
  if (!auth.url) {
    throw Error('Can not save, missing "url"')
  }
  if (!auth.token) {
    throw Error('Can not save, missing "token"')
  }
  localStorage.setItem('connection', JSON.stringify(auth));
}
export function getDashboardSettings () {
  const settings = localStorage.getItem("connection");
  if (settings === null) {
    throw Error('POS is not connected to Dashboard yet')
  }
  return JSON.parse(settings);
}
export function saveSyncDatetime () {
  localStorage.setItem('lastSyncAt', JSON.stringify(new Date().toUTCString()));
}
export function getSyncDatetime () {
  return localStorage.getItem('lastSyncAt');
}




import { authApiCall } from "./http.hook";
import makeUrl from "./makeUrl";

class DashboardService{
  getSections () {
    return authApiCall(makeUrl("/sections/"), "get");
  }

  getTerminalConfig () {
    return authApiCall(makeUrl("/config/"), "get");
  }

  getTables () {
    return authApiCall(makeUrl("/tables/"), "get");
  }

  getProducts () {
    return authApiCall(makeUrl("/products/"), "get");
  }
  
  getNextProducts (url) {
    return authApiCall(makeUrl(url), "get");
  }

  getCategories () {
    return authApiCall(makeUrl("/categories/"), "get");
  }

  getOrders (payload) {
    return authApiCall(makeUrl("/orders/"), "post", payload);
  }

  getEmployees () {
    return authApiCall(makeUrl("/employees/"), "get");
  }

  getCustomers () {
    return authApiCall(makeUrl("/customers/"), "get");
  }

  getCurrentShift () {
    return authApiCall(makeUrl("/shifts/sync/"), "get");
  }

  getDiscounts () {
    return authApiCall(makeUrl("/discounts/"), "get");
  }

  postOrder (payload) {
    return authApiCall(makeUrl("/orders/sync/"), "post", payload);
  }

  postShift (payload) {
    return authApiCall(makeUrl("/shifts/sync/"), "post", payload);
  }

  postCustomer (payload) {
    return authApiCall(makeUrl("/customer-add/"), "post", payload);
  }

  postTablePositions (payload) {
    return authApiCall(makeUrl("/tablepositions/"), "post", payload);
  }

  getDaySummary () {
    return authApiCall(makeUrl("/daysummary/"), "get");
  }

  getOrderList(orderStatus, pageNumber) { 
    return authApiCall(makeUrl(`/orderlist?status=${orderStatus}&page=${pageNumber}`),"get")
  }
    getOrder(orderId) { 
    return authApiCall(makeUrl(`/orderlist?id=${orderId}`,"get"))
    }
  
  getAllRefundedOrders() { 
    return authApiCall(makeUrl(`/orderlist?status=5`),"get")
  }

  getNextOrders(nextUrl) { 
    const payload = []
    return authApiCall(makeUrl(nextUrl),"post",payload)
  }

  getOnlineOrdersList(orderStatus=0) { 
    return authApiCall(makeUrl(`/online/orderlist?status=${orderStatus}`,"get"))
  }
  getOpenOrders() { 
    return authApiCall(makeUrl(`/orderlist?status=1`,"get"))
  }

  getRecentOrders() { 
    return authApiCall(makeUrl(`/orders/recent`,"get"))
  }

  getCountries(){
    return authApiCall(makeUrl('/countries','get'))
  }

  getUSAStates(){
    return authApiCall(makeUrl('/states','get'))
  }

  deleteAddress(addressId){
    return authApiCall(makeUrl(`/address/${addressId}/`),'delete')
  }

  fetchGiftCardPayments(){
    return authApiCall(makeUrl('/gift-card-payments/'),'get')
  }

  fetchGiftCard(giftCard){
    return authApiCall(makeUrl(`/gift-card/?${giftCard.param}=${giftCard.number}`),'get')
  }

  postGiftCard(giftCard){
    return authApiCall(makeUrl(`/gift-card/`),'post', giftCard)
  }

  updateGiftCard(giftCards){
    return authApiCall(makeUrl(`/gift-card/`), 'patch', giftCards)
  }

  recordGiftCardPayments(giftCardPayments){
    return authApiCall(makeUrl(`/gift-card-payments/record/`), 'post', giftCardPayments)
  }
}

export default new DashboardService();

import { toFixed } from "./utils";
import store from "@/store/store";

export const getItemSubtotal = (item) => {
  if (!item) throw Error("[getItemFinalPrice] Can not calc subtotal as no item provided");
  let newQuantity = item.quantity < 0 ? (-1 * item.quantity) : 0
  return handlingRoundOffCalculations((wholeDiscountArray,price,discount,newQuantity,subTotal,modPrice)=>{
      return wholeDiscountArray.length > 0 ? newQuantity < 0 ? -1 * parseFloat(toFixed(subTotal,2))  : parseFloat(toFixed(subTotal,2)) : parseFloat(toFixed((price-discount+modPrice)*newQuantity,2))
    },item,newQuantity)
};
export const getItemGrandTotal = (item) => {
  const isTaxInclusive = store.getters["config/isTaxInclusive"];
  if (!item) throw Error("[getItemFinalPrice] Can not calc subtotal as no item provided");
  let modifiersSum = 0;
  item.modifiers && item["modifiers"].forEach(function (mod) {
    if (!mod.void_status) modifiersSum += mod.price_original;
  });
  // let res = parseFloat(toFixed(item.quantity * (item.price - item.discount + modifiersSum), 2));
    let res =  handlingRoundOffCalculations((wholeDiscountArray,price,discount,newQuantity,subTotal,modPrice)=>{
      return wholeDiscountArray.length > 0 ? newQuantity < 0 ? -1 * parseFloat(toFixed(subTotal,2))  : parseFloat(toFixed(subTotal,2)) : parseFloat(toFixed((price-discount+modPrice)*newQuantity,2))
    },item,0,modifiersSum)
  if (!isTaxInclusive) {
    res += calculateItemTaxTotal(item) * item.quantity;
  }
  return res;
};

export const getInclusiveTax = (taxedPrice, taxRate) => {
  return taxedPrice - setTaxRound((taxedPrice - taxedPrice / (1 + taxRate) * taxRate), 2);
};
export const getExclusiveTax = (price, taxRate) => {
  return setTaxRound((price * taxRate), 2);
};
export const getModSum = (item) => {
  let modifiersSum = 0;
  item.modifiers && item["modifiers"].forEach(function (mod) {
    if (!mod.void_status) modifiersSum += mod.price_original;
  });
  return modifiersSum;
};

export const calculateItemTaxTotal = (item = 0) => {
  let tax_sum = 0;
  item.tax_items.forEach(function (tax_item) {
    tax_sum += setTaxRound(tax_item.tax_total);
  });
  return tax_sum;
};

export const getOrderSubTotal = (currentOrder) => {
  let res = 0;
  for (const item of currentOrder.items) {
    if (!item.void_status) {
      // res += (item.price - item.discount) * item.quantity;
      res+=handlingRoundOffCalculations((wholeDiscountArray,price,discount,newQuantity,subTotal,modPrice)=>{
        return wholeDiscountArray.length > 0 ? newQuantity < 0 ? -1 * parseFloat(toFixed(subTotal,2))  : parseFloat(toFixed(subTotal,2)) : parseFloat(toFixed((price-discount+modPrice)*newQuantity,2))
      },item)

      console.log("RESSSSSPONSE", res)
      for (const itemMod of item.modifiers) {
        if (!itemMod.void_status) {
          res += itemMod.price_original ? itemMod.price_original * item.quantity : 0;
        }
      }
    }
  }
  return res;
};

export const getOrderGrantTotal = (currentOrder, products, isTaxInclusive) => {
  let res = 0;
  res += getOrderSubTotal(currentOrder);
  if (!isTaxInclusive) {
    //pass
    res += calculateOrderTax(currentOrder, products);
    // console.log("CALCULATING ",res)
  }
  return res
};

export const calculateOrderTax = (order, products) => {
  let overallTax = 0;
  for (const item of order.items) {
    const product = products.find(prod => prod.id === item.product);
    const isTaxed = product.tax_rates && product["tax_rates"].length > 0
      && item.tax_status === 0;
    if (!item.void_status && isTaxed) {
      overallTax += item.tax * item.quantity;
    }
  }
  console.log("THIS IS OVERALL TAx..",overallTax)
  return setTaxRound(overallTax);
};

export const handlingRoundOffCalculations = (fn,item,refundQuantity=0, modPrice = 0,) => {
  // destructing 
  const {discount , wholeDiscount , quantity, price, discountPropotion } = item
  
  // calculating new quantity regarding refunds
  let newQuantity = 0
  if(refundQuantity < 0 ){
    newQuantity = refundQuantity
  }
  else{
    newQuantity = quantity
    
  }


// creating modulus of quantity so that it is always positive regardless of the refunds
let iteratorQuantity = Math.abs(newQuantity)

  let wholeDiscountArray = []

  let subTotal = 0
  
  if(discountPropotion){

      // iterate over the quantity of the item
      for(let numberOfQuantity=1;numberOfQuantity <= iteratorQuantity;numberOfQuantity++){
            
        // if it is the last quantity i.e. if there are 3 quantities if it is on 3rd quantity then this clause will execute
            if(numberOfQuantity===iteratorQuantity){
              let previousDiscountTotal = 0
              if(wholeDiscountArray.length > 0){
                // calculate all the previous discounts on the other quantities e.g. if the array has [3.33,3.33] => return 6.66
                previousDiscountTotal = wholeDiscountArray.reduce((total,discount)=>total+discount, 0)
                // negate the remaning discounts from the propotion of the discount associated to the item e.g 10-6.66 => 3.34 and push  
                wholeDiscountArray.push(roundToTwoDecimalPlaces((discountPropotion - previousDiscountTotal)))
              }
              else{
                // add the distributed discount in the discount array if there is only one item
                wholeDiscountArray.push(roundToTwoDecimalPlaces(wholeDiscount))
              }
            }
            else{
              // add the distributed discount in the discount array
              wholeDiscountArray.push(roundToTwoDecimalPlaces(wholeDiscount))
            }
          }

    // calcualting subtotal
    wholeDiscountArray.forEach(calculatedDiscount=>{
      subTotal += modPrice >=0 ? price - Math.abs(discount+calculatedDiscount) + modPrice : price - Math.abs(discount+calculatedDiscount)
    })
}

return fn(wholeDiscountArray,price,discount,newQuantity,subTotal,modPrice)

}

function roundToTwoDecimalPlaces(num) {
  return Math.round(num * 100) / 100;
}

export const setTaxRound = (tax) => {
  const tempStore = store.getters["config/store"];
  if ('tax_round' in tempStore) {
    if (tempStore.tax_round === 'nearest') {
      return parseFloat(toFixed(tax, 2));
    } else if (tempStore.tax_round === 'no') {
      return parseFloat(tax);
    }
  } else {
    return parseFloat(toFixed(tax, 2));
  }
}
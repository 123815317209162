import ApiService from '@/core/services/api.service'

export const API_ENDPOINT = 'customer-display'

export default {
  // write-sync-message
  writeSyncMessage (data) {
    return ApiService.post(API_ENDPOINT + '/write-sync-message/', data)
  },

  // get-sync-message
  getSyncMessage (data) {
    return ApiService.post(API_ENDPOINT + '/get-sync-message/', data)
  }
}

export const sleep = t => new Promise(s => setTimeout(s, t));


export const toFixed = (num, precision) => {
  return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
};

export const toFixedNoRounding = (num, precision) => {
  const re = new RegExp('^-?\\d+(?:.\\d{0,' + (precision || -1) + '})?');
  return num.toString().match(re)[0];
}

export const fetchWithTimeout = (ms, promise) => {
  const controller = new AbortController();
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      reject(new Error('timeout'));
      controller.abort();
    }, ms);
    promise.then(resolve, reject);
  });
};

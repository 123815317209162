<template>
  <div class="h-100">
    <div
      v-if="terminalMode!=='KIOSK_MODE'"
      id="sidebar"
      :class="{ show: sidebarShow }"
      class="sidebar"
    >
      <b-navbar-nav>
        <template v-for="item in sidebarItems">
          <b-nav-item
            v-if="item.href"
            :key="item.title"
            :href="item.href?item.href:null"
            target="_blank"
            @click="sidebarToggle()"
            :disabled="item.disabled"
          >
            <div class="d-flex align-items-center">
              <div class="sidebar-icon">
                <svg :class="'icon icon-icon-'+ item.icon">
                  <use :xlink:href="'#icon-icon-'+ item.icon" />
                </svg>
              </div>

              <div class="sidebar-item">
                <span class="sidebar-text">
                  {{ item.text }}
                </span>
                <span class="sidebar-meta">
                  {{ item.meta }}
                </span>
              </div>
            </div>
          </b-nav-item>
          <b-nav-item
            v-if="!item.href"
            :key="item.title"
            @click="sidebarToggle(item.path)"
            :disabled="item.disabled"
            :class="{'disabled' : item.disabled}"
          >
            <div class="d-flex align-items-center">
              <div class="sidebar-icon">
                <svg :class="'icon icon-icon-'+ item.icon">
                  <use :xlink:href="'#icon-icon-'+ item.icon" />
                </svg>
              </div>

              <div class="sidebar-item">
                <span class="sidebar-text">
                  {{ item.text }} <span
                    class="badge badge-danger"
                    v-if="item.badgeCounter"
                  >{{ item.badgeCounter }}</span>
                </span>
                <span class="sidebar-meta">
                  {{ item.meta }}
                </span>
              </div>
            </div>
          </b-nav-item>
          <b-nav-item
            v-if="item.shift"
            :key="item.title"
            @click="showShiftModal()"
          >
            <div class="d-flex align-items-center">
              <div class="sidebar-icon">
                <svg :class="'icon icon-icon-'+ item.icon">
                  <use :xlink:href="'#icon-icon-'+ item.icon" />
                </svg>
              </div>

              <div class="sidebar-item">
                <span class="sidebar-text">
                  {{ item.text }}
                </span>
                <span class="sidebar-meta">
                  {{ item.meta }}
                </span>
              </div>
            </div>
          </b-nav-item>
        </template>
      </b-navbar-nav>
    </div>

    <div
      v-if="terminalMode!=='KIOSK_MODE'"
      class="sidebar-mask"
    >
      <button
        :aria-pressed="sidebarShow ? 'true' : 'false'"
        class="navbar-toggle"
        @click="sidebarShow = !sidebarShow"
      />
    </div>

    <div
      id="content"
      :class="{'kiosk-content': isKioskMode}"
      class="main-container h-100"
    >
      <b-navbar
        v-if="!isKioskMode"
        toggleable="xs"
        type="dark"
        variant="primary"
        class="main-navbar"
        fixed="top"
      >
        <b-container
          fluid
          class="d-block px-0"
        >
          <b-row class="align-items-center no-gutters">
            <b-col
              cols="12"
              md="4"
              lg="4"
              order="1"
              order-md="1"
            >
              <div class="navbar-top d-flex align-items-center justify-content-md-between">
                <button
                  class="navbar-toggle"
                  @click="sidebarToggle()"
                >
                  <svg class="icon icon-icon-menu">
                    <use xlink:href="#icon-icon-menu" />
                  </svg>
                </button>

                <b-navbar-brand
                  :to="(!isSavingProcess || true)?($route.name === 'Order' && terminalMode === 'QUICK_SERVICE') ? {} : terminalMode !== 'QUICK_SERVICE'?{ name: 'Tables'}:{name: 'Order', params: { tableID: null, id: 'new'}}:null"
                >
                  <img
                    :class="{'loading': isSavingProcess && false}"
                    class="logo"
                    src="../assets/images/logo.svg"
                    alt=""
                  >
                </b-navbar-brand>

                <div class="d-none d-md-flex align-items-center justify-content-between ml-auto">
                  <div class="navbar-status time-status">
                    <!-- <digital-clock :blink="false"/> -->
                    <span>{{ timestamp }}</span>
                  </div>
                </div>
              </div>
            </b-col>

            <b-col
              cols="12"
              md="4"
              lg="4"
              order="3"
              order-md="2"
              class="d-none d-md-block"
            >
              <slot name="navbar" />
            </b-col>

            <b-col
              cols="6"
              md="4"
              lg="4"
              order="2"
              order-md="3"
              class="d-none d-md-block"
            >
              <div class="d-flex align-items-center justify-content-between py-2 py-lg-0 pr-3">
                <slot name="navbar-button" />

                <div class="user-menu ml-auto d-flex align-items-center justify-content-end">
                  <div class="user-menu-pic" />

                  <div
                    v-if="employee"
                    class="user-menu-name"
                  >
                    {{ employee.name }}
                  </div>
                  <!-- temporary disabled -->
                  <!-- <customer-display-comm @channel="$emit('orderChannel', $event)" /> -->

                  <a
                    href="#"
                    class="user-menu-btn"
                    @click="lock"
                  >
                    <svg class="icon icon-icon-lock">
                      <use xlink:href="#icon-icon-lock" />
                    </svg>
                  </a>
                </div>

                <div class="fullscreen-toggle pl-3">
                  <a
                    href="#"
                    @click="toggle"
                  >
                    <span
                      v-if="!fullscreen"
                      class="oi oi-fullscreen-enter fullscreen-btn"
                    >
                      <svg class="icon icon-icon-resize">
                        <use xlink:href="#icon-icon-resize" />
                      </svg>
                    </span>
                    <span
                      v-if="fullscreen"
                      class="oi oi-fullscreen-exit fullscreen-btn"
                    >
                      <svg class="icon icon-icon-unresize">
                        <use xlink:href="#icon-icon-unresize" />
                      </svg>
                    </span>
                  </a>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-navbar>

      <slot />


      <b-modal
        ref="modal-open-shift"
        :body-bg-variant="bodyBgVariant"
        :modal-class="popupPrice+' '+popupPriceSidebar"
        no-close-on-backdrop
        hide-footer
      >
        <template
          slot="modal-header"
          slot-scope="{ close }"
        >
          <b-button
            variant="outline-light"
            @click="close()"
          >
            {{ $t("shiftviewpopup.cancel") }}
          </b-button>
          <h5>
            {{ $t("shiftviewpopup.open-shift") }}
          </h5>
          <b-button
            variant="primary"
          >
            {{ $t("shiftviewpopup.open-shift") }}
          </b-button>
        </template>

        <div class="modal-top text-center">
          <p>
            {{ $t("shiftviewpopup.a-shift-has-not-yet-been-opened") }}
          </p>
          <p>
            {{ $t("shiftviewpopup.enter-starting-cash-amount") }}
          </p>
        </div>

        <div class="pin-win">
          <span>$525.00</span>
        </div>

        <div class="pin-keyboard text-center my-0">
          <ul class="pin-keyboard-list">
            <li>
              <span
                class="pin-key"
                @click="typeCode(1)"
              >1</span>
            </li>
            <li>
              <span
                class="pin-key"
                @click="typeCode(2)"
              >2</span>
            </li>
            <li>
              <span
                class="pin-key"
                @click="typeCode(3)"
              >3</span>
            </li>

            <li>
              <span
                class="pin-key"
                @click="typeCode(4)"
              >4</span>
            </li>
            <li>
              <span
                class="pin-key"
                @click="typeCode(5)"
              >5</span>
            </li>
            <li>
              <span
                class="pin-key"
                @click="typeCode(6)"
              >6</span>
            </li>

            <li>
              <span
                class="pin-key"
                @click="typeCode(7)"
              >7</span>
            </li>
            <li>
              <span
                class="pin-key"
                @click="typeCode(8)"
              >8</span>
            </li>
            <li>
              <span
                class="pin-key"
                @click="typeCode(9)"
              >9</span>
            </li>

            <li><span class="pin-key pin-key_call">C</span></li>

            <li>
              <span
                class="pin-key"
                @click="typePin(0)"
              >0</span>
            </li>

            <li>
              <span
                class="pin-key pin-key_call"
                @click="typePin(0)"
              >
                <svg class="icon icon-icon-pin-arrow">
                  <use xlink:href="#icon-icon-pin-arrow" />
                </svg>
              </span>
            </li>
          </ul>
        </div>
      </b-modal>

      <b-modal
        id="modal-open-sync"
        hide-footer
        hide-header
        modal-class="success-popup"
        ok-only
        no-stacking
      >
        <div class="text-center modal-complete">
          <div class="success-popup-icon success-popup-icon_success">
            <svg class="icon icon-icon-popup-sync">
              <use xlink:href="#icon-icon-popup-sync" />
            </svg>
          </div>

          <h2>
            {{ $t("syncdatapopup.sync-data") }}
          </h2>

          <div class="popup-meta">
            <p>
              {{ $t("syncdatapopup.last-changes-on") }}
            </p>
            <p>
              {{ $t("syncdatapopup.synchronize-new-data") }}
            </p>
          </div>

          <div class="d-flex justify-content-center align-items-center">
            <b-button
              v-b-modal.modal-sync-item-2
              variant="primary"
              class="mr-2"
            >
              {{ $t("syncdatapopup.sync") }}
            </b-button>
            <b-button
              variant="outline-primary"
              @click="$bvModal.hide('modal-sync-item')"
            >
              {{ $t("syncdatapopup.cancel") }}
            </b-button>
          </div>
        </div>
      </b-modal>


      <b-modal
        id="modal-open-sync-progress"
        hide-footer
        hide-header
        modal-class="success-popup"
        ok-only
        no-stacking
      >
        <div class="text-center modal-complete">
          <div class="success-popup-icon success-popup-icon_success">
            <svg class="icon icon-icon-popup-sync">
              <use xlink:href="#icon-icon-popup-sync" />
            </svg>
          </div>

          <h2>
            {{ $t("syncprogressdialog.syncing") }}
          </h2>

          <b-progress
            :max="max"
            :striped="false"
            :animated="true"
            height="10px"
            show-progress
          >
            <b-progress-bar
              :value="value"
              variant="success"
            >
              <h5 v-if="value > 0">
                <svg class="icon icon-icon-popup-sync">
                  <use xlink:href="#icon-icon-popup-sync" />
                </svg>
              </h5>
            </b-progress-bar>
          </b-progress>

          <div class="d-flex justify-content-center align-items-center">
            <b-button
              variant="outline-success w-100"
              @click="$bvModal.hide('modal-sync-item-2')"
            >
              {{ $t("syncprogressdialog.cancel") }}
            </b-button>
          </div>
        </div>
      </b-modal>
      <ModalError
        :id="error.modalId"
        :error="error.errorBody"
        :cancel-callback="error.cancelCallback"
      />
    </div>
  </div>
</template>
<script>
import store from "../store/store";
import ModalError from "../modals/ModalError";
import { ORDER_STATUS } from "../helpers/order/status";
import orderMixin from "../mixins/order/orderMixin";
import { mapGetters, mapActions } from "vuex";
import moment from 'moment';
import customerDisplayMixin from "@/mixins/customerDisplayMixin";
export default {
  name: "PosLayout",
  components: {
    ModalError
  },
  mixins:[orderMixin, customerDisplayMixin],
  data () {
    return {
      fullscreen: false,
      sidebarShow: false,
      timer: 0,
      value: 0,
      max: 180,
      modalShow: false,
      bodyBgVariant: "light",
      popupPrice: ["popup-price"],
      popupPriceSidebar: ["popup-price-sidebar"],
      toastOptions: {
        position: "bottom-right",
        icon: "cloud_off"
      },
      sidebarOriginalItems: [],
      timestamp: moment().format("h:mm A")
    };
  },
  computed: {
    ...mapGetters(['isSyncInProgress']),
    ...mapGetters({
      isActiveOrder: 'orders/isActiveOrder',
      enableOnlineOrders: 'config/enableOnlineOrders',
      baseUrl: "config/baseUrl",
      store:"config/store"
    }),
    error () {
      return this.$store.getters["errorModal/error"];
    },
    employee () {
      return this.$store.getters["employee"];
    },
    terminal () {
      return this.$store.getters["config/terminal"];
    },
    terminalMode () {
      return this.$store.getters.terminalMode;
    },
    isKioskMode () {
      return this.$store.getters['config/isKioskMode'];
    },
    isSavingProcess () {
      return this.$store.getters.isSavingProcess;
    },
    connection () {
      return this.$store.getters.connection;
    },
    sidebarItems() {
      let filteredSidebarItems = []
      if (this.terminalMode === "QUICK_SERVICE" && this.enableOnlineOrders === 'true') {
        filteredSidebarItems = this.sidebarOriginalItems
          .filter(item => item.title !== "Tables")
          .map(item => item.title === "Online Orders" ? { ...item, ...{ badgeCounter: this.pendingCount } } : item)
      }
      else if (this.terminalMode === "QUICK_SERVICE" && this.enableOnlineOrders === 'false') {
        filteredSidebarItems = this.sidebarOriginalItems
          .filter(item => item.title !== "Tables" && item.title !== "Online Orders")
      }
      else if (this.terminalMode !== "QUICK_SERVICE" && this.enableOnlineOrders === 'true') {
        filteredSidebarItems = this.sidebarOriginalItems
          .map(item => item.title === "Online Orders" ? { ...item, ...{ badgeCounter: this.pendingCount } } : item)
      }
      else if (this.terminalMode !== "QUICK_SERVICE" && this.enableOnlineOrders === 'false') { 
        filteredSidebarItems = this.sidebarOriginalItems
          .filter(item => item.title !== "Online Orders")
      }
      return filteredSidebarItems;
    },
    pendingCount () {
      return this.$store.getters['orders/getOrdersByStatus'](ORDER_STATUS.PENDING).filter(o => o.status === ORDER_STATUS.PENDING && this.isToday(new Date(o.open_date)) ).length;
    }
  },
  watch: {
    fullscreen (val) {
      if (val) {
        document.documentElement.requestFullscreen();
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    },
    error (err) {
      this.$nextTick(() => {
        this.$bvModal.show(err.modalId);
      });
    },
    isSyncInProgress:{
      handler(val) {
        this.sidebarOriginalItems.forEach(sideBarItem => { 
            if (sideBarItem.title === 'Synchronize') { 
              sideBarItem.disabled = val
            }
          })
       },
      immediate:true
    },
    connection: {
      handler () {
        this.initialSideBarItems()
      },
      immediate: true
    },
    connectionState: {
      handler (val) {
        // if webrtc is disconnected then generate a new offer and connect again
        if (val === 'disconnected') {
          this.getConnectionKey()
        }
      }
    }
  },
  methods: {
    ...mapActions({
      fetchCurrentShift:'fetchCurrentShift',
      fetchOnlineOrders:'orders/fetchOnlineOrders'
    }),
    showShiftModal () {

      if (this.sidebarShow) {
        this.sidebarClose();
      } else {
        this.sidebarOpen();
      }
      this.$refs["modal-open-shift"].show();
    },
    sidebarOpen () {
      this.sidebarShow = true;
    },
    sidebarClose () {
      this.sidebarShow = false;
    },
    initialSideBarItems () {
      this.sidebarOriginalItems = [
        {
          title: "Sales",
          path: "/orders/new",
          icon: "dashboard-12",
          text: this.$t("sidebar.sales"),
          meta: this.$t("sidebar.sales-desc"),
          disabled: false
        },
        {
          title: "Dashboard",
          icon: "dashboard-1",
          text: this.$t("sidebar.dashboard"),
          meta: this.$t("sidebar.dashboard-desc"),
          disabled: false
        },
        {
          title: "Tables",
          path: "/tables",
          icon: "dashboard-3",
          text: this.$t("sidebar.tables"),
          meta: this.$t("sidebar.tables-desc"),
          disabled: false
        },
        {
          title: "Orders",
          path: "/orders",
          icon: "dashboard-5",
          text: this.$t("sidebar.orders"),
          meta: this.$t("sidebar.orders-desc"),
          disabled: false
        },
        {
          title: 'Online Orders',
          path: '/online-orders',
          icon: 'dashboard-10',
          text: this.$t('sidebar.onlineorders'),
          meta: this.$t('sidebar.orders-desc'),
          disabled: false
        },
        {
          title: "Shift",
          path: "/shift",
          icon: "dashboard-6",
          text: this.$t("sidebar.shift"),
          meta: this.$t("sidebar.shift-desc"),
          disabled: false
          // shift: true
        },
        {
          title: "Synchronize",
          path: "/synchronize",
          icon: "dashboard-2",
          text: this.$t("sidebar.sync"),
          meta: this.$t("sidebar.sync-desc"),
          disabled: !this.connection,
        },
        {
          title: "Settings",
          path: "/settings",
          icon: "dashboard-11",
          text: this.$t("sidebar.settings"),
          meta: this.$t("sidebar.settings-desc"),
          disabled: false
        }
    ];
    },
    async sidebarToggle (path =null) {
      if (this.sidebarShow) {
        if (path) {
          // checking if the pos window does not have any open orders
          if (this.isActiveOrder && this.terminalMode === "QUICK_SERVICE" && path !== '/tables')   {
            this.$bvModal.show('leaveCurrentOrderModal')
          }
          else {
            if (path === "/shift") { 
              this.fetchCurrentShift()
            }
            this.$router.push({ path: path })
          }
        }
        this.sidebarClose();
      } else {
        this.sidebarOpen();
      }
    },
    toggle () {
      this.fullscreen = !this.fullscreen;
    },
    isToday(date){
    const today = new Date();
    return date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
    },
    lock () {
      // checking if the pos window does not have any open orders
      if (this.terminalMode === "QUICK_SERVICE" && this.isActiveOrder ) {
        this.$bvModal.show('leaveCurrentOrderModal')
      }
      else {
        store.commit("lock");
      }
    },
  },
  mounted() {
    this.initialSideBarItems()
    // getting dashboard url frpm the localstorage
    const baseUrl = localStorage.getItem('baseUrl');
    if (baseUrl) {
      const dashboard = this.sidebarOriginalItems.find(sidebarItem => sidebarItem.title === 'Dashboard')
      const dashboardIndex = this.sidebarOriginalItems.findIndex(sidebarItem => sidebarItem.title === 'Dashboard')
      dashboard.href = baseUrl
      // updating the sidebarOriginalItems array through spread operator
      this.sidebarOriginalItems[dashboardIndex] = {...dashboard}
    }
  },
  created() {
  // updating time on pos
    setInterval(() => {
      this.timestamp = moment().format("h:mm A")
    }, 1000)
  }
};
</script>
<style lang="scss">
.loading {
  cursor: wait;
  /*background: black;*/
}
.disabled{
  opacity:0.5;
  cursor:default;
}
</style>

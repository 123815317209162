<template>
  <div>
    <EnterPinModal
      :modal-id="'enter-pin-modal--order-price'"
      @verify-action="verifyAction"
    />
    <!-- Modal Price Adjust -->
    <b-modal
      :id="id"
      no-close-on-backdrop
      hide-footer
      modal-class="popup-price popup-price-btn"
      @show="resetModal"
      @hidden="resetModal"
      no-fade
    >
      <template
        slot="modal-header"
        slot-scope="{ close }"
      >
        <b-button
          variant="outline-light"
          @click="close()"
        >
          {{ $t("adjustpricepopup.cancel") }}
        </b-button>
        <h5 v-if="id !== 'gift-card-modal'">
          {{ $t("adjustpricepopup.price-adjust") }}
        </h5>
        <h5 v-else>
          <strong>
            {{ $t("adjustpricepopup.price-adjust") }}
          </strong>
        </h5>
      </template>

      <div
        v-if="id === 'gift-card-modal'"
        class="mx-auto text-center"
      >
        <p class="mb-0">
          Add funds to selected gift card
        </p>
        <p class="mt-0">
          <strong class="mt-0">{{ giftCard ? giftCard.gift_card_number : '' }}</strong>
        </p>
      </div>
      <div class="pin-win">
        <div class="pin-win-inner">
          {{ currency }} {{ newPrice !== null ? newPrice.toFixed(2) : "0.00" }}
        </div>

        <button
          v-if="hasTax"
          :class="{ 'btn-light': !isTaxed, 'btn-primary': isTaxed }"
          class="btn"
          @click="taxedToggle()"
        >
          <div
            v-show="!isTaxed"
          >
            {{ $t("adjustpricepopup.not-taxed") }}
          </div>
          <div
            v-show="isTaxed"
          >
            {{ $t("adjustpricepopup.taxed") }}
          </div>
        </button>
      </div>
      <SimpleNumKeyboard
        :withconfirm="true"
        @updated="setNewPrice"
        @confirm="updatePrice"
      />
    </b-modal>
  </div>
</template>

<script>
import EnterPinModal from "./EnterPinModal";
import { EMPLOYEE_ROLE } from "../helpers/employee/role";
import SimpleNumKeyboard from "../components/SimpleNumKeyboard";
import { mapGetters } from "vuex";

export default {
  name: "ModalPriceAdjust",
  components: {
    SimpleNumKeyboard,
    EnterPinModal
  },
  props:{
    id:{
      type:String,
      default:'modal-price-adjust'
    },
    // giftCardPaymentsAmount:{
    //   type:Number,
    //   default:0
    // }
    // giftCardNumber:{
    //   type:String,
    //   default:'112-233-455-556'
    // }
  },
  data () {
    return {
      newPrice: 0,
      newItemTax: 0,
      isTaxed: false,
      hasTax: false,
      currentTax: 0,
      isActionVerified: false
    };
  },
  computed: {
    ...mapGetters({
      employee: "employee",
      currency: "config/currency",
      giftCard:"giftVoucher/activeGiftCard"
    })
  },
  watch: {
    employee (employee) {
      if (!this.isKioskMode) {
        if (this.product["price_adjust"]) return this.isActionVerified = true;
        if ((!employee || employee.role === EMPLOYEE_ROLE.Cashier) && !this.product["price_adjust"])
          return this.isActionVerified = false;
        return this.isActionVerified = true;
      }
      return {};
    }
  },
  mounted: function () {
    console.log("[ModalPriceAdjust] created");
  },
  methods: {
    resetModal () {
      if(this.id !== 'gift-card-modal'){
      this.item = this.$store.getters["itemPriceAdjust/item"];
      this.product = this.$store.getters["config/product"](this.item.product);
      this.newPrice = this.item.price;
      this.newItemTax = this.item.tax;
      this.isTaxed = this.item.tax_status === 0;
      this.hasTax = this.product["tax_rates"].length > 0;
    }
    },
    verifyAction (employee) {
      if (!employee) return this.isActionVerified = false;
      this.isActionVerified = true;
      this.$bvModal.hide("enter-pin-modal--order-price");
      return this.updatePrice();
    },
    handleOk (bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$bvModal.hide("modal-price-adjust");
    },
    setNewPrice (newPrice) {
      this.newPrice = newPrice !== "" ? parseFloat(newPrice) / 100 : 0;
    },
    taxedToggle () {
      this.isTaxed = !this.isTaxed;
    },
    updatePrice () {
      if (!this.isKioskMode && this.employee.role === EMPLOYEE_ROLE.Cashier && !this.isActionVerified && !this.product["price_adjust"])
        return this.$bvModal.show("enter-pin-modal--order-price");
      if(this.id !== 'gift-card-modal' ){
      this.$emit("savePrice", {
        price: this.newPrice,
        taxed: this.isTaxed,
        product: this.product,
      });
      this.isActionVerified = false;
      this.$bvModal.hide("modal-price-adjust");
    }
    else{
      console.log(this.giftCard.credit,this.giftCardPaymentsAmount)
      // if((this.giftCard.credit-this.giftCardPaymentsAmount) >= this.newPrice){
      // this.$emit("saveGiftCard", {
      //   amount_paid: this.newPrice,
      // });
      this.$emit("saveGiftCard", {
        gift_card_new_credit: this.newPrice,
      });
      this.isActionVerified = false;
      this.newPrice = 0
      this.$bvModal.hide(this.id);
    }
    // else{
    //   this.$store.dispatch("errorModal/displayModalError", {
    //         modalId: "order-error",
    //         errorBody: {
    //           title: "Value error",
    //           info: `Price requested is greater than gift card balance`
    //         }
    //       });
    // }
    // }
    }
  }
};
</script>

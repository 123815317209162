<template>
  <div>
    <b-modal
      :centered="true"
      :hide-header="true"
      size="lg"
      modal-class="success-popup leaving-order-modal"
      footer-class="d-flex justify-content-center align-items-center"
      id="leaveCurrentOrderModal"
    >
      <div class="content mb-5">
        <svg class="icon icon-icon-popup-wrong">
          <use xlink:href="#icon-icon-popup-wrong--circle" />
        </svg>
        <h2
          class="mt-5 mb-3"
          style="font-size: 2.2rem;"
        >
          Leaving this order?
        </h2>
        <span class="popup-meta">Please select an action below to proceed before leaving</span>
      </div>
      <template #modal-footer>
        <div>
          <button 
            v-b-modal.modal-close_visit 
            class="btn btn-outline-primary btn-sm m-1 px-2"
            @click="cancelOrder()"
          >
            Cancel the Order
          </button>
          <button 
            v-b-modal.modal-close_visit 
            class="btn btn-primary btn-sm m-1 px-2"
            @click="showHoldOrderModal"
          >
            Hold the Order
          </button>
        </div>
      </template>
    </b-modal>
    <HoldOrder @hold-order="holdOrder" />
  </div>
</template>
<script>
import HoldOrder from './MoreOptions/HoldOrder.vue'
import orderMixin from '../../mixins/order/orderMixin'
export default {
  components: {
    HoldOrder
  },
  mixins:[orderMixin],
  data: () => ({
    openModal:true
  }),
  methods: {
    showHoldOrderModal() {
      this.$bvModal.show('hold-order-modal')
    },
    holdOrder(payload) { 
      this.$bvModal.hide('leaveCurrentOrderModal')
      this.$emit('hold-order',payload)
    },
    cancelOrder() {
      this.$bvModal.hide('leaveCurrentOrderModal')
      this.$emit('cancel-order')
    }
  }
}
</script>
<style>
.leaving-order-modal .modal-dialog {
  max-width: 435px !important;
}
.leaving-order-modal .btn {
  text-transform: inherit ;
}
</style>
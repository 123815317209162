export const itemNote = {
  namespaced: true,
  state: {
    index: null,
    notes: ''
  },
  mutations: {
    setIndex: (state, payload) => {
      state.index = payload;
    },
    setNotes: (state, payload) => {
      state.notes = payload;
    }
  },
  getters: {
    index: (state) => {
      return state.index
    },
    notes: (state) => {
      return state.notes
    }
  }
};

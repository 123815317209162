<template class="enter-pin--wrapper">
  <div class="enter-pin--wrapper">
    <b-modal
      :id="modalId"
      no-close-on-backdrop
      hide-footer
      style="height: 90vh"
      no-fade
    >
      <template
        slot="modal-header"
        slot-scope="{ close }"
        style="justify-content: center"
      >
        <b-button
          variant="outline-light"
          style="min-width: 84px;position: absolute;left: 16px;top: 50%;margin-top: -20px;"
          @click="close()"
        >
          {{ $t('customerlistpopup.cancel') }}
        </b-button>
        <h5>
          Enter PIN
        </h5>
      </template>

      <div class="content text-center">
        <PinBlockComponent
          :clear-form-trigger="clearFormTrigger"
          :pin-msg="$t('pin-modal.enter-pin')"
          :wrong-pin-msg="$t('pin-modal.wrong-pin')"
          @get-employee="checkEmployee"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
  import PinBlockComponent from "../views/particle/PinBlockComponent";
  import {EMPLOYEE_ROLE} from "../helpers/employee/role";

  export default {
    name: "EnterPinModal",
    components: {
      PinBlockComponent
    },
    props: {
      'modalId': {
        type: String,
        required: false,
        default: 'enter-pin-modal'
      },
      'customCloseAction': {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data () {
      return {
        clearFormTrigger: false,
        title: this.$t('pinscreen.enter-pin-to-login')
      }
    },
    mounted() {
      const self = this;
      this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
        if (self.customCloseAction && modalId === self.modalId)
          self.$emit('close-enter-pin-modal', true)
      })
    },
    methods: {
      checkEmployee(employee) {
        if (!employee || (employee.role && (employee.role !== EMPLOYEE_ROLE.Manager && employee.role !== EMPLOYEE_ROLE.Owner))) {
          this.clearFormTrigger = true;
          return this.$emit('verify-action', false);
        }

        return this.$emit('verify-action', employee);
      }
    }
  }
</script>

<style lang="scss">
  .modal-body {
    /*background-color: white !important;*/
  }
  #enter-pin-modal,#enter-pin-modal--order-price,#enter-pin-modal--order-discount {
    .modal-header {
      justify-content: center;
    }
    .pin-dots-list li {
      background: #eeeeee;
      border-color: #2196F3 !important;
    }
    .pin-dots-list li.filled {
      background: #2196F3 !important;
    }
    .pin-dots-list {
      padding-top: 0;
    }
  }

</style>

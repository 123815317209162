<template>
  <pos-layout>
    <div>
      <b-container class="text-left pt-4">
        <h3>Terminal Tools</h3>
        <b-card title="Export/Import">
          <b-card-text>
            Functionality allows to export or import local database
          </b-card-text>

          <b-button
            variant="primary"
            @click="exportLocalDb"
          >
            Export
          </b-button>
          <b-button
            variant="primary"
            disabled
          >
            Import
            <b-badge
              pill
              variant="warning"
            >
              SOON
            </b-badge>
          </b-button>
        </b-card>
        <b-card title="Sync">
          <b-card-text>
            Sync terminal with dashboard
          </b-card-text>
          <b-button
            :to="{name: 'Synchronize'}"
            variant="primary"
          >
            Start Sync
          </b-button>
        </b-card>
        <b-card
          :to="{name: 'Reset'}"
          title="Reset"
        >
          <b-card-text>Reset dashboard settings</b-card-text>

          <b-button
            :to="{name: 'Reset'}"
            variant="primary"
          >
            Reset
          </b-button>
        </b-card>
      </b-container>
    </div>
  </pos-layout>
</template>

<script>
import PosLayout from '../layouts/PosLayout';
import indexDbService from "../helpers/dbHelper"
import * as Sentry from "@sentry/vue";

export default {
  name: 'Tools',
  components: {
    PosLayout
  },
  data() {
    return {};
  },
  methods: {
    async exportLocalDb() {
      console.log('exportOrders');
      try {
        // TODO
        // const res = await exportToJson();
        const orders = await indexDbService.getDbData('orders');
        let dataToSave = {}
        try {
          dataToSave = orders
        } catch (e){
          Sentry.captureException(e);
          console.error("could not retrieve orders from storage");
        }
        this.saveFile(dataToSave);
      } catch (e) {
        Sentry.captureException(e);
        console.error(e)
        await this.$store.dispatch('errorModal/displayModalError', {
          modalId: 'print-error',
          errorBody: {
            title: 'Error',
            info: 'Something went wrong during export! Please, contact administrator'
          }
        });
      }

    },
    saveFile(res) {
      console.log('saveFile start');
      const data = JSON.stringify(res);
      const blob = new Blob([data], { type: 'text/plain;charset=utf-8' });
      const FileSaver = require('file-saver');
      FileSaver.saveAs(blob, 'terminalOrdersDump.json');
    }
  }
};
</script>

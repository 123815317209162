<template>
  <pos-layout>
    <div class="h-100 orders">
      <b-row class="h-100 no-gutters">
        <b-col
          :class="{hide: sidebarMob}"
          col
          cols="12"
          md="5"
          lg="4"
          class="h-100 orders-sidebar-mob"
        >
          <div class="orders-sidebar">
            <div class="orders-head">
              <div class="orders-top">
                <div class="d-flex align-items-center">
                  <div class="orders-top-num">
                    <span>
                      {{ $t("amountandpaymentmethod.order-no") }}
                    </span>
                    <strong>
                      # {{ displayOrderId }}{{ currentOrder.dining_option ? " - " + currentOrder.dining_option : "" }}
                    </strong>
                  </div>
                  <div class="orders-top-btn ml-auto d-flex align-items-center">
                    <b-button
                      v-if="currentOrder.status === ORDER_STATUS.COMPLETED"
                      variant="secondary"
                      :disabled="currentOrder.isFullyRefund || !currentOrder.id"
                      @click="showRefundModal"
                    >
                      {{ currentOrder.isFullyRefund ? "Fully Refunded" : $t("orderscreenv1.refund") }}
                    </b-button>
                    <b-button
                      v-if="currentOrder.status === ORDER_STATUS.OPEN || currentOrder.status === ORDER_STATUS.HOLD"
                      :to="{ name: 'Order', params: { id: currentOrder.id }}"
                      variant="secondary"
                    >
                      {{ $t("orderscreen.edit") }}
                    </b-button>
                  </div>
                </div>
              </div>
              <div class="orders-add">
                <span v-if="!currentOrder.customer">{{ $t("orderscreen.no-customer-selected") }}</span>
                <span v-else>
                  {{ currentOrder.customer.first_name }} {{ currentOrder.customer.last_name }}
                </span>
              </div>
            </div>

            <div class="orders-sidebar-body">
              <div 
                class="orders-list" 
                role="tablist"
              >
                <!-- <transition-group name="list-complete"> -->
                <b-card
                  v-for="(item, index) in itemsAtCurrentOrder"
                  :key="'item_'+index"
                  no-body
                  class="list-complete-item"
                >
                  <b-card-header
                    header-tag="header"
                    class="orders-item-head p-0"
                    role="tab"
                  >
                    <b-button
                      v-b-toggle="'accordion-'+index"
                      block
                      href="#"
                    >
                      <div class="d-flex">
                        <div class="btn-num">
                          {{ item.quantity }}
                        </div>

                        <div class="btn-body">
                          <div class="d-flex align-items-center justify-content-between top text-nowrap">
                            <div class="text-left d-flex">
                              {{ item.name }}
                              <svg 
                                v-if="item.notes && item.notes.length" 
                                class="icon icon-icon-note"
                              >
                                <use xlink:href="#icon-icon-note" />
                              </svg>
                              <div 
                                v-if="item.isPartialRefund"
                                class="icon-dot ml-2 my-auto"
                              />								
                            </div>
                            <div class="text-right">
                              <span
                                v-if="item.hasOwnProperty('tax_rates') && item.tax_rates.length > 0"
                                class="short-desc"
                              >{{ $t("listvieworderdelegate.taxed") }}</span>
                              {{ currency }} {{ getItemGrandTotal(item).toFixed(2) }}
                            </div>
                          </div>
                          <div class="d-flex align-items-center justify-content-between sub text-nowrap">
                            <div class="text-left">
                              {{ calculateDiscount(item.cost, item.discount) }} %
                            </div>
                            <div class="text-right">
                              {{ currency }} {{ item.discount ? (item.discount * item.quantity).toFixed(2) : "0.00" }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-button>
                  </b-card-header>

                  <b-collapse
                    :id="'accordion-'+index"
                    visible
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <table class="table">
                        <tr>
                          <td>
                            <span>
                              {{ $t("listvieworderdelegate.qty") }}
                            </span>
                            <strong class="text-primary">
                              {{ item.quantity }}
                            </strong>
                          </td>
                          <td>
                            <span>
                              {{ $t("listvieworderdelegate.price") }}
                            </span>
                            <strong class="text-primary">
                              {{ currency }}{{ item.price ? item.price.toFixed(2) : "0.00" }}
                            </strong>
                          </td>
                          <td>
                            <span>
                              {{ $t("listvieworderdelegate.discount") }}
                            </span>
                            <strong>
                              {{ currency }} {{ item.discount }}
                            </strong>
                          </td>
                        </tr>
                      </table>
                      <div 
                        v-if="item.notes && item.notes.length" 
                        class="card-notes"
                      >
                        {{ item.notes }}
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <!-- </transition-group> -->
              </div>
            </div>

            <div class="orders-bottom">
              <div v-if="!displayPaymentDetailsList">
                <div class="orders-bottom-item">
                  <span class="text-left">
                    {{ $t("ordertotal.total") }}
                  </span>
                  <span class="text-right">
                    {{ currency }} {{ currentOrder.grand_total ? currentOrder.grand_total.toFixed(2) : "0.00" }}
                    <b-button
                      variant="link"
                      class="w-100"
                      @click="isDisplayPaymentDetailsList"
                    >
                      {{ $t("ordertotal.view-details") }}
                    </b-button>
                  </span>
                </div>
              </div>

              <div v-else>
                <div class="orders-bottom-item">
                  <b-button
                    variant="link"
                    class="w-100 text-center"
                    @click="isDisplayPaymentDetailsList"
                  >
                    {{ $t("ordertotal.close-details") }}
                  </b-button>
                </div>
                <div class="orders-bottom-item">
                  <span class="text-left">
                    {{ $t("ordertotal.discount") }}
                  </span>
                  <span class="text-right">
                    {{ currency }} {{ currentOrder.discount_total ? currentOrder.discount_total.toFixed(2) : "0" }}
                  </span>
                </div>
                <div class="orders-bottom-item">
                  <span class="text-left">
                    {{ $t("ordertotal.sub-total") }}
                  </span>
                  <span class="text-right">
                    {{ currency }} {{ currentOrder.subtotal.toFixed(2) }}
                  </span>
                </div>

                <div class="orders-bottom-item">
                  <span class="text-left">
                    {{ $t("ordertotal.tax") }}
                  </span>
                  <span class="text-right">
                    {{ currency }} {{ toFixedNoRounding(currentOrder.tax_total, 2) }}
                  </span>
                </div>

                <div
                  class="orders-bottom-item"
                  v-if="currentOrder.tips_total"
                >
                  <span class="text-left">
                    {{ $t("ordertotal.tip") }}
                  </span>
                  <span class="text-right">
                    {{ currency }} {{ currentOrder.tips_total.toFixed(2) }}
                  </span>
                </div>

                <div class="orders-bottom-item">
                  <span class="text-left">
                    {{ $t("ordertotal.total") }}
                  </span>
                  <span class="text-right">
                    {{ currency }} {{ currentOrder.grand_total ? currentOrder.grand_total.toFixed(2) : "0.00" }}
                  </span>
                </div>
              </div>

              <div
                v-if="!(currentOrder.status === ORDER_STATUS.HOLD || currentOrder.status === ORDER_STATUS.CANCELLED)"
              >
                <div
                  v-for="(item, index) in currentOrder.payments"
                  :key="index"
                  class="orders-bottom-item"
                >
                  <span class="text-left">
                    {{ item.amount > 0 ? $t("ordertotal.paid") : "Refunded" }} - {{ item.payment_type }}
                  </span>
                  <span class="text-right">
                    {{ currency }} {{ item.amount.toFixed(2) }}
                    <span :style="{color: 'red'}">
                      {{ `(${item.change_amount.toFixed(2)})` }}
                    </span>
                  </span>
                </div>
              </div>
              <div class="orders-bottom-options">
                <div class="order-sum">
                  <div class="d-flex align-items-center justify-content-between">
                    <span class="text-left">
                      {{ $t("ordertotal.balance") }}
                    </span>
                    <span class="text-right">
                      {{
                        currency
                      }} {{ currentOrder.balance_remaining ? currentOrder.balance_remaining.toFixed(2) : "0.00" }}
                    </span>
                  </div>
                </div>
              </div>

              <!-- Print panel For desktop -->
              <div class="print d-none d-md-block">
                <div class="d-flex h-100 no-gutters">
                  <div class="h-100 w-100">
                    <b-button
                      :disabled="!currentOrder.status"
                      variant="link"
                      class="w-100"
                      @click="printCheck()"
                    >
                      <svg class="icon icon-icon-print-1">
                        <use xlink:href="#icon-icon-print-1" />
                      </svg>

                      <span>
                        {{ $t("amountandpaymentmethod.print-check") }}
                      </span>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>

        <b-col 
          col 
          cols="12" 
          md="7" 
          lg="8" 
          class="h-100 orders-col"
        >
          <div 
            :id="!isMobile && 'infinite-list'" 
            class="products-wrap h-100 pt-0"
          >
            <div 
              :id="isMobile && 'infinite-list'" 
              class="section-body"
            >
              <div class="filter">
                <div class="filter-panel row g-1">
                  <div class="col-3 position-relative">
                    <span
                      class="badge badge-danger"
                      v-if="countPending>0"
                    >{{ countPending }}</span>
                    <b-button
                      class="w-100"
                      :class="{'active': statusFilter==ORDER_STATUS.PENDING}"
                      @click="statusFilter=ORDER_STATUS.PENDING"
                    >
                      <span>Pending</span>
                    </b-button>
                  </div>
                  <div class="col-3 position-relative">
                    <b-button
                      :class="{'active': statusFilter==ORDER_STATUS.OPEN}"
                      @click="statusFilter=ORDER_STATUS.OPEN"
                      class="w-100"
                    >
                      <span>Payment Pending</span>
                    </b-button>
                  </div>
                  <div class="col-3 position-relative">
                    <b-button
                      :class="{'active': statusFilter==ORDER_STATUS.COMPLETED}"
                      @click="statusFilter=ORDER_STATUS.COMPLETED"
                      class="w-100"
                    >
                      <span>{{ $t("vieworderspopup.completed") }}</span>
                    </b-button>
                  </div>
                  <div class="col-3 position-relative">
                    <b-button
                      :class="{'active': statusFilter==ORDER_STATUS.CANCELLED}"
                      @click="statusFilter=ORDER_STATUS.CANCELLED"
                      class="w-100"
                    >
                      <span>{{ $t("vieworderspopup.cancelled") }}</span>
                    </b-button>
                  </div>
                </div>

                <div class="filter-panel filter-panel-sub justify-content-between">
                  <div 
                    class="btn-group" 
                    role="group"
                  >
                    <button
                      type="button"
                      :class="{'active': diningFilter===null}"
                      @click="diningFilter=null"
                      class="btn btn-outline-primary"
                    >
                      All
                    </button>
                    <button 
                      v-for="(diningOption, dindex) in diningOptions" 
                      :key="'dindex_'+dindex" 
                      type="button"
                      :class="{'active': diningFilter===diningOption.name}"
                      @click="diningFilter=diningOption.name" 
                      class="btn btn-outline-primary"
                    >
                      {{ diningOption.name }}
                    </button>
                  </div>

                  <div 
                    class="btn-group" 
                    role="group"
                  >
                    <button 
                      type="button" 
                      class="btn btn-outline-primary" 
                      :class="{'active': dateFilter==='today'}"
                      @click="dateFilter='today'"
                    >
                      Today
                    </button>
                    <button 
                      type="button" 
                      class="btn btn-outline-primary" 
                      :class="{'active': dateFilter==='future'}"
                      @click="dateFilter='future';"
                    >
                      Future
                    </button>
                  </div>
                </div>

                <div class="filter-body">
                  <div class="orders-table">
                    <div 
                      v-for="(order, index) in filteredOrders" 
                      :key="'filteredOrders'+index" 
                      @click="currentOrder=order" 
                      class="orders-table-item text-left"
                    >
                      <div class="row flex-lg-nowrap justify-content-between">
                        <div class="col-sm-6 col-lg-4">
                          <p>
                            <strong>#{{ order.id }}{{ order.dining_option ? " - " + order.dining_option : "" }}</strong>
                          </p>
                          <p v-if="order.customer">
                            {{ order.customer.address }}{{
                              order.customer.city ? ", " + order.customer.city : ""
                            }}{{
                              order.customer.state ? ", " + order.customer.state : ""
                            }}{{ order.customer.zipcode ? ", " + order.customer.zipcode : "" }}
                          </p>
                        </div>

                        <div class="col-sm-6 col-lg">
                          <div v-if="order.customer">
                            <p>
                              <strong>{{ order.customer.first_name }} {{ order.customer.last_name }}</strong>
                            </p>
                            <p>
                              {{ order.customer.phone }}
                            </p>
                          </div>
                        </div>

                        <div class="col-sm-6 col-lg-3">
                          <ul class="list-unstyled mb-0">
                            <li>
                              <span>Ordered: </span>
                              <strong>{{
                                new Date(order.open_date).toLocaleTimeString("en-US", { timeStyle: "short" })
                              }}</strong>
                            </li>
                            <li>
                              <span>Timer: </span> <strong>{{ getTimerValue(order.open_date) }}</strong>
                            </li>
                            <li>
                              <span>Item Count: </span> <strong>{{ order.items.length }}</strong>
                            </li>
                            <li>
                              <span>Amount: </span> <strong>{{
                                currency
                              }}{{ order.grand_total ? order.grand_total.toFixed(2) : "0.00" }}</strong>
                            </li>
                            <li>
                              <span>Serve at: </span>
                              <strong>{{
                                !order.serve_at ? "Now" : new Date(Date.parse(order.serve_at)).toLocaleString([], {
                                  year: "numeric",
                                  month: "numeric",
                                  day: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit"
                                })
                              }}</strong>
                            </li>
                            <li>
                              <span>Status: </span> <strong>{{ order.payments.length ? "Paid" : "Unpaid" }}</strong>
                            </li>
                          </ul>
                        </div>

                        <div class="col-sm-6 col-lg-auto pt-3 pt-sm-0">
                          <div 
                            class="orders-table-settings" 
                            v-if="order.status === ORDER_STATUS.PENDING"
                          >
                            <button 
                              type="button" 
                              class="btn btn-success w-100" 
                              @click="approve(order)"
                            >
                              Approve
                            </button>
                            <button 
                              type="button" 
                              class="btn btn-danger w-100" 
                              @click="reject(order)"
                            >
                              Reject
                            </button>
                          </div>
                          <div 
                            class="orders-table-settings" 
                            v-if="order.status === ORDER_STATUS.OPEN"
                          >
                            <button 
                              type="button" 
                              class="btn btn-success w-100" 
                              @click="pay(order)"
                            >
                              Pay
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <scale-loader
            :loading="isLoad || isProcessing"
            :color="'#007bff'"
          />
        </b-col>
      </b-row>
    </div>
    <SimpleInfoModal :id="'info-modal'">
      <template v-slot:title>
        {{ $t("order.not-found") }}
      </template>
      <template v-slot:info>
        {{ $t("order.not-found") }}. {{ $t("modal-info.try-again") }}
      </template>
    </SimpleInfoModal>
    <ConfirmActionModal 
      :modal="confirmActionModal" 
      @confirm-action="confirmAction"
    />
    <SuccessActionModal :modal="successActionModal" />
    <CustomerModal
      :view-customer="viewCustomer"
      :is-selected="true"
      @close-modal="clearViewCustomer"
    />
    <ProcessingModal />
    <ModalError
      :id="'modal-error-checkout'"
      :error="error"
    />

    <ConfirmModal 
      :modal="confirmActionModal" 
      @confirm-action="rejectConfirm"
    />
  </pos-layout>
</template>

<script>
import { ORDER_STATUS } from "@/helpers/order/status";
import * as Sentry from "@sentry/vue";

// include the default style
import "vuejs-dialog/dist/vuejs-dialog.min.css";
import orderMixin from "../mixins/order/orderMixin";
import shiftMixin from "../mixins/shiftMixin";
import viewCustomerModalMixin from "../mixins/modals/viewCustomerModalMixin";
import CustomerModal from "@/modals/CustomerModal";
import SimpleInfoModal from "../components/SimpleInfoModal";
import PosLayout from "../layouts/PosLayout";
import ConfirmActionModal from "../modals/ConfirmActionModal";
import SuccessActionModal from "../modals/SuccessActionModal";
import { TRANSACTION_TYPE } from "@/helpers/order/transaction/types";
import syncMixin from "../mixins/syncMixin";
import printMixin from "../mixins/printMixin";
import ProcessingModal from "../modals/ProcessingModal";
import ModalError from "../modals/ModalError";
import { PAYMENT_BEHAVIOR } from "@/helpers/order/payment/behavior";
import { makeNARefund, makeNAVoid } from "@/helpers/payments/NABancard";
import ConfirmModal from "../modals/ConfirmModal";
import { mapGetters, mapActions} from 'vuex'
import { toFixedNoRounding } from "@/helpers/utils";

const isToday = (someDate) => {
	console.log("someDate", someDate);
	const today = new Date();
	return someDate.getDate() === today.getDate() &&
		someDate.getMonth() === today.getMonth() &&
		someDate.getFullYear() === today.getFullYear();
};
const sound = new Audio(require("../assets/sounds/error-sound.mp3"));
// const soundNewOrder = new Audio(require("../assets/sounds/online_order_notificaiton.wav"));
export default {
	name: "OrderList",
	components: {
		PosLayout,
		SimpleInfoModal,
		ScaleLoader: () => import("vue-spinner/src/ScaleLoader.vue"),
		ConfirmActionModal,
		SuccessActionModal,
		CustomerModal,
		ProcessingModal,
		ModalError,
		ConfirmModal
	},
	mixins: [orderMixin, shiftMixin, viewCustomerModalMixin, syncMixin, printMixin],
	data () {
		return {
			ORDER_STATUS,
			sidebarMob: true,
			timer: 0,
			syncing: null,
			baseURL: localStorage.baseURL,
			swiperOption: {
				slidesPerView: "auto",
				spaceBetween: 0,
				freeMode: true,
				slideToClickedSlide: false,
				pagination: {
					el: ".swiper-pagination",
					clickable: true
				}
			},
			storeInfo: null,
			itemIdCounter: 0,
			currentOrder: {
				id: 0,
				localID: 0,
				subtotal: 0,
				tax_total: 0,
				grand_total: 0,
				items: [],
				table: 0,
				status: null,
				// order_gift_card_payment:[]
			},
			modalShow: false,
			rejectingOrder: null,
			bodyBgVariant: "light",
			popupPrice: ["popup-price"],
			popupPriceSidebar: ["popup-price-sidebar"],
			statusFilter: ORDER_STATUS.PENDING,
			diningFilter: null,
			dateFilter: "today",
			displayPaymentDetailsList: false,
			searchValue: "",
			perPage: 20,
			countItems: 20,
			infoModal: {
				text: "Not Found"
			},
			confirmActionModal: {
				title: "",
				text: "",
				confirmActionText: "refund"
			},
			actionToConfirm: "",
			successActionModal: {
				title: "Success",
				text: "Order has been refunded"
			},
			isProcessing: false,
			error: {
				title: "Unknown error...",
				info: "Please, contact support"
			}
		};
	},
	computed: {
		...mapGetters({
			shouldDeductQuantity: 'orders/shouldDeductQuantity',
			refundedOrdersList: "orders/refundedOrdersList"
		}),
		employee () {
			return this.$store.getters["employee"];
		},
		currency () {
			return this.$store.getters["config/currency"];
		},
		store () {
			return this.$store.getters["config/store"];
		},
		displayOrderId: function () {
			return this.currentOrder.id;
		},
		diningOptions: function () {
			return this.terminal.dining_options.filter(o => o.online_ordering);
		},
		terminal () {
			return this.$store.getters["config/terminal"];
		},
		filteredOrders: function () {
			const self = this;
			let refundedOrders = [...self.refundedOrdersList]
			let orders = this.orders.filter(function (order) {
				return order.status == self.statusFilter && order.type === 1;
			});
			// filter by dining
			orders = this.diningFilter ? orders.filter(o => o.dining_option === this.diningFilter) : orders;

			// filter by date period
			orders = this.dateFilter === "today" ? orders.filter(o => (o.open_date && isToday(new Date(o.open_date)))) : orders.filter(o => (o.serve_at && !isToday(new Date(o.serve_at))));
			if (self.statusFilter === 3) { 
				// console.log("STATUS WORKING")
				// console.log("REFUNDED ORDERS", refundedOrders)
					refundedOrders.forEach(order => {

					if (order.reference_order !== null && order.status === 5) {
					const selectedOrder = orders.find(selectedOrder => {
					if (selectedOrder.id === order.reference_order) {  
					return selectedOrder
					}

					})
					const selectedOrderIndex = orders.findIndex(selectedOrder => selectedOrder.id === order.reference_order)
						if (selectedOrder) {
							console.log("SELECTED ORDERS",selectedOrder)
							if (order.items.length > 0) {
								order.items.forEach(item => {

									let itemIndex = -1
									selectedOrder.items.findIndex((selectedItem, index) => {

										if (selectedItem.id === item.order_refund_item) {
											itemIndex = index
											return index
										}
									})
									if (itemIndex > -1) {
										// console.log("ITEM", itemIndex)
										// let orderQuantity = selectedOrder.items[itemIndex].quantity
										if (item.void_status === 0 && (selectedOrder.items[itemIndex].void_status === 0|| selectedOrder.items[itemIndex].void_status === null)) {

											// as orders are fetched after every 2 sec thus setting isPartialRefund is set to true
											selectedOrder.items[itemIndex].isPartialRefund = true

											// all the values are already in negative thus add operation will be perform
											if (item.void_item === false) {
												item.void_item = true
												selectedOrder.items[itemIndex].isPartialRefund = true
												selectedOrder.total_sum += parseFloat((item.grand_total).toFixed(2))
												orders[selectedOrderIndex] = selectedOrder
											}
										}

									}

								})
							}
						}
					}

					})
					this.setDeductRefundQuantity(false)
					orders.forEach((selectedOrder, selectedOrderIndex) => {
					if (selectedOrder.total_sum <= 0.01 && selectedOrder.status === 3) {
						selectedOrder.isFullyRefund = true
						orders[selectedOrderIndex] = selectedOrder

					}
					})
					}



			return orders.sort((a, b) => {
				return b.id - a.id;
			}).splice(0, this.countItems);
		},
		orders: function () {
			return this.$store.getters["orders/getAllOrders"];
		},
		countPending: function () {
			return this.orders.filter(o => o.status === ORDER_STATUS.PENDING && isToday(new Date(o.open_date)) ).length;
		},
		isLoad: function () {
			return this.$store.getters.isLoad;
		},
		isMobile: function () {
			return window.innerWidth <= 992;
		}
	},
	watch: {
		async statusFilter(status, prevStatus) {	
			if (status !== 0) {
				const payload = {
					orderStatus: status,
					firstPage: 1,
					initial: true,
					onlineOrders: true
				}
				await this.fetchOrderListByStatus(payload)
			}
			else { 
				await this.fetchOnlineOrders(status)
			}
			this.$store.dispatch("getList", { status, firstPage: true });
			this.$store.dispatch("resetPageCounterForStatus", { prevStatus });
			this.countItems = this.perPage;
		},
		searchValue (value, prevValue) {
			const idRegex = "^[0-9_]*$";
			if (value.search(idRegex) === -1) this.searchValue = prevValue;
		}
	},
	async created () {
	},
	async mounted () {
		this.startTimer();
		await this.fetchOnlineOrders(this.statusFilter)
		await this.$store.dispatch("getList", { status: this.statusFilter, firstPage: true });
		const container = document.querySelector("#infinite-list");
		const self = this;
		container.addEventListener("scroll", async function () {
			if (self.isLoad) return false;
			const userScrollHeight = container.scrollTop + container.clientHeight;
			const threshold = userScrollHeight + container.scrollHeight * 0.2;
			if (threshold - container.scrollHeight > -2) {
				await self.$store.dispatch("getList", { status: self.statusFilter });
				self.countItems = self.countItems + self.perPage;
			}
		});
	},
	beforeDestroy () {
		clearInterval(this.syncing);
		this.$store.dispatch("resetPageCounterForAll");
	},
	methods: {
		...mapActions('orders', ['setRefundItems', 'setRefundOrderId', 'setDeductRefundQuantity', 'setRefundOrderTableId', 'setRefundedOrderTotalDiscount','fetchOnlineOrders','fetchOrderListByStatus']),
		toFixedNoRounding,
		getTimerValue (createdDate) {
			const today = new Date();
			let result = "";
			const createdDateObject = new Date(createdDate);
			// get total seconds between the times
			let delta = Math.abs(today - createdDateObject) / 1000;
			// calculate (and subtract) whole days
			const days = Math.floor(delta / 86400);
			delta -= days * 86400;

			// calculate (and subtract) whole hours
			const hours = Math.floor(delta / 3600) % 24;
			delta -= hours * 3600;

			// calculate (and subtract) whole minutes
			const minutes = Math.floor(delta / 60) % 60;
			delta -= minutes * 60;

			if (days > 0) {
				result += `${days.toFixed(0)} days, ${hours.toFixed(0)} hours, ${minutes} min`;
			} else if (days === 0 && hours > 0) {
				result += `${days.toFixed(0)} days, ${hours.toFixed(0)} hours, ${minutes} min`;
			} else {
				result += `${minutes} min`;
			}
			return result;
		},
		async pay (order) {
			await this.$router.push({
				name: "OrderCheckout",
				params: { id: (order.id || order.local_id) }
			});
		},
		approve (order) {
			if (order.payments.length) {
				this.$store.commit("orders/saveOrder", {
					...order, ...{
						status: ORDER_STATUS.COMPLETED,
						employee: this.employee.id,
						related_terminal: this.terminal.id
					}
				});
				this.printKitchenCheck();
			} else {
				this.$store.commit("orders/saveOrder", {
					...order, ...{
						status: ORDER_STATUS.OPEN,
						employee: this.employee.id,
						related_terminal: this.terminal.id
					}
				});	
			}
			this.$store.dispatch('sync')
		},
		reject (order) {
			this.confirmActionModal = {
				title: "Reject this order?",
				text: "Are you sure you want to reject this order?",
				confirmActionText: "yes",
				cancelActionText: "no"
			};
			this.actionToConfirm = "rejectConfirm";
			this.$bvModal.show("confirm-modal");
			this.rejectingOrder = order;
		},
		rejectConfirm () {
			this.$store.commit("orders/saveOrder", {
				...this.rejectingOrder, ...{
					status: ORDER_STATUS.CANCELLED,
					employee: this.employee.id
				}
			});
			this.$bvModal.hide("confirm-modal");
		},
		confirmAction (value) {
			if (!value) return false;
			this[this.actionToConfirm]();
			this.$bvModal.hide("confirm-action--modal");
		},
		showRefundModal() {
			const self = this
			const orderToBeRefunded = { ...this.currentOrder }
			let refundedOrders = [...self.refundedOrdersList]
			refundedOrders = refundedOrders.filter(refundedOrders => refundedOrders.reference_order === orderToBeRefunded.id)
			if (refundedOrders.length > 0) {
				refundedOrders.forEach(refundedOrder => {
					orderToBeRefunded.items.forEach((orderToBeRefundedItems, itemIndex) => {
						const item = refundedOrder.items.filter(refundedOrderItem => refundedOrderItem.order_refund_item === orderToBeRefundedItems.id)

						if (item.length > 0 && item[0].void_item)
							orderToBeRefunded.items[itemIndex].quantity += item[0].quantity
						if (orderToBeRefunded.items[itemIndex].quantity <= 0) {
							orderToBeRefunded.items.splice(itemIndex, 1)
						}
					})
				})
			}
			// saving the attributes which calculates discount total for the refunded order of the reference order
			const discountTotalDetails = {
				value: this.currentOrder.discount_orders_raw_value,
				discountType: this.currentOrder.discount_orders_raw_type

			}
			this.setRefundItems(orderToBeRefunded.items || [])
			// stroing in store
			this.setRefundedOrderTotalDiscount(discountTotalDetails)
			this.setRefundOrderId(this.currentOrder.id)
			this.setRefundOrderTableId(this.currentOrder.table)
			this.$router.push({ name: 'Order', params: { id: 'new', tableId: null, referenceId: this.currentOrder.id, refund: true } })
			
			// this.confirmActionModal = {
			// 	title: "Refund",
			// 	text: "Are you sure you want to refund this order",
			// 	confirmActionText: "refund"
			// };
			// this.actionToConfirm = "refund";
			// this.$bvModal.show("confirm-action--modal");
		},
		async refund () {
			this.$bvModal.show("processing-modal");
			let isError = false;
			const paymentObjects = [];
			try {
				for (const payment of this.currentOrder.payments) {
					console.log("payment");
					console.log(payment);
					console.log(payment.processor_response);
					const amount = 0 - (payment.amount - payment.change_amount);
					const current_payment_type = this.terminal.payment_types.find(py => py.name === payment.payment_type);
					console.log("current_payment_type", current_payment_type);
					let result;
					if (!current_payment_type) {
						this.error.title = "Payment type is not found";
						this.error.info = "Payment type is not available at current terminal";
						isError = true;
						break;
					}
					const behavior = current_payment_type.behavior;
					if (behavior === PAYMENT_BEHAVIOR.NABANCARD_INTEGRATION) {
						// NA payment
						// try void
						try {
							result = await makeNAVoid(current_payment_type.url, payment.processor_response);
							if (result && result.status === "error") {
								throw Error("Could not refund");
							}
						} catch (e) {
							Sentry.captureException(e);
							console.error("Could not void");
							console.error(e);
							isError = true;
						}
						// try refund
						if (!result || (result && result["status"] === "error")) {
							try {
								result = await makeNARefund(current_payment_type.url, payment.processor_response);
								if (result && result.status === "error") {
									throw Error("Could not refund");
								}
							} catch (e) {
								Sentry.captureException(e);
								console.error("Could not refund");
								console.error(e);
								isError = true;
							}
						}
						if (!result || (result && result["status"] === "error")) {
							console.error("Can not refund");
							this.error.title("Can not refund");
							this.error.info("Can not refund, contact administrator");
							isError = true;
						}
						// NA payment end
					}
					if (!isError) {
						paymentObjects.push({
							order: payment.order,
							employee: payment.employee,
							amount: amount,
							amount_paid: amount,
							tips: payment.tips,
							change_amount: 0,
							payment_type: payment.payment_type,
							payment_date: new Date().toISOString(),
							related_terminal: this.terminal.id,
							transaction_type: TRANSACTION_TYPE.RETURN,
							processor_refund_response: result && result.response ? result.response : "",
							card_lastfour: null,
							local_id: `${this.terminal.id}_${this.currentOrder.payments.length + 1 + paymentObjects.length}`,
							shift: this.shift.id
						});
					}

				}
			} catch (e) {
				Sentry.captureException(e);
				console.error(e);
				isError = true;
			}
			this.$bvModal.hide("processing-modal");
			if (isError) {
				this.$bvModal.show("modal-error-checkout");
			} else {
				this.currentOrder.status = 5;
				this.currentOrder.payments = [...this.currentOrder.payments, ...paymentObjects];
				paymentObjects.map(async (paymentObject) => {
					await this.savePaymentToShift({ name: paymentObject.payment_type, amount: paymentObject.amount }, "refund");
				});
				await this.saveAndStartSyncing();
				this.$bvModal.show("success-action--modal");
			}

		},
		async searchByNumber () {
			const id = this.searchValue;
			if (isNaN(id)) {
				if (!id.includes("_")) return this.searchError();
				return this.localSearch(id);
			}
			if (!navigator.onLine) return this.localSearch(id);

			const orders = await this.$store.dispatch("getOrder", { id });
			if (!orders.length) return this.searchError();
			this.currentOrder = orders[0];

			return this.searchValue = "";

		},
		localSearch (id) {
			const orders = this.orders.filter(order => order.id == id);
			if (!orders.length) return this.searchError();

			this.currentOrder = orders[0];

			return this.searchValue = "";
		},
		searchError () {
			sound.play();
			this.infoModal.text = "Order not found";
			this.$bvModal.show("info-modal");

			return this.searchValue = "";
		},
		receiveMessage (event) {
			const self = this;
			self.updateLocalId(event.data);
		},
		startTimer () {
			const vm = this;
			const timer = setInterval(function () {
				vm.value += 2;
				if (vm.value >= 180) clearInterval(timer);
			}, 50);
		},
		updateLocalId: function (msgObj) {
			if (this.currentOrder.id === msgObj.localID) {
				this.currentOrder.id = msgObj.remoteID;
			}
		},
		calculateDiscount: function (itemCost, discount) {
			return discount ? (parseFloat((discount / itemCost * 100).toFixed(2))) : 0;
		},
		isDisplayPaymentDetailsList: function () {
			return this.displayPaymentDetailsList = !this.displayPaymentDetailsList;
		}
	}
};
</script>

<style lang="scss">
.orders-bottom-options {
	border-bottom: 1px solid #e0e0e0;
}

.list-complete-item {
	transition: all .5s;
}

.list-complete-enter, .list-complete-leave-to {
	opacity: 0;
	transform: translateY(30px);
}

.list-complete-leave-active {
	position: absolute;
}

.popup-price {

	.modal-dialog {
		max-width: 440px;
	}

	.modal-body {
		padding: 30px 44px;

		@media (max-width: 767.98px) {
			padding: 25px 12px;
		}
	}

	.modal-header {
		background: #FAFAFA;
		align-items: center;
		position: relative;
		justify-content: center;
		height: 72px;

		.btn {
			min-width: 84px;
			position: absolute;
			left: 16px;
			top: 50%;
			margin-top: -20px;

			@media (max-width: 767.98px) {
				left: 12px;
			}
		}

		h5 {
			margin: 0px;
			text-align: center;
		}
	}

	.modal-footer {

		border: none;
		position: absolute;
		width: 130px;
		text-align: center;
		top: 0;
		bottom: 0;
		background: #EBEBEB;
		border-radius: 0px 10px 10px 0px;
		right: -130px;
		border-left: 3px solid #2A7BFF;
		padding: 0px;
		align-items: flex-start;
		overflow: hidden;
		height: 100%;

		.w-100 {
			height: 100%;
		}

		h5 {
			font-size: 16px;
			line-height: 19px;
			color: rgba(74, 74, 74, 0.717108);
			background: #FAFAFA;
			height: 71px;
			line-height: 71px;
			margin: 0px;
			width: 100%;
		}

		ul {
			overflow-y: auto;
			height: calc(100% - 71px);

			li {
				padding: 1px 0;
			}

			a {
				display: block;
				height: 72px;
				width: 100%;
				font-size: 15px;
				line-height: 21px;
				color: #1E1E1E;
				text-decoration: none;
				background: #fff;
				transition: all .2s ease-in-out;
				padding: 10px;

				&.active,
				&:hover {
					background: #2889FA;
					color: #fff;
				}

				span {
					display: block;
					position: relative;
					top: 50%;
					transform: translateY(-50%);
				}
			}


		}
	}

	&-sidebar {
		.modal-content {
			border-radius: 10px 0px 0px 10px;
		}

		.modal-header {
			border-radius: 10px 0px 0px 0rem;
		}

		.modal-body {
			border-radius: 0rem 0px 0px 10px;
		}
	}
}


.modifiers-sidebar-backdrop {
	background: #212121;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	cursor: pointer;
	opacity: .5;
	z-index: 10;

	transition: all .2s ease;
}

.modifiers-sidebar-backdrop[aria-pressed="false"] {
	opacity: 0;
	z-index: -1;
}


.slide-enter-active,
.slide-leave-active {
	left: 0;
	transition: transform 0.2s ease;
}

.slide-enter,
.slide-leave-to {
	left: 100%;
	transition: all .15s ease-in 0s;
}


@media (max-width: 767.98px) {
	.list-complete-item,
	.modifiers-sidebar-backdrop,
	.slide-enter-active,
	.slide-leave-active,
	.slide-enter,
	.slide-leave-to {
		transition: none !important;
	}
}


.modifiers-sidebar-panel {
	position: absolute;
	z-index: 100;
	top: 0;
	background: #eeeeee;
	left: 0;
	width: 100%;
	bottom: 0;
}

.order-sum {
	background-color: #fff;
	color: #2c3e50;
	height: 60px;
	font-size: 1.375rem;
	line-height: 1.875rem;
	padding: 15px 16px;
}
.icon-dot{
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50px;
}
</style>

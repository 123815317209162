<template>
  <div class="text-left">
    <div class="text-center">
      {{ store['name'] }}
    </div>
    <br>
    <div class="text-center">
      {{ store['address'] }}
    </div>
    <div class="text-center">
      {{ store['phone'] }}
    </div>
    <div class="text-center">
      {{ store['email'] }}
    </div>
    <div class="text-center">
      {{ store['website'] }}
    </div>
    <br>
    <div class="text-center">
      {{ store['receipt_header_text'] }}
    </div>
    <br>
    <div>
      <p>
        Sales Receipt: {{ order.id }}/{{ terminal.id }} <br>
        Date: {{ dateNow }} <br>
        <!--        Time: <br>-->
        <span v-if="employee">Cashier: {{ employee.name }}</span>
        <br>
        <br>
        <br>
      </p>
    </div>
    <table class="col-md-12 text-left">
      <tr>
        <th>Qty</th>
        <th>Item Name</th>
        <th>Price</th>
        <th>Discount</th>
        <th>Subtotal</th>
      </tr>
      <tr
        v-for="item in items"
        :key="item.id"
      >
        <td>{{ item.quantity }}</td>
        <td>
          {{ item.name }}
          <ul>
            <li
              v-for="mod in item.modifiers"
              :key="mod.id"
            >
              {{ mod.name }} - {{ mod.price.toFixed(2) }}
            </li>
          </ul>
        </td>
        <td>{{ (item.price || 0).toFixed(2) }}</td>
        <td>{{ (item.discount || 0).toFixed(2) }}</td>
        <td>{{ (getItemGrandTotal(item) || 0).toFixed(2) }}</td>
      </tr>
    </table>
    <br>
    <br>
    <br>
    <p />
    <div class="">
      <p>
        Subtotal: {{ (order.subtotal || 0).toFixed(2) }}<br>
        Order Discount: {{ (order.discount_orders || 0).toFixed(2) }}<br>
        Sales Tax: {{ (order.tax_total || 0).toFixed(2) }}<br>
        TOTAL: {{ (order.grand_total || 0).toFixed(2) }}
      </p>
    </div>
    <div class="text-center">
      {{ store.receipt_footer_text }}
    </div>
    <div class="text-center">
      Powered by Biyo POS
    </div>
  </div>
</template>
<script>
  import orderMixin from '../mixins/order/orderMixin';

  export default {
    name: 'PdfReceiptView',
    mixins: [orderMixin],
    props: ['order'],
    data(){
      return {
        dateNow: '',
        store: '',
        employee: {
          name: ''
        }
      };
    },
    computed: {
      items(){
        return this.order.items.filter((item) => item.void_status !== 1);
      },
      terminal () {
        return this.$store.getters["config/terminal"];
      }
    },
    async beforeCreate(){
      let today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      const yyyy = today.getFullYear();
      today = dd + '/' + mm + '/' + yyyy;
      this.dateNow = today;
    },
    methods: {}
  };
</script>

import DashboardService from "../../services/dashboard-service";
import * as Sentry from "@sentry/vue";

const state = () => ({
  list: [],
  updating_shift: {
    local_id: null,
    remote_id: null
  }
});

// getters
const getters = {
  getUpdatingShift: (state) => state.updating_shift,
  getAllShifts: (state) => state.list
};

// mutations
const mutations = {
  // TODO apply DRY
  save (state, payload) {
    const now = (new Date).toISOString();
    payload = {
      ...payload, ...{
        created_at: payload.id ? payload.created_at : now,
        updated: true
      }
    };
    let existingIndex;
    if (payload.id && payload.id > 0) {
      existingIndex = state.list.findIndex(instance => instance.id === payload.id);
    }
    if ((existingIndex === undefined || existingIndex === -1) && payload.local_id) {
      existingIndex = state.list.findIndex(instance => instance.local_id === payload.local_id);
    }
    if ((existingIndex === undefined || existingIndex === -1)) {
      state.list.push(payload);
    } else {
      state.list = [...state.list.map((item, itemIndex) => itemIndex !== existingIndex ? item : { ...item, ...payload })];
    }
  },
  updateId (state, payload) {
    state.updating = {
      local_id: payload.local_id || payload.id,
      remote_id: payload.id
    };
    const objIndex = state.list.findIndex((obj => (obj.local_id === payload.local_id || obj.id === payload.id)));
    state.list[objIndex].id = payload.id;
  }
};

// actions
const actions = {
  upload: async ({ state, commit }, payload) => {
    let retrieved
    try {
       retrieved = (await DashboardService.postShift(payload));
    } catch (e){
      Sentry.captureException(e);
      console.error(e);
      console.error(e.message);
      const index = state.list.findIndex(instance => (instance.local_id === payload.local_id));
      // state.list[index].updated = false;
      state.list[index].archived = true;
      return
    }

    if(!payload.id && retrieved.id){
      commit('updateShiftIdIfMatch', {local_id: payload.local_id, id: retrieved.id}, {root: true})
    }
    const index = state.list.findIndex(instance => (instance.local_id === retrieved.local_id) || (instance.id === retrieved.id));
    state.list[index].updated = false;
    commit("updateId", retrieved);
  },
  uploadUpdated: async ({ state, dispatch }) => {
    const shiftsToUpload = state.list.filter(instance => instance.updated && !instance.archived);
    for (const instance of shiftsToUpload) {
      const index = state.list.findIndex(instanceFind => ((instanceFind.local_id === instance.local_id) || (instanceFind.id === instance.id)));
      try {
        await dispatch("upload", instance);
        state.list[index]["updated"] = false;
      } catch (e) {
        Sentry.captureException(e);
        console.error(`Could not upload ${instance.local_id || instance.id}`, e);
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export const surcharge = {
    namespaced: true,
    state: {
      surcharged:false
    },
    mutations: {
      SET_SURCHARGE:(state,surcharge)=> state.surcharged = surcharge
    },
    getters: {
        isSurcharged: state => state.surcharged
    },
    actions:{
        setSurcharge : ({commit},surcharge) => commit('SET_SURCHARGE',surcharge)
    }
  };
  
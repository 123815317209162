<template>
  <b-modal
    :id="idModal"
    no-close-on-backdrop
    hide-footer
    size="lg"
    body-class="modal-height"
    style="height: 90vh"
    no-fade
  >
    <template
      slot="modal-header"
    >
      <b-button
        variant="outline-light"
        @click="closeModal"
      >
        {{ $t('customerpopup.cancel') }}
      </b-button>
      <h5>
        {{ isView ? $t('customerpopup.view-customer') : $t('customerpopup.add-new-customer') }}
      </h5>
      <b-button
        v-if="!isView"
        variant="primary"
        @click="addCustomer"
      >
        {{ $t('customerpopup.done') }}
      </b-button>
      <b-button
        v-else-if="!isSelected"
        variant="primary"
        @click="selectCustomer"
      >
        {{ $t('customerpopup.select') }}
      </b-button>
    </template>
    <b-card
      class="general-info mb-3"
      body-class="p-0"
    >
      <div class="general-info__content d-flex flex-wrap">
        <b-form-input
          v-model="customer.first_name"
          :class="!customer.first_name & isSubmit && 'is-invalid'"
          :disabled="isView"
          :placeholder="$t('customerpopup.first-name')"
          type="email"
          autocomplete="off"
          required
        />

        <b-form-input
          v-model="customer.last_name"
          :disabled="isView"
          :placeholder="$t('customerpopup.last-name')"
          autocomplete="off"
          required
        />
        <b-form-input
          v-model="customer.phone"
          :disabled="isView"
          :placeholder="$t('customerpopup.phone-number')"
          autocomplete="off"
          required
        />
        <b-form-input
          v-model="customer.email"
          :disabled="isView"
          :placeholder="$t('customerpopup.email-id')"
          type="email"
          autocomplete="off"
          required
        />
      </div>
    </b-card>
    <b-card
      class="mb-3"
      body-class="p-0"
    >
      <!-- address info -->
      <div class="d-flex flex-column">
        <div class="flex-grow-1">
          <b-form-input
            v-model="addressDetails.address_1"
            :disabled="isView"
            :placeholder="$t('customerpopup.address-1')"
            autocomplete="off"
            required
          />
          <b-form-input
            v-model="addressDetails.address_2"
            :disabled="isView"
            :placeholder="$t('customerpopup.address-2')"
            autocomplete="off"
            required
          />
          <b-form-select
            v-if="!isView"
            v-model="addressDetails.country"
            :options="countries"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >
                -- Please select a country --
              </b-form-select-option>
            </template>
          </b-form-select>
          <b-form-input
            v-else
            v-model="country"
            :disabled="isView"
            :placeholder="$t('customerpopup.country')"
            autocomplete="off"
            required
          />
          <b-form-select
            v-if="addressDetails.country === 'US' && !isView"
            v-model="addressDetails.state"
            :options="usaState"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >
                -- Please select a state --
              </b-form-select-option>
            </template>
          </b-form-select>
          <b-form-input
            v-else
            v-model="addressDetails.state"
            :disabled="isView"
            :placeholder="$t('customerpopup.state')"
            autocomplete="off"
            required
          />
          <div class="d-flex flex-grow-1">
            <b-form-input
              v-model="addressDetails.city"
              :disabled="isView"
              :placeholder="$t('customerpopup.city')"
              autocomplete="off"
              required
            />
            <b-form-input
              v-model="addressDetails.postal_code"
              :disabled="isView"
              :placeholder="$t('customerpopup.zip-code')"
              autocomplete="off"
              required
            /> 
          </div>
        </div>
      </div>
      <!-- address info -->
    </b-card>
    <b-card
      class="mb-3"
      body-class="p-0"
    >
      <b-textarea
        :model="customer.notes"
        :disabled="isView"
        :placeholder="$t('customerpopup.notes')"
        rows="5"
      />
    </b-card>
  </b-modal>
</template>
<script>
  // import {findAvailableCustomerLocalId, syncCustomer} from "../helpers/dbHelper";
  import orderMixin from "../mixins/order/orderMixin";
  import {mapActions,mapGetters} from 'vuex'
  import customerAddressHelper from "@/helpers/customer/customer"
  export default {
    name: 'CustomerModal',
    mixins: [orderMixin],
    props: {
      viewCustomer: {
        type: Object,
        default: () => {}
      },
      isSelected: {
        type: Boolean,
        default: false
      },
      index: {
        type: Number,
        default: null
      }
    },
    data () {
      return {
        customer: {
          first_name: null,
          last_name: null,
          phone: null,
          email: null,
          notes: null,
          
        },
        addressDetails:{
          address_1: null,
          address_2: null,
          city: null,
          state: null,
          postal_code: null,
          country:null,
          default:true
        },
        isSubmit: false,
        
      }
    },
    computed: {
      ...mapGetters({
          countries:"customers/countries",
          usaState:"customers/usaState"
      }),
      isView() {
        return this.viewCustomer !== null && Object.keys(this.viewCustomer).length > 0
      },
      isValidForm() {
        return !!this.customer.first_name;
      },
      idModal() {
        return this.index ? 'customer-modal-' + this.index : 'customer-modal'
      },
      country(){
        return this.addressDetails?.country.length === 2 ? customerAddressHelper.deSerializeCountryValue(this.addressDetails?.country) : this.addressDetails?.country
      }
    },
    watch: {
      viewCustomer (customer) {
        this.setViewCustomer(customer)
      }
    },
    created(){
      // this.fetchCountriesAndStates()
    },
    methods: {
      ...mapActions({
        fetchCountriesAndStates : 'customers/fetchCountriesAndStates'
      }),
      async addCustomer() {
        if (!this.isValidForm)
          return this.isSubmit = true;
        const customer = this.customer;
        customer.local_id = (new Date).toISOString();
        customer.addresses = [] 
        this.addressDetails.local_id =  (new Date).toISOString();
        customer.addresses.push(this.addressDetails)
        customer.default_address = {...this.addressDetails}
        this.$store.commit('customers/save', customer)
        // old
        // customer.id = await findAvailableCustomerLocalId();
        // customer.local_id = customer.id
        // try{
        //   if('serviceWorker' in navigator && 'SyncManager' in window ){
        //     await syncCustomer(customer);
        //     await navigator.serviceWorker.ready.then(reg => reg.sync.register('customer_sync'));
        //   } else{
        //     await this.postCustomer(customer);
        //   }
        // } catch(e){
        //   console.log(e)
        // }
        this.$emit('get-customers');
        this.$bvModal.hide(this.idModal);
        this.customerInit();
        this.$emit('select-customer-after-add', customer);
      },
      selectCustomer() {
        this.$bvModal.hide(this.idModal);
        this.customerInit();
        this.$emit('select-customer', this.viewCustomer);
      },
      customerInit() {
        this.customer =
          {
            first_name: null,
            last_name: null,
            phone: null,
            email: null,
            notes: null
          }
          this.addressDetails={
          address_1: null,
          address_2: null,
          city: null,
          state: null,
          postal_code: null,
          country:null,
          default:true
        }
      },
      setViewCustomer(customer) {
        if (this.isView) {
          this.customerInit();
          this.$set(this, 'customer', {...this.customer, ...customer})
          this.addressDetails = this.customer.default_address ? this.customer.default_address : this.customer.addresses.find(address=>address.default=== true) 
        }
      },
      closeModal() {
        this.$bvModal.hide(this.idModal);
        this.customerInit();
        this.$emit('close-modal');
      }
    }
  }
</script>

<style scoped type="text/css" lang="scss">
  .modal-body .modal-height {
    height: 90vh !important;
  }
  .form-control {
    height: 3rem;
    border-radius: 0;
    border-top: initial;
    border-left: initial;
  }
  .general-info {
    &__content {
      .form-control {
        width: 50%;

      }
    }
  }
</style>

import Vue from "vue";
import Router from "vue-router";
import routes from "./routes";
// import store from "@/store.js"

Vue.use(Router);
//
// const originalPush = Router.prototype.push;
// Router.prototype.push = function push (location) {
//   return originalPush.call(this, location).catch(err => {
//     if (err.name !== "NavigationCancelled") throw err;
//   });
// };

// const router = new Router({
//   routes
// });
// router.onError(err => {
//   print("<!-- router error: " + err.message + " -->");
// });
//
// router.beforeEach((to, from, next) => {
//   console.log(to.path);
//   if (to.path != "/pin" && to.path != "/reset") {
//     // next('orderList');
//     if (false || store.getters.getPstat) {
//       if (store.getters.getPstat) {
//         console.log("There is a token, resume. (" + to.path + ")");
//         next();
//       } else {
//         console.log("There is no token, test redirect to login. (" + to.path + ")");
//         next("pin");
//       }
//     } else {
//       console.log("You're on the login page");
//       next(); // This is where it should have been
//     }
//   }
// });
Router.prototype
// Router.onError((err)=>{
//   console.log("error");
//   console.log(err);
// })

export default new Router({
  routes
});

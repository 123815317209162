const state = () => ({
  qtyString: "",
  changed: false,
  index: 0,
  initial: ''
});

// getters
const getters = {
  index: (state) => state.index,
  initial: (state) => state.initial

};

// mutations
const mutations = {
  setIndex (state, payload) {
    state.index = payload;
  },
  setInitial (state, payload) {
    state.initial = payload;
  }
};

// actions
const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { getDashboardSettings } from "@/helpers/storageHelper";
import * as Sentry from "@sentry/vue";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init () {
    try {
      Vue.use(VueAxios, axios)
      Vue.axios.defaults.baseURL = getDashboardSettings().url + '/api/'
      Vue.axios.defaults.timeout = 30000 // 30s Request timeout
      Vue.axios.defaults.withCredentials = true // send cookies when cross-domain requests
      setHeader()
      setRequestInterceptors()
      setResponseInterceptors()
    } catch (e) {
      Sentry.captureException(e);
      console.log('getDashboardSettings not loaded')
    }
  },

  async query (resource, params) {
    return await new Promise((resolve, reject) => {
      Vue.axios.get(resource, params).then((response) => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  async get (resource, params) {
    return await new Promise((resolve, reject) => {
      Vue.axios.get(`${resource}`, params).then((response) => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  async post (resource, params) {
    return await new Promise((resolve, reject) => {
      Vue.axios.post(`${resource}`, params).then((response) => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

// Private Functions

/**
 * Set the default HTTP request headers
 */
function setHeader () {
}

/**
 * Set the default request interceptor
 */
function setRequestInterceptors () {
  Vue.axios.interceptors.request.use(request => {
    return request
  })
}

/**
 * Set the default response interceptor
 */
function setResponseInterceptors () {
  axios.interceptors.response.use(response => {
    // Successfully response
    return response
  }, error => {
    // Failed response
    return Promise.reject(error)
  })
}

export default ApiService

export const TERMINAL_MODE = {
  '1': 'TABLE_SERVICE',
  '2': 'QUICK_SERVICE',
  '3': 'KIOSK_MODE'
};
export const TERMINAL_MODE_IDS = {
  'TABLE_SERVICE': 1,
  'QUICK_SERVICE': 2,
  'KIOSK_MODE': 3
};

<template>
  <b-modal
    id="success-action--modal"
    hide-footer
    hide-header
    no-close-on-backdrop
    no-close-on-esc
    modal-class="success-popup"
    no-fade
  >
    <div class="text-center modal-complete">
      <svg class="icon icon-icon-success-frame">
        <use xlink:href="#icon-icon-success-frame" />
      </svg>

      <h2>
        <!-- TODO: need to add translation -->
        {{ modal.title }}
      </h2>
      <div class="popup-meta">
        <!-- TODO: need to add translation -->
        {{ modal.text }}
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <b-button
          variant="primary"
          @click="$bvModal.hide('success-action--modal')"
        >
          <!-- TODO: need to add translation -->
          Close
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
  export default {
    name: "SuccessActionModal",
    props: {
      modal: {
        type: Object,
        required: true
      }
    },
    methods: {}
  }
</script>

<style scoped>

</style>

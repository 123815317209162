const state = () => ({
    orderDiscount: {
        val: 0,
        type: 1,
    }
})

const getters = {
    discountDetails: state => state.orderDiscount
}

const mutations = {
    setOrderDiscount : (state,orderDiscount) => state.orderDiscount = Object.assign(state.orderDiscount,orderDiscount)
}

const actions = {

    setOrderDiscount: ({ commit }, discountDetails) => {
        console.log("DISSOUNT ",discountDetails)
        commit('setOrderDiscount',discountDetails)
    }


}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

export const errorModal = {
  namespaced: true,
  state: {
    modalId: 'modal-error',
    errorBody: {
      title: 'Unknown error...',
      info: 'Please, contact support'
    },
    cancelCallback: () => {}
  },
  mutations: {
    SET_ERROR: (state, payload) => {
      state.modalId = payload.modalId;
      state.errorBody = payload.errorBody;
      state.cancelCallback = payload.cancelCallback ? payload.cancelCallback : () => {}
    }
  },
  getters: {
    error: (state) => {
      return {
        modalId: state.modalId,
        errorBody: state.errorBody,
        cancelCallback: state.cancelCallback
      }
    }
  },
  actions: {
    displayModalError: ({commit}, payload) => {
      commit('SET_ERROR', payload);
    }
  }
}

export const COLORS = {
  'orange': '#e56d3b',
  'aqua': '#459b99',
  'brown': '#744a17',
  'green': '#009d13',
  'cyan': '#00b2d8',
  'maroon': '#8f1758',
  'pink': '#b93aab'
};


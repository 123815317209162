<template>
  <div class="pin-keyboard text-center my-0">
    <ul class="pin-keyboard-list">
      <li>
        <span
          class="pin-key"
          @click="typeNum(1)"
        >1</span>
      </li>
      <li>
        <span
          class="pin-key"
          @click="typeNum(2)"
        >2</span>
      </li>
      <li>
        <span
          class="pin-key"
          @click="typeNum(3)"
        >3</span>
      </li>

      <li>
        <span
          class="pin-key"
          @click="typeNum(4)"
        >4</span>
      </li>
      <li>
        <span
          class="pin-key"
          @click="typeNum(5)"
        >5</span>
      </li>
      <li>
        <span
          class="pin-key"
          @click="typeNum(6)"
        >6</span>
      </li>

      <li>
        <span
          class="pin-key"
          @click="typeNum(7)"
        >7</span>
      </li>
      <li>
        <span
          class="pin-key"
          @click="typeNum(8)"
        >8</span>
      </li>
      <li>
        <span
          class="pin-key"
          @click="typeNum(9)"
        >9</span>
      </li>

      <li>
        <span
          class="pin-key pin-key_call"
          @click="typeNum('C')"
        >C</span>
      </li>
      <li>
        <span
          class="pin-key"
          @click="typeNum(0)"
        >0</span>
      </li>
      <li>
        <span
          class="pin-key bg-primary"
          @click="confirm"
        >
          <svg class="icon icon-icon-check">
            <use xlink:href="#icon-icon-check" />
          </svg>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    props: ['searchValue'],
    data: () => ({
      value: ''
    }),
    watch: {
      searchValue(value) {
        this.value = value;
      }
    },
    mounted() {
      this.$root.$on('bv::dropdown::show', () =>  {
        window.addEventListener('keydown', this.onKey);
        this.value  = ''
      });
      this.$root.$on('bv::dropdown::hide', () =>  {
        window.removeEventListener('keydown', this.onKey);
      });
    },
    methods: {
      onKey(event) {
        if (event.keyCode === 13) {
          return this.confirm();
        } else {
          this.typeNum(this.getNum(event));
        }
      },
      getNum(event) {

        let num = '';
        if (event.keyCode >= 48 && event.keyCode <= 57) {
          num = event.keyCode - 48;
        } else if (event.keyCode === 8) {
          num  = "C";
        }

        return num;
      },
      typeNum: function (num) {
        if (num === 'C') {
          this.value = '';

          return this.$emit('barcode-input-change',this.value);
        }

        return  this.$emit('barcode-input-change',this.value += num);
      },
      confirm: function() {
        this.$emit('hide-barcode-numpad');

        return this.$emit('confirm-action', this.value);
      }
    }
  }
</script>

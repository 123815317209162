import {sendToScales} from '../helpers/print/scales';

export default {
  methods: {
    getWeight: async function(){
      try{
        await sendToScales(this.setWeight);
      } catch(e){
        this.$store.dispatch('errorModal/displayModalError', {
          modalId: 'scale-error',
          errorBody: {
            title: 'Scales error',
            info: 'Can not connect to scales'
          }
        });
      }
    },
    setWeight: function(weight){
      if (weight.includes('lb')){
        weight = parseFloat(weight.trim().split('lb')[0]).toFixed(3);
        this.typeNum(null, weight);
      } else if(!(weight==='') && !weight.includes('lb')){
        console.error('Weight should be in lb');
        // todo translate errors
        this.$store.dispatch('errorModal/displayModalError', {
          modalId: 'scale-error',
          errorBody: {
            title: 'Scales error',
            info: 'Weight should be in lb'
          }
        });
      } else {
        console.log(`Received:|${weight}|`)
      }
    }
  }
};

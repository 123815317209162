<template>
  <div class="pin-page h-100 text-center">
    <b-container 
      fluid 
      class="h-100"
    >
      <b-row class="h-100 align-content-center">
        <b-col cols="12">
          <b-navbar 
            variant="faded" 
            type="light"
          >
            <b-navbar-brand href="#" />
          </b-navbar>

          <!-- open shift popup-->
          <b-modal
            id="modal-open-shift"
            no-close-on-backdrop
            hide-footer
            body-bg-variant="light"
            no-fade
          >
            <template slot="modal-header">
              <b-button 
                variant="outline-light" 
                @click="cancel()"
              >
                {{ $t("shiftviewpopup.cancel") }}
              </b-button>
              <h5>
                {{ $t("shiftviewpopup.open-shift") }}
              </h5>
              <b-button 
                variant="primary" 
                @click="openShift()"
              >
                {{ $t("shiftviewpopup.open-shift") }}
              </b-button>
            </template>

            <div class="modal-top text-center">
              <p>
                {{ $t("shiftviewpopup.a-shift-has-not-yet-been-opened") }}
              </p>
              <p>
                {{ $t("shiftviewpopup.enter-starting-cash-amount") }}
              </p>
            </div>

            <div class="pin-win">
              <span>{{ currency }} {{ newShiftAmount.toFixed(2) }}</span>
            </div>
            <numpad
              @updated="setShiftAmount"
            />
          </b-modal>

          <!-- close shift popup-->
          <b-modal
            id="modal-close-shift"
            no-close-on-backdrop
            hide-footer
            body-bg-variant="light"
            no-fade
          >
            <template slot="modal-header">
              <b-button 
                variant="outline-light" 
                @click="cancel()"
              >
                {{ $t("shiftviewpopup.cancel") }}
              </b-button>
              <h5>
                {{ $t("shiftviewclosingcurrentshiftpopup.count-your-drawer") }}
              </h5>
              <b-button 
                variant="primary" 
                @click="closeShift()"
              >
                {{ $t("shiftviewclosingcurrentshiftpopup.close-shift") }}
              </b-button>
            </template>

            <div class="modal-top text-center">
              <p>
                {{ $t("shiftviewclosingcurrentshiftpopup.you-are-about-to-close-shift") }}
              </p>
              <p>
                {{ $t("shiftviewclosingcurrentshiftpopup.enter-end-cash-amount") }}
              </p>
            </div>

            <div class="pin-win">
              <span>{{ currency }} {{ newShiftAmount.toFixed(2) }}</span>
            </div>
            <numpad
              @updated="setShiftAmount"
            />
          </b-modal>

          <!-- status shift popup-->
          <b-modal
            id="modal-status-shift"
            no-close-on-backdrop
            hide-footer
            header-bg-variant="light"
            body-bg-variant="white"
            ok-only
            no-fade
            no-stacking
          >
            <template slot="modal-header">
              <b-button 
                variant="outline-light" 
                @click="cancel()"
              >
                {{ $t("shiftviewdetailspopup.cancel") }}
              </b-button>
              <h5>
                {{ $t("shiftviewdetailspopup.shift-summary") }}
              </h5>
              <b-button 
                v-b-modal.modal-close-shift 
                v-if="!currentShift.shift_close_date" 
                variant="danger"
              >
                {{ $t("shiftviewcurrentshiftpopup.close-shift") }}
              </b-button>
              <b-button 
                v-b-modal.modal-open-shift 
                v-if="currentShift.shift_close_date" 
                variant="primary"
              >
                {{ $t("shiftviewlastclosedshiftpopup.open-shift") }}
              </b-button>
            </template>

            <div class="shift">
              <div class="shift-top">
                <div class="shift-top-icon">
                  <svg class="icon icon-icon-shift">
                    <use xlink:href="#icon-icon-shift" />
                  </svg>
                </div>
                <div class="shift-top-body">
                  <div class="d-flex align-items-center">
                    <div class="shift-title mr-3">
                      {{ $t("shiftviewdetailspopup.shift") }} {{ (currentShift.id || currentShift.local_id) }}
                    </div>
                    <div 
                      v-if="!currentShift.shift_close_date" 
                      class="shift-status bg-success"
                    >
                      {{ $t("shiftviewdetailspopup.active") }}
                    </div>
                    <div 
                      v-if="currentShift.shift_close_date" 
                      class="shift-status bg-danger"
                    >
                      {{ $t("shiftviewdetailspopup.closed") }}
                    </div>
                  </div>
                  <div 
                    v-if="currentShift.shift_close_date === null" 
                    class="shift-meta"
                  >
                    {{ $t("shiftviewdetailspopup.opened") }} {{ currentShift.shift_open_date | moment("calendar") }} by
                    {{ openedBy }}
                  </div>
                  <div 
                    v-if="currentShift.shift_close_date !== null" 
                    class="shift-meta"
                  >
                    Closed {{ currentShift.shift_close_date | moment("calendar") }} by
                    {{ closedBy }}
                  </div>
                </div>
              </div>

              <div class="d-flex shift-total-row">
                <div class="shift-total">
                  <div class="shift-total-inner">
                    <strong>{{ currentShift.totalTransactions }}</strong>
                    <span>
                      {{ $t("shiftviewdetailspopup.total-transactions") }}
                    </span>
                  </div>
                </div>
                <div class="shift-total">
                  <div class="shift-total-inner">
                    <!-- <strong>{{ currency }} {{
                      currentShift.shift_amount ? currentShift.shift_amount.toFixed(2) : "0.00"
                    }}</strong> -->
                    <strong>{{ currency }} {{ currentShift.payment_breakdown ? currentShift.payment_breakdown.net_sales.toFixed(2) : "0.00" }}</strong>
                    <span>
                      {{ $t("shiftviewdetailspopup.total-net-sales") }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="shift-list">
                <table>
                  <tr>
                    <td>
                      {{ $t("shiftviewdetailspopup.opening-amount") }}
                    </td>
                    <td>
                      {{ currency+currentShift.opening_amount.toFixed(2) }}
                    </td>
                  </tr>
                  <tr v-if="currentShift.payment_breakdown">
                    <td><strong>Sales Breakdown</strong></td>
                    <td><strong>{{ currency+currentShift.payment_breakdown.total_sales.toFixed(2) }}</strong></td>
                  </tr>
                  <tr 
                    v-for="paymentType in currentShift.payment_breakdown.sales" 
                    :key="paymentType.name"
                  >
                    <td>
                      - {{ paymentType.payment_type }} Sales
                    </td>
                    <td>
                      {{ currency+paymentType.sum.toFixed(2) }}
                    </td>
                  </tr>
                  <tr v-if="currentShift.payment_breakdown">
                    <td><strong>Refunds Breakdown</strong></td>
                    <td class="text-danger">
                      <strong>{{ currentShift.payment_breakdown.total_refund.toFixed(2) >= 0 ? `${currency}${currentShift.payment_breakdown.total_refund.toFixed(2)}` :`-${currency}${(-1*currentShift.payment_breakdown.total_refund.toFixed(2))}` }}</strong>
                    </td>
                  </tr>
                  <tr 
                    v-for="(paymentType,index) in currentShift.payment_breakdown.refund" 
                    :key="index"
                  >
                    <td>
                      - {{ paymentType.payment_type }} Refunds
                    </td>
                    <td class="text-danger">
                      {{ paymentType.sum.toFixed(2) >= 0 ? `${currency}${paymentType.sum.toFixed(2)}` :`-${currency}${(-1*paymentType.sum.toFixed(2))}` }}
                    </td>
                  </tr>
                  <tr v-if="currentShift.gift_card_breakdown">
                    <td><strong>Gift Card Breakdown</strong></td>
                    <td class="text-danger" />
                  </tr>
                  <tr 
                    v-for="(paymentType,index) in currentShift.gift_card_breakdown" 
                    :key="index"
                  >
                    <td>
                      - {{ paymentType.name }}
                    </td>
                    <td>
                      {{ currency + paymentType.sum.toFixed(2) }}
                    </td>
                  </tr>
                  <tr v-if="currentShift.shift_close_date">
                    <td>
                      {{ $t("shiftviewdetailspopup.closing-amount") }}
                    </td>
                    <td>
                      <span v-if="currentShift.closing_amount >= 0">{{ currency }} {{
                        currentShift.closing_amount.toFixed(2)
                      }}</span>
                      <span v-else> - </span>
                    </td>
                  </tr>
                  <tr v-if="currentShift.shift_close_date">
                    <td>
                      {{ $t("shiftviewdetailspopup.over-short") }}
                    </td>
                    <td>
                      <span v-if="currentShift.closing_amount >= 0"> 
                        {{
                          currentShift.over_shortage >=0 ?
                            currency+parseFloat(currentShift.over_shortage).toFixed(2) : "-"+currency+
                              (-1*parseFloat(currentShift.over_shortage)).toFixed(2)
                        }}</span>
                      <span v-else> - </span>
                    </td>
                  </tr>
                </table>
              </div>

              <div class="text-center">
                <b-button 
                  variant="outline-light" 
                  size="lg" 
                  @click="printShiftReport()"
                >
                  {{
                    currentShift.shift_close_date ? $t("shiftviewlastclosedshiftpopup.print-z-report") : $t("shiftviewcurrentshiftpopup.print-x-report")
                  }}
                </b-button>
                <b-button 
                  variant="outline-light" 
                  size="lg" 
                  @click="printShiftReport(true)"
                >
                  {{ $t("shiftviewcurrentshiftpopup.print-summary") }}
                </b-button>
              </div>
            </div>
          </b-modal>
        </b-col>
      </b-row>
    </b-container>
    <EnterPinModal 
      :custom-close-action="true" 
      @verify-action="verifyAction" 
      @close-enter-pin-modal="enterPinClose"
    />
    <scale-loader
      :loading="isLoad"
      :color="'#007bff'"
    />
  </div>
</template>

<script>
import router from "../router";
import EnterPinModal from "../modals/EnterPinModal";
import ScaleLoader from "vue-spinner/src/ScaleLoader";
import { EMPLOYEE_ROLE } from "../helpers/employee/role";
import { printSummary } from "../helpers/printHelper";
import DashboardService from "../services/dashboard-service";
import numpad from "@/components/Numpad";
import * as Sentry from "@sentry/vue";

export default {
  name: "Shift",
  components: {
    numpad,
    EnterPinModal,
    ScaleLoader
  },
  mixins: [],
  data () {
    return {
      newShiftAmount: 0,
      openedBy: "unknown",
      closedBy: "unknown",
      isLoad: false,
      currentShift: {
        totalTransactions: 0,
        opening_amount: 0,
        shiftAmount: 0,
        terminal: 0,
        open_shift_employee: 0,
        close_shift_employee: 0,
        shift_close_date: null,
        total_cashtenders: 0,
        total_cashreturns: 0,
        total_payouts: 0,
        orders: [],
        payment_breakdown: {
          sales: [],
          refund: [],
          total_sales: 0,
          total_refund: 0,
          net_sales:0
        },
        gift_card_breakdown:[
          {name: "Gift Card Paid In", sum: 0},
          {name: "Gift Card Paid Out", sum: 0},
          {name: "Net", sum: 0}
        ]
      },
      displayInfo: null,
      openShiftPending: false,
      closeShiftPending: false
    };
  },
  computed: {
    terminal () {
      return this.$store.getters["config/terminal"];
    },
    employee () {
      return this.$store.getters.employee;
    },
    terminalMode () {
      return this.$store.getters.terminalMode;
    },
    updatingShift () {
      return this.$store.getters["shifts/getUpdatingShift"];
    },
    currency () {
      return this.$store.getters["config/currency"];
    },
    updatedShift() { 
      return this.$store.getters["shift"]
    }
  },
  watch: {
    displayInfo (value) {
      if (!value) return this.$bvModal.show("enter-pin-modal");
      this.$bvModal.hide("enter-pin-modal");
      this.$bvModal.show(this.currentShift.local_id?"modal-status-shift":"modal-open-shift");
    },
    updatingShift () {
      if (this.currentShift.local_id === this.updatingShift.local_id) {
        this.currentShift.id = this.updatingShift.remote_id;
        this.$forceUpdate();
      }
    },
    'updatedShift': {
      handler() { 
        if(!this.currentShift.shift_close_date)
          this.currentShift.payment_breakdown = { ...this.updatedShift.payment_breakdown }
          this.$forceUpdate();
      },
      immediate: true,
    }
  },
  beforeMount: async function () {
    const shift = this.$store.getters.shift;
    this.currentShift = (shift && (shift.local_id || shift.id)) ? shift : this.currentShift;
    // 
    if (!Object.keys(this.currentShift).includes('payment_breakdown')
      || Object.keys(this.currentShift.payment_breakdown).length === 0
    ) {
      this.currentShift.payment_breakdown = {
        sales: [],
        refund: [],
        total_sales: 0,
        total_refund: 0,
        net_sales: 0
      }
    }
    console.log('bm this.currentShift', this.currentShift);
    if (this.currentShift && this.currentShift.open_shift_employee) {
      this.openedBy = (await this.$store.getters["config/employeeById"](this.currentShift.open_shift_employee)).name;
    }
    if (this.currentShift && this.currentShift.close_shift_employee) {
      this.closedBy = this.currentShift.close_shift_employee
        ? (await this.$store.getters["config/employeeById"](this.currentShift.close_shift_employee)).name
        : "unknown";
    }
  },
  created: async function () {
    const dbSettings = this.terminal.db_settings;
    const allowEmployeeCashierShift = dbSettings.find(setting => setting.name === "allow_cashier_shift");
    this.displayInfo = !((allowEmployeeCashierShift && allowEmployeeCashierShift.value === "false")
      && this.employee.role === EMPLOYEE_ROLE.Cashier);
    if (!(this.currentShift && this.currentShift.local_id)) return this.$bvModal.show("modal-open-shift");
    if (this.currentShift && this.currentShift.id && this.displayInfo) return this.$bvModal.show("modal-status-shift");
  },
  methods: {
    enterPinClose () {
      if (!this.displayInfo)
        return this.$router.go(-1);
    },
    verifyAction (employee) {
      if (!employee) return false;
      this.displayInfo = true;
    },
    printShiftReport: async function (isSaleSummary) {
      let daySummary;
      if (isSaleSummary) {
        try {
          daySummary = await DashboardService.getDaySummary();
        } catch (e) {
          Sentry.captureException(e);
          console.error(e);
        }
      }
      await printSummary(isSaleSummary, daySummary);
    },
    cancel: async function () {
      this.$bvModal.hide("modal-open-shift");
      return this.terminalMode === "QUICK_SERVICE" && this.currentShift.shift_close_date
        ? this.$store.commit("lock") : router.push({ name: "Tables" });
    },
    setShiftAmount: async function (sum) {
      this.newShiftAmount = parseFloat(sum);
    },
    openShift: function () {
      this.currentShift = {
        terminal: this.terminal.id,
        opening_amount: this.newShiftAmount,
        open_shift_employee: this.employee.id,
        close_shift_employee: null,
        shift_open_date: new Date().toISOString(),
        shift_close_date: null,
        total_cashtenders: 0,
        total_cashreturns: 0,
        total_drops: 0,
        total_payouts: 0,
        over_shortage: 0,
        shift_amount: 0,
        totalTransactions: 0,
        orders: [],
        local_id: `${this.terminal.id}_${Date.now()}`
      };
      this.$bvModal.hide("modal-open-shift");
      this.newShiftAmount = 0;
      this.$store.commit("setCurrentShift", this.currentShift);
      this.$store.commit("shifts/save", this.currentShift);
      this.$store.dispatch("sync");
      this.terminalMode === "QUICK_SERVICE" ? this.$router.push({
        name: "Order",
        params: {
          tableID: null,
          id: "new"
        }
      }) : router.push({ name: "Tables" });
    },
    closeShift: async function () {
      console.log("[Shift] closeShift");
      this.currentShift.closing_amount = this.newShiftAmount;
      const over_shortage =  this.currentShift.closing_amount - (this.currentShift.opening_amount + this.currentShift.payment_breakdown.net_sales);
      this.currentShift.over_shortage = parseFloat(over_shortage.toString()).toFixed(2);
      this.currentShift.shift_close_date = new Date().toISOString();
      this.currentShift.close_shift_employee = this.employee.id;
      this.$store.commit("setCurrentShift", this.currentShift);
      this.$bvModal.hide("modal-close-shift");
      this.$bvModal.show("modal-status-shift");
      this.newShiftAmount = 0;
      this.$store.commit("shifts/save", this.currentShift);
      this.$store.dispatch("sync");
    }
  }
};
</script>

<style lang="scss" scoped>
.pin-page {
  background: linear-gradient(180deg, #1976D2 0%, #2196F3 100%);
  color: #fff;
  min-height: 100vh;

  h6 {
    font-size: 15px;
    line-height: 18px;
  }

  .pin-block {
    padding-top: 15px;
  }
}

.navbar {
  padding: 30px 0 30px;
}

.navbar-brand {
  padding: 0;
  margin: 0 auto;
  height: 60px;

  img {
    max-height: 100%;
    height: 100%;
  }
}


</style>

<template>
  <div>
    <EnterPinModal
      :modal-id="'enter-pin-modal--order-discount'"
      @verify-action="verifyAction"
    />

    <!-- Modal Order Discount -->
    <b-modal
      id="modal-order-discount"
      :body-bg-variant="bodyBgVariant"
      :modal-class="popupPrice+' '+popupPriceSidebar+' '+'popup-price-btn'"
      no-close-on-backdrop
      no-fade
    >
      <template
        slot="modal-header"
        slot-scope="{ close }"
      >
        <b-button
          variant="outline-light"
          @click="close()"
        >
          {{ $t("discountpopup.cancel") }}
        </b-button>
        <h5>
          {{ $t("discountpopup.discount") }}
        </h5>
      </template>

      <div class="pin-win discount-modal">
        <span
          v-if="discount.type === 1"
        >
          {{ parseFloat(discount.val).toFixed(2) }} %
        </span>
        <span
          v-if="discount.type === 2"
        >
          {{ currency }} {{ parseFloat(discount.val).toFixed(2) }}
        </span>
        <b-button
          v-if="discount.type === 1"
          variant="primary"
          @click="changeDiscountType()"
        >
          {{ $t("discountpopup.percent-off") }}
        </b-button>
        <b-button
          v-if="discount.type === 2"
          variant="primary"
          @click="changeDiscountType()"
        >
          {{ $t("discountpopup.currency-off") }}
        </b-button>
      </div>

      <div class="pin-keyboard text-center my-0">
        <ul class="pin-keyboard-list">
          <li>
            <span
              class="pin-key"
              @click="typeDiscount(1)"
            >1</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeDiscount(2)"
            >2</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeDiscount(3)"
            >3</span>
          </li>

          <li>
            <span
              class="pin-key"
              @click="typeDiscount(4)"
            >4</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeDiscount(5)"
            >5</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeDiscount(6)"
            >6</span>
          </li>

          <li>
            <span
              class="pin-key"
              @click="typeDiscount(7)"
            >7</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeDiscount(8)"
            >8</span>
          </li>
          <li>
            <span
              class="pin-key"
              @click="typeDiscount(9)"
            >9</span>
          </li>

          <li>
            <span
              class="pin-key pin-key_call"
              @click="typeDiscount('C')"
            >C</span>
          </li>

          <li>
            <span
              class="pin-key"
              @click="typeDiscount(0)"
            >0</span>
          </li>

          <li>
            <span
              class="pin-key bg-primary"
              @click="saveDiscountToOrder()"
            >
              <svg class="icon icon-icon-check">
                <use xlink:href="#icon-icon-check" />
              </svg>
            </span>
          </li>
        </ul>
      </div>

      <div
        slot="modal-footer"
        class="w-100"
      >
        <h5>
          {{ $t("discountpopup.discounts") }}
        </h5>

        <ul class="list-unstyled ">
          <li
            v-for="(discountItem, index) in allDiscounts"
            :key="index"
          >
            <a
              href="#"
              @click="setPredefinedDiscount(index)"
            >
              <span>
                {{ discountItem.name }}
              </span>
            </a>
          </li>
        </ul>
      </div>
    </b-modal>
  </div>
</template>

<script>
import EnterPinModal from "./EnterPinModal";
import { EMPLOYEE_ROLE } from "../helpers/employee/role";

export default {
  name: "OrderDiscount",
  components: {
    EnterPinModal
  },
  props: [
    "discount",
    "bodyBgVariant",
    "popupPrice",
    "popupPriceSidebar",
    "currency",
    "allDiscounts",
    "currentOrder",
    "currentEmployee"
  ],

  data () {
    return {
      isActionVerified: false
    };
  },
  watch: {
    currentEmployee (employee) {
      if (!employee || employee.role === EMPLOYEE_ROLE.Cashier) return this.isActionVerified = false;

      return this.isActionVerified = true;
    }
  },
  beforeMount () {
    this.currentDiscount = this.discount;
  },
  mounted () {
    this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
      switch (modalId) {
        case "modal-order-discount":
          window.addEventListener("keydown", this.onKey);
          break;
        case "enter-pin-modal--order-discount":
          window.removeEventListener("keydown", this.onKey);
          break;
      }
    });
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      switch (modalId) {
        case "enter-pin-modal--order-discount":
          window.addEventListener("keydown", this.onKey);
          break;
        case "modal-order-discount":
          window.removeEventListener("keydown", this.onKey);
          break;
      }
    });
  },
  methods: {
    onKey (event) {
      if (event.keyCode >= 48 && event.keyCode <= 57) {
        this.typeDiscount(event.keyCode - 48);
      } else if (event.keyCode === 8) {
        this.typeDiscount("C");
      }
    },
    changeDiscountType: async function () {
      this.currentDiscount.type = this.currentDiscount.type === 1 ? 2 : 1;
    },
    setPredefinedDiscount: async function (index) {
      this.currentDiscount.type = this.allDiscounts[index].type;
      this.currentDiscount.val = this.allDiscounts[index].value;
    },
    verifyAction (employee) {
      if (!employee) return this.isActionVerified = false;
      this.isActionVerified = true;
      this.$bvModal.hide("enter-pin-modal--order-discount");
      return this.saveDiscountToOrder();

    },
    saveDiscountToOrder: async function () {
      if (this.currentEmployee.role === EMPLOYEE_ROLE.Cashier && !this.isActionVerified)
        return this.$bvModal.show("enter-pin-modal--order-discount");

      this.$emit("save-discount-to-order", {
        value: parseFloat(this.discount.val),
        discountType: this.discount.type
      });
      this.isActionVerified = false;
      this.$bvModal.hide("modal-order-discount");
    },
    typeDiscount: function (num) {
      if (num === "C") {
        this.currentDiscount.raw = "0";
        this.currentDiscount.val = "0";
      } else {
        if (this.currentDiscount.changed) {
          if (this.currentDiscount.raw.length < 8) {
            this.currentDiscount.raw = this.discount.raw.concat(num + "");
          }
          this.currentDiscount.val = (parseFloat(this.discount.raw) / 100).toString();
        } else {
          this.currentDiscount.raw = num + "";
          this.currentDiscount.val = (parseFloat(this.discount.raw) / 100).toString();
        }
        this.currentDiscount.changed = true;
      }
    }
  }
};
</script>

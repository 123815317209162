<template>
  <b-modal
    id="confirm-modal"
    hide-footer
    hide-header
    modal-class="success-popup"
    no-fade
  >
    <div class="text-center modal-complete">
      <svg class="icon icon-icon-popup-cansel">
        <use xlink:href="#icon-icon-popup-cansel" />
      </svg>

      <h2>
        {{ modal.title }}
      </h2>

      <div class="popup-meta">
        {{ modal.text }}
      </div>

      <div class="d-flex justify-content-center align-items-center">
        <b-button
          variant="outline-primary"
          @click="$bvModal.hide('confirm-modal')"
        >
          {{ modal.cancelActionText }}
        </b-button>
        <b-button
          variant="primary"
          class="w-100 m-3"
          @click="confirmAction"
        >
          <!-- TODO: need to add translation -->
          {{ modal.confirmActionText }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
  export default {
    name: "ConfirmModal",
    props: {
      modal: {
        type: Object,
        required: true
      }
    },
    methods: {
      confirmAction() {
        this.$emit('confirm-action', true);
      }
    }
  }
</script>

<style scoped>

</style>

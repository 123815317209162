<template>
  <b-modal
    id="new-order-received"
    hide-footer
    hide-header
    modal-class="success-popup success-popup_wrong"
    no-fade
  >
    <div class="text-center modal-complete">
      <svg class="w-25 icon-icon-dashboard-10">
        <use xlink:href="#icon-icon-dashboard-10" />
      </svg>  
      <h2>
        {{ modal.title }}
      </h2>
  
      <div class="popup-meta">
        {{ modal.text }}
      </div>
  
      <div class="d-flex justify-content-center align-items-center">
        <b-button
          variant="outline-primary"
          @click="$bvModal.hide('new-order-received')"
        >
          {{ modal.cancelActionText }}
        </b-button>
        <b-button
          variant="primary"
          class="w-100 ml-3"
          @click="confirmAction"
        >
          <!-- TODO: need to add translation -->
          {{ modal.confirmActionText }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
  
  <script>
    export default {
      name: "NewOrderReceivedModal",
      props: {
        modal: {
          type: Object,
          required: true
        }
      },
      methods: {
        confirmAction() {
          this.$emit('confirm-action', true);
        }
      }
    }
  </script>
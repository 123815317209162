export const TRANSACTION_TYPE = {
  SALE: 1,
  VOID: 2,
  RETURN: 3
};

export const GIFT_CARD_TRANSACTION_TYPE = {
  CREDIT: 1,
  DEBIT: 2
}

export default {
  data () {
    return {
      viewCustomer: null
    }
  },
  methods: {
    showViewCustomerModal(customer, index) {
      console.log("showViewCustomerModal", customer)
      this.viewCustomer = customer;
      this.$bvModal.show('customer-modal'+(index ? '-' + index : ''));
    },
    clearViewCustomer() {
      this.viewCustomer = null
    }
  }
};

import DashboardService from "../../services/dashboard-service";
import indexDbService from "@/helpers/dbHelper"
export const giftVoucher = {
  namespaced: true,
  state: {
    giftVoucherPayments: [],
    activeGiftCard: null,
    showGiftVoucherOption: false,
    giftCards:[],
    selectedGiftCards:[],
    giftCardPaymentInitiateStatus:false,
    refundedGiftCards: [],
    // giftCard
  },
  mutations: {
    SET_GIFT_VOUCHER_ENTRIES: (state, entries) => state.giftVoucherPayments = entries,
    SET_GIFT_CARD: (state, card) => (state.activeGiftCard = card),
    SET_GIFT_VOUCHER_VISIBLITY: (state, visiblity) => state.showGiftVoucherOption = visiblity,
    SET_GIFT_CARDS: (state,giftCards) => state.giftCards = giftCards,
    SET_SELECTED_GIFT_CARDS: (state, selectedGiftCards) => state.selectedGiftCards = selectedGiftCards,
    SET_GIFT_CARD_PAYMENT_STATUS: (state, giftCardPaymentInitiateStatus) => {state.giftCardPaymentInitiateStatus = giftCardPaymentInitiateStatus},
    SET_REFUNDED_GIFT_CARDS: (state, refundedGiftCards) => {state.refundedGiftCards = refundedGiftCards}
  },
  getters: {
    giftVoucherPayments: (state) => state.giftVoucherPayments,
    activeGiftCard: (state) => state.activeGiftCard,
    showGiftVoucherOption: (state) => state.showGiftVoucherOption,
    giftCards: state => state.giftCards,
    selectedGiftCards: state => state.selectedGiftCards,
    isGiftCardPaymentInitiated: state => state.giftCardPaymentInitiateStatus,
    refundedGiftCards : state => state.refundedGiftCards
  },
  actions: {
    setGiftCardPayments: ({ commit }, entries) =>
      commit("SET_GIFT_VOUCHER_ENTRIES", entries),
    setGiftCard: ({ commit, state }, giftCard) => {
      const activeCard = state.giftCards.find(card=>card.gift_card_number === giftCard || card.id === giftCard)
      commit("SET_GIFT_CARD", activeCard)
    },
    setGiftVoucherVisiblity: ({ commit }, visiblity) => commit("SET_GIFT_VOUCHER_VISIBLITY", visiblity),
    setGiftCards: ({commit},giftCards) => {commit('SET_GIFT_CARDS',giftCards); console.log("GIFT CARDSSS",giftCards)},
    fetchGiftCard: async ({dispatch}, fetchGiftCard) => {
      const giftCard = await DashboardService.fetchGiftCard(fetchGiftCard)
      if(giftCard.length > 0){
        indexDbService.saveToDb('giftCards',giftCard).then()
        dispatch('setGiftCards',giftCard)
        dispatch('setGiftCardPayments',giftCard[0].payments)
      }
      return giftCard && giftCard.length > 0
    },
    setSelectedGiftCards({commit}, selectedGiftCards){
        commit('SET_SELECTED_GIFT_CARDS', selectedGiftCards)
    },
    async updateGiftCardCredits({state}){
      try{
        await DashboardService.updateGiftCard(state.selectedGiftCards)
        state.selectedGiftCards = []
      }
      catch(err){
        console.log(err)
      }
    },
    async createNewGiftCard({dispatch},giftCardDetails){
      const payload = {
        gift_card_number : giftCardDetails.gift_card_number,
        is_expired: false,
        store: giftCardDetails.store,
        credit: 0,
        order:null
      }
      const giftCard  = await DashboardService.postGiftCard(payload)
      if(giftCard.length > 0){
        indexDbService.saveToDb('giftCards',giftCard).then()
        dispatch('setGiftCards',giftCard)
        dispatch('setGiftCardPayments',[])
      }
      return giftCard && giftCard.length > 0

    },
    async recordGiftCardPayments({state},giftCardPayments){
      const response = await DashboardService.recordGiftCardPayments(giftCardPayments)
      state.activeGiftCard.credit = response.credit
      return response && Object.keys(response).length > 0
    },
    async toggleGiftCardPaymentInitiater({commit,state}){
      commit('SET_GIFT_CARD_PAYMENT_STATUS', !state.giftCardPaymentInitiateStatus)
    },
    async removeSelectedGiftCard({state}, local_id){
      const deleteIndex = state.selectedGiftCards.findIndex(giftCard=>giftCard.order_item_local_id === local_id)
      state.selectedGiftCards.splice(deleteIndex,1)
    },
    updateSelectedGiftCards({state}, giftVoucherDetils){
      state.selectedGiftCards.forEach(element => {
        if(element.order_item_local_id === giftVoucherDetils.order_item_local_id){
          element.amount_paid = giftVoucherDetils.price
          element.transaction_balance = parseFloat((state.activeGiftCard.credit + element.amount_paid ).toFixed(2))
        }
      });
    },
    setRefundGiftCardIds({commit}, giftCards){
      commit('SET_REFUNDED_GIFT_CARDS', giftCards)
    }
}
}

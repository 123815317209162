<template>
  <!-- Modal Qty Order -->
  <b-modal
    id="modal-quantity"
    body-bg-variant="light"
    :modal-class="terminal && terminal.scale?'popupQuantity':'popup-price'"
    no-close-on-backdrop
    no-fade
    hide-footer
  >
    <template
      slot="modal-header"
      slot-scope="{ close }"
    >
      <b-button
        variant="outline-light"
        @click="close()"
      >
        {{ $t("quantitypopup.cancel") }}
      </b-button>
      <h5>
        {{ $t("quantitypopup.quantity") }}
      </h5>
      <b-button
        v-if="terminal && terminal.scale"
        variant="primary"
        @click="getWeight()"
      >
        Get Weight
      </b-button>
    </template>

    <div class="pin-win">
      <span>{{ newQtyStr }}</span>
    </div>
    <NumKeyboard
      :enable-dot="true"
      @onTypeNum="typeNum"
      @save="passSave"
    />
  </b-modal>
</template>

<script>
import NumKeyboard from "@/components/keyboards/NumKeyboard";
import scaleMixin from "@/mixins/scaleMixin";
import { mapGetters } from "vuex";

export default {
  name: "ModalQuantity",
  props: {
    "initialValue": {
      type: String,
      required: false,
      default: "1"
    }
  },
  components: {
    NumKeyboard
  },
  mixins: [scaleMixin],
  data () {
    return {
      newQtyStr: "",
      changed: false,
      index: null
    };
  },
  computed: {
    ...mapGetters({
      'employee': "employee",
      'currency': "config/currency",
      'terminal': "config/terminal",
    }),
  },
  beforeMount: function () {
    this.$root.$on("bv::modal::show", this.init);
  },
  beforeDestroy: function () {
    this.$root.$off("bv::modal::show", this.init);
  },
  methods: {
    init: function (bvEvent, modalId) {
      if (modalId === "modal-quantity") {
        this.newQtyStr = this.$store.getters["qtyModal/initial"];
        this.index = this.$store.getters["qtyModal/index"];
        this.changed = false;
      }
    },
    typeNum: function (num, quantityVal = null) {
      const qtyLengthLimit = 12;
      if (num === "C") {
        this.newQtyStr = "";
      } else if ((this.newQtyStr.includes(".") && num === ".") || (this.newQtyStr + 1).length >= qtyLengthLimit) {
        return;
      }
      else if (num === "-") {
        if (!this.newQtyStr.includes("-")) {
          this.newQtyStr = "-" + this.newQtyStr
          this.changed = true
        }
        return
      }
      else if (typeof num !== "undefined" && !quantityVal) {
        if (this.changed) {
          this.newQtyStr += num.toString();
        } else {
          this.newQtyStr = num === "." ? `0${num}` : num.toString();
        }
        this.changed = true;
      } else if (quantityVal) {
        this.newQtyStr = quantityVal;
      }
    },
    passSave: function () {
      if (this.newQtyStr === "") {
        this.newQtyStr = "1"
      }
      if(this.newQtyStr==='-'){
        this.newQtyStr = "-1"
      }
      if (this.newQtyStr !== "0" && this.newQtyStr !== "")
        this.$emit("save", { index: this.index, newQtyStr: this.newQtyStr });
    }
  }
};
</script>

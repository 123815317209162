import _ from './commands';
import * as utils from './utils';
import {MutableBuffer} from 'mutable-buffer';
import * as qz from 'qz-tray';
import Vue from 'vue';
import * as Sentry from '@sentry/vue'
import store from "../../store/store";

export async function sendToPrinter(data, customPrinterObj = null){
  let confRawObj, currentPrinter;
  // const terminal = await getConfigItem('terminal');
  const terminal = store.getters['config/terminal']

  // if custom (kitchen) printer, otherwise use terminal printer
  if(customPrinterObj){
    currentPrinter = customPrinterObj;
  } else{
    currentPrinter = terminal.printer;
  }

  // by ip or by name
  if(currentPrinter.local_ip){
    confRawObj = {host: currentPrinter.local_ip, port: 9100};
  } else{
    confRawObj = {name: currentPrinter.name};
  }
  const config = qz.configs.create(confRawObj);
  const connectOptions = terminal.printer_server_ip?{...{host: terminal.printer_server_ip}}:{};

  let retries = 2;
  let hasErrors = false;
  do{
    Vue.$log.info(`print retry: ${retries}`);
    try{
      if(hasErrors){
        Vue.$log.info(`Trying again, retries left: ${retries}`);
      }

      // connect and print or print
      if(!qz.websocket.isActive()){
        await qz.websocket.connect(connectOptions).then(function(){
          Vue.$log.debug('Printing to ', config.printer);
          return qz.print(config, data);
        }).catch(function(e){
          console.error('Can not connect to printer.', e);
          Sentry.captureException(e);
          throw e;
        });
      } else{
        const config = qz.configs.create(confRawObj);       // Create a default config for the found printer
        Vue.$log.debug('Printing to ', config.printer);
        return qz.print(config, data);
      }
    } catch(e){
      hasErrors = true;
      retries--;
      if(!(retries >= 1 && hasErrors)){
        throw e;
      }
      Sentry.captureException(e)
    }

  } while(retries >= 1 && hasErrors);

}

export default class Printer{
  constructor(isWidth58 = false){
    this.width = isWidth58 ? 30 : 42;
    this.defaultWidthPercent = 1;
    this.newLine = '\x0A'; //LF byte in hex notation
    this.data = [];
    const esc = '\x1B'; // ESC byte in hex notation
    const newLine = '\x0A'; //LF byte in hex notation
    let cmds = esc + '@'; //Initializes the printer (ESC @)
    cmds += newLine + newLine;
    this.buffer = new MutableBuffer();
    this.cmds = cmds;
  }

  _getStyle(type){
    let styled = '';
    switch(type.toUpperCase()){
      case 'B':
        styled += _.TEXT_FORMAT.TXT_BOLD_ON;
        styled += _.TEXT_FORMAT.TXT_ITALIC_OFF;
        styled += _.TEXT_FORMAT.TXT_UNDERL_OFF;
        break;
      case 'I':
        styled += _.TEXT_FORMAT.TXT_BOLD_OFF;
        styled += _.TEXT_FORMAT.TXT_ITALIC_ON;
        styled += _.TEXT_FORMAT.TXT_UNDERL_OFF;
        break;
      case 'U':
        styled += _.TEXT_FORMAT.TXT_BOLD_OFF;
        styled += _.TEXT_FORMAT.TXT_ITALIC_OFF;
        styled += _.TEXT_FORMAT.TXT_UNDERL_ON;
        break;
      case 'U2':
        styled += _.TEXT_FORMAT.TXT_BOLD_OFF;
        styled += _.TEXT_FORMAT.TXT_ITALIC_OFF;
        styled += _.TEXT_FORMAT.TXT_UNDERL2_ON;
        break;

      case 'BI':
        styled += _.TEXT_FORMAT.TXT_BOLD_ON;
        styled += _.TEXT_FORMAT.TXT_ITALIC_ON;
        styled += _.TEXT_FORMAT.TXT_UNDERL_OFF;
        break;
      case 'BIU':
        styled += _.TEXT_FORMAT.TXT_BOLD_ON;
        styled += _.TEXT_FORMAT.TXT_ITALIC_ON;
        styled += _.TEXT_FORMAT.TXT_UNDERL_ON;
        break;
      case 'BIU2':
        styled += _.TEXT_FORMAT.TXT_BOLD_ON;
        styled += _.TEXT_FORMAT.TXT_ITALIC_ON;
        styled += _.TEXT_FORMAT.TXT_UNDERL2_ON;
        break;
      case 'BU':
        styled += _.TEXT_FORMAT.TXT_BOLD_ON;
        styled += _.TEXT_FORMAT.TXT_ITALIC_OFF;
        styled += _.TEXT_FORMAT.TXT_UNDERL_ON;
        break;
      case 'BU2':
        styled += _.TEXT_FORMAT.TXT_BOLD_ON;
        styled += _.TEXT_FORMAT.TXT_ITALIC_OFF;
        styled += _.TEXT_FORMAT.TXT_UNDERL2_ON;
        break;
      case 'IU':
        styled += _.TEXT_FORMAT.TXT_BOLD_OFF;
        styled += _.TEXT_FORMAT.TXT_ITALIC_ON;
        styled += _.TEXT_FORMAT.TXT_UNDERL_ON;
        break;
      case 'IU2':
        styled += _.TEXT_FORMAT.TXT_BOLD_OFF;
        styled += _.TEXT_FORMAT.TXT_ITALIC_ON;
        styled += _.TEXT_FORMAT.TXT_UNDERL2_ON;
        break;

      case 'NORMAL':
      default:
        styled += _.TEXT_FORMAT.TXT_BOLD_OFF;
        styled += _.TEXT_FORMAT.TXT_ITALIC_OFF;
        styled += _.TEXT_FORMAT.TXT_UNDERL_OFF;
        break;
    }
    return styled;
  }

  style(type){
    this.cmds = this.cmds.concat(this._getStyle(type));
    return this;
  }

  putNewLine(){
    this.cmds = this.cmds.concat(this.newLine);
    return this;
  }

  text(rawText){
    this.cmds = this.cmds.concat(rawText);
    this.cmds += this.newLine;
    return this;
  }

  title(titleText, bold = false){
    if(bold){
      this.style('B');
    }
    this.tableCustom([
      {text: titleText, align: 'CENTER', width: this.defaultWidthPercent}
    ]);
    if(bold){
      this.style('NORMAL');
    }
    this.style('NORMAL');
    return this;
  }

  line(){
    // this.cmds = this.cmds.concat('-'.repeat(48));
    this.align('ct')
    this.cmds = this.cmds.concat('-'.repeat(this.width));
    this.cmds += this.newLine;
     this.align('lt')
    return this;
  }

  font(family){
    this.buffer.write(_.TEXT_FORMAT[
    'TXT_FONT_' + family.toUpperCase()
      ]);
    // if(family.toUpperCase() === 'A'){
    //   this.width = 42;
    // } else{
    //   this.width = 56;
    // }
    return this;
  }

  table(data){
    this.cmds = this.cmds.concat(this.newLine);
    const cellWidth = this.width / data.length;
    for(let i = 0; i < data.length; i++){
      this.cmds += data[i].toString();
      const spaces = cellWidth - data[i].toString().length;
      for(let j = 0; j < spaces; j++){
        this.cmds += ' ';
      }
    }
    this.cmds += this.newLine;
    return this;
  }

  tableCustom(data){
    let spaces;
    let cellWidth = this.width / data.length;
    const secondLine = [];
    let secondLineEnabled = false;
    let lineStr = '';
    for(var i = 0; i < data.length; i++){
      let tooLong = false;
      const obj = data[i];
      if (obj.image)
          lineStr += obj.image
      if(obj.text){
        obj.text = obj.text.toString();

        if(obj.width){
          cellWidth = this.width * obj.width;
        } else if(obj.cols){
          cellWidth = obj.cols;
        }

        // If text is too wide go to next line
        if(cellWidth < obj.text.length){
          tooLong = true;
          obj.originalText = obj.text;
          obj.text = obj.text.substring(0, cellWidth - 1);
        }
        if(obj.align === 'CENTER'){
          const spaces = (cellWidth - obj.text.toString().length) / 2;
          for(let j = 0; j < spaces; j++){
            lineStr += ' ';
          }
          if(obj.text !== ''){
            lineStr += obj.text;
          }
          for(let j = 0; j < spaces - 1; j++){
            lineStr += ' ';
          }
        } else if(obj.align === 'RIGHT'){
          spaces = cellWidth - obj.text.toString().length;
          for(let j = 0; j < spaces; j++){
            lineStr += ' ';
          }
          if(obj.text != ''){
            lineStr += obj.text;
          }
        } else{
          if(obj.text != ''){
            lineStr += obj.text;
          }
          spaces = cellWidth - obj.text.toString().length;
          for(var j = 0; j < spaces; j++){
            lineStr += ' ';
          }
        }
        if(tooLong){
          secondLineEnabled = true;
          obj.text = obj.originalText.substring(cellWidth - 1);
          secondLine.push(obj);
        } else{
          obj.text = '';
          secondLine.push(obj);
        }
      }
    }
    this.cmds += lineStr + this.newLine;
    if(secondLineEnabled){
      this.tableCustom(secondLine);
    } else{
      return this;
    }
  }

  priceRow(name, price, bold = false, proportion = [0.7, 0.25]){
    if(bold){
      this.style('B');
    }
    this.tableCustom([
      {text: name, align: 'LEFT', width: proportion[0]},
      {text: price, align: 'RIGHT', width: proportion[1]}]);
    if(bold){
      this.style('NORMAL');
    }

  }

  barcode(code, type, options){
    options = options || {};
    let width, height, position, font, includeParity;
    if(typeof width === 'string' || typeof width === 'number'){ // That's because we are not using the options.object
      width = arguments[2];
      height = arguments[3];
      position = arguments[4];
      font = arguments[5];
    } else{
      width = options.width;
      height = options.height;
      position = options.position;
      font = options.font;
      includeParity = options.includeParity !== false; // true by default
    }

    type = type || 'EAN13'; // default type is EAN13, may a good choice ?
    const convertCode = String(code);
    let parityBit = '', codeLength = '';
    if(typeof type === 'undefined' || type === null){
      throw new TypeError('barcode type is required');
    }
    if(type === 'EAN13' && convertCode.length !== 12){
      throw new Error('EAN13 Barcode type requires code length 12');
    }
    if(type === 'EAN8' && convertCode.length !== 7){
      throw new Error('EAN8 Barcode type requires code length 7');
    }
    if(this._model === 'qsprinter'){
      this.buffer.write(_.MODEL.QSPRINTER.BARCODE_MODE.ON);
    }
    if(this._model === 'qsprinter'){
      // qsprinter has no BARCODE_WIDTH command (as of v7.5)
    } else if(width >= 2 || width <= 6){
      this.buffer.write(_.BARCODE_FORMAT.BARCODE_WIDTH[width]);
    } else{
      this.buffer.write(_.BARCODE_FORMAT.BARCODE_WIDTH_DEFAULT);
    }
    if(height >= 1 || height <= 255){
      this.buffer.write(_.BARCODE_FORMAT.BARCODE_HEIGHT(height));
    } else{
      if(this._model === 'qsprinter'){
        this.buffer.write(_.MODEL.QSPRINTER.BARCODE_HEIGHT_DEFAULT);
      } else{
        this.buffer.write(_.BARCODE_FORMAT.BARCODE_HEIGHT_DEFAULT);
      }
    }
    if(this._model === 'qsprinter'){
      // Qsprinter has no barcode font
    } else{
      this.buffer.write(_.BARCODE_FORMAT[
      'BARCODE_FONT_' + (font || 'A').toUpperCase()
        ]);
    }
    this.buffer.write(_.BARCODE_FORMAT[
    'BARCODE_TXT_' + (position || 'BLW').toUpperCase()
      ]);
    this.buffer.write(_.BARCODE_FORMAT[
    'BARCODE_' + ((type || 'EAN13').replace('-', '_').toUpperCase())
      ]);
    if(type === 'EAN13' || type === 'EAN8'){
      parityBit = utils.getParityBit(code);
    }
    if(type === 'CODE128' || type === 'CODE93'){
      codeLength = utils.codeLength(code);
    }
    this.buffer.write(codeLength + code + (includeParity?parityBit:'') + '\x00'); // Allow to skip the parity byte
    if(this._model === 'qsprinter'){
      this.buffer.write(_.MODEL.QSPRINTER.BARCODE_MODE.OFF);
    }
    this.cmds += this.buffer.flush();
    return this;
  }

  openDrawerCommand(){
    this.data.push('\x10' + '\x14' + '\x01' + '\x00' + '\x05');// open drawer todo remove
    this.data.push('\x1b' + '\x70' + '\x00');// open drawer todo remove
    this.data.push('\x1b' + '\x70' + '\x01');// open drawer todo remove
    this.data.push('\x1b' + '\x70' + '\x07');// open drawer todo remove
    this.data.push('\x07');// open drawer todo remove
    // this.buffer.write(_.CASH_DRAWER[
    // 'CD_KICK_' + (pin || 2)
    //   ]);
    return this;
  }

  align(align){
    this.buffer.write(_.TEXT_FORMAT[
    'TXT_ALIGN_' + align.toUpperCase()
      ]);
    this.cmds += this.buffer.flush();
    return this;
  }

  async print(customPrinter = null){
    this.data.push(this.cmds);
    this.data.push('\x0A', '\x0A', '\x0A');// cut paper (old syntax));
    this.data.push('\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A', '\x1B' + '\x69');// cut paper (old syntax));
    await sendToPrinter(this.data, customPrinter);
  }

  async send(){
    await sendToPrinter(this.data);
  }
}

import store from "../store/store";
import * as Sentry from "@sentry/vue";

export async function backgroundSync (fetchOrder=true) {
  console.log("backgroundSync", (new Date).toISOString());
  store.commit("startSync");
  try {
    store.dispatch("customers/uploadUpdated");
    store.dispatch("shifts/uploadUpdated");
    // just before order need update synced related ids
   store.commit("orders/updateLocalIds");
    // await store.dispatch("orders/fetchOrders");
    if(fetchOrder) {
      await store.dispatch("orders/uploadOfflineOrders");
      store.dispatch("config/fetchSections");
    }

    store.dispatch("config/uploadUpdatedTables");
  } catch (e) {
    Sentry.captureException(e);
    console.error(e);
  }
  store.commit("endSync");
}


<template>
  <b-modal
    id="processing-modal"
    hide-footer
    hide-header
    no-close-on-backdrop
    no-close-on-esc
    modal-class="success-popup success-popup_wrong"
    no-fade
  >
    <div class="text-center modal-complete">
      <svg class="icon icon-icon-popup-wrong">
        <use xlink:href="#icon-icon-popup-wrong" />
      </svg>

      <h2>
        {{ modalTitle }}
      </h2>

      <div class="popup-meta" />

      <div class="d-flex justify-content-center align-items-center" />
    </div>
  </b-modal>
</template>

<script>
  export default {
    name: "ProcessingModal",
    props:{
      modalTitle:{
        type:String,
        default:"Processing 1"
      },
    }
  }
</script>

<style scoped>

</style>

<template>
  <b-modal
    id="dining-options-modal"
    no-close-on-backdrop
    hide-footer
    size="md"
    body-class="modal-height p-0"
    no-fade
  >
    <template
      slot="modal-header"
      slot-scope="{ close }"
    >
      <b-button
        variant="outline-light"
        @click="close()"
      >
        {{ $t('dining-options-modal.cancel') }}
      </b-button>
      <h5>
        {{ $t('dining-options-modal.choose-one') }}
      </h5>
      <b-button
        variant="primary"
        @click="selectOption"
      >
        {{ $t('dining-options-modal.done') }}
      </b-button>
    </template>
    <div class="dining__options">
      <div
        v-for="(option) in activeDiningOptions"
        :key="option.id"
        class="dining__option"
      >
        <label class="container">{{ option.name }}
          <input
            v-model="selectedOption"
            :value="option.id"
            type="radio"
          >
          <span class="checkmark" />
        </label>
      </div>
    </div>
  </b-modal>
</template>

<script>
  export default {
    name: "DiningOptionModal",
    props: {
      diningOptions: {
        type: Array,
        required: true
      },
      diningOption: {
        type: Number,
        required: false,
        default: null
      }
    },
    data () {
      return {
        selectedOption: null
      }
    },
    computed: {
      activeDiningOptions() {
        if (!this.diningOptions) return [];
        return this.diningOptions.filter(option => option.active);
      }
    },
    watch: {
      diningOption(value) {
        this.selectedOption = value;
      }
    },
    beforeMount () {
      this.selectedOption = this.diningOption
    },
    methods: {
      selectOption() {
        this.$emit('select-dining-option', this.selectedOption);
        this.$bvModal.hide('dining-options-modal');
      }
    }
  }
</script>

<style scoped lang="scss">
  .dining {
    &__options {
      display: flex;
      flex-direction: column;
    }
    &__option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1rem;
      line-height: 1.875rem;
      padding: 9px 16px 9px 16px;
      background-color: #ffffff;
      position: relative;
      label {
        margin-bottom: 0;
      }
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        background: #E0E0E0;
      }

      .container {
        display: block;
        position: relative;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        & input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
        }
      }

      .checkmark {
        position: absolute;
        top: 0;
        right: 0;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 1px solid #616161;

        &:after {
          content: "";
          position: absolute;
          display: none;
        }
      }

      .container input:checked ~ .checkmark:after {
        display: block;
        border-color: #2196F3;
      }

      .container input:checked ~ .checkmark {
        border-color: #2196F3;
      }

      .container .checkmark:after {
        top: 4px;
        left: 4px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #2196F3;
      }
    }
  }

</style>
